import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import CollaboratorName from "../../../type/collaboratorName";
const AppliedCollaborator: React.FC<{type: string ; value: CollaboratorName[]; removeFilter: () => void; }> = (props) => {
  const removerFilter = () => {
    props.removeFilter();
  };
  return (
    <div className="applied-filter-item">
      <p className="text-xs text-black">
      {props.type}: {props.value.map((c)=>(`${c.name} , `))}
      </p>
      <span className="relative top-[2px] pl-2" onClick={removerFilter}>
        <FontAwesomeIcon icon={faXmark} />
      </span>
    </div>
  );
};

export default AppliedCollaborator;
