import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BreadCrumb from "../../Global/BreadCrumb";
import BotanicalFamilyTableContent from "./BotanicalFamilyTableContent";
import SearchField from "../../Global/SearchField";

const BotanicalFamily = () => {
  const [search, setSearch] = useState<string>("");
  const [sendSearch, setSendSearch] = useState<string>("");
  const { t } = useTranslation();
  const applySearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSendSearch(search);
  };

  return (
    <div className="parent-container">
      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-[#414141] text-[16px] mt-5">
          {t("Botanical families.Botanical families")}
        </h1>
        <Link
          to="/setting/botanical-families/add-botanical-family"
          className="main-btn px-[23px] py-[10px] text-sm"
        >
          {t("Botanical families.Add botanical family")}
        </Link>
      </div>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          { name: t("Botanical families.Botanical families"), url: "" },
        ]}
      />

      <SearchField
        applySearch={applySearch}
        change={(e) => setSearch(e.target.value)}
        searchValue={search}
      />

      <div className="p-4 bg-white mt-3 rounded-md">
        <BotanicalFamilyTableContent
          search={sendSearch}
          link="/api/admin/botanical_families"
          title={t("Tree Species.Botanical family")}
          deleteMessage={t("Message.Invalid species delete")}
          editLink="/setting/botanical-families/edit-botanical-family"
        />
        <br />
      </div>
    </div>
  );
};

export default BotanicalFamily;
