import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const LocationAlert: React.FC<{
  alert: boolean;
  handleAddLocation: () => void;
}> = (props) => {
  const [showPrompt, setShowPrompt] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.alert) {
      setShowPrompt(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = () => {
    setShowPrompt(false);
    props.handleAddLocation();
  };

  return showPrompt ? (
    <div className="w-full h-full bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0 z-50">
      <div className="bg-white w-full h-fit px-10 py-14 sm:w-[492px] sm:h-[435px] rounded-md shadow-md text-center">
        <FontAwesomeIcon
          className="w-[104px] h-[92px] text-[#FE9D2B]"
          icon={faTriangleExclamation}
        />
        <h1 className="mt-4 text-[25px] font-bold text-[#FE9D2B]">
          {t("Message.Alert")}
        </h1>
        <p className="mt-6">{t("Message.Unsuccessful reading lat and long")}</p>
        <div className="mt-7 pt-8">
          <button
            onClick={handleAdd}
            className="px-12 py-3 bg-[#FE9D2B] text-white text-sm rounded-3xl font-medium"
          >
            {t("Message.Add location")}
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default LocationAlert;
