import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";

const RootAdminAssign: React.FC<{
  name: string;
  showAlert: boolean;
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
  onRootAdminAction: () => void;
}> = ({ name, showAlert, setShowAlert, onRootAdminAction }) => {
  const { t } = useTranslation();

  const handleOk = () => {
    onRootAdminAction && onRootAdminAction();
    setShowAlert(false);
  };
  
  const handleCancel = () => {
    setShowAlert(false);
  };

  return showAlert ? (
    <div className="w-full h-full bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0 z-50">
      <div className="bg-white w-full h-fit px-10 py-14 sm:w-[492px] sm:h-[435px] rounded-md shadow-md text-center">
        <FontAwesomeIcon
          className="w-[104px] h-[92px] text-[#FE9D2B]"
          icon={faTriangleExclamation}
        />
        <h1 className="mt-4 text-[25px] font-bold text-[#FE9D2B]">
          {t("Message.Alert")}
        </h1>
        <p className="mt-6">
          {t("Message.You are assigning the root admin to ")}
          <span className="font-bold">{name}</span>
          {t(
            "Message.. By this action, you will relinquish your own root admin privileges, and this change is irreversible."
          )}
        </p>
        <div className="mt-7 border-t border-gray-300 pt-8">
          <button
            onClick={handleCancel}
            className="mr-2 px-12 py-3 text-sm font-medium"
          >
            {t("Message.Cancel")}
          </button>
          <button
            onClick={handleOk}
            className="px-12 py-3 bg-[#FE9D2B] text-white text-sm rounded-3xl font-medium"
          >
            {t("Message.Ok")}
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default RootAdminAssign;