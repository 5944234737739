import { Fragment, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import { faPencil, faTrashCan, faEye } from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { profileInfo } from "../../store";
import { faUserGear } from "@fortawesome/pro-regular-svg-icons";

const ActionItem: React.FC<{
  editLink: string;
  deleteAction: () => void;
  delete: boolean;
  viewLink: string;
  view: string;
  viewHistory: string;
  historyLink: string;
  index?: boolean;
  scroll?: boolean;
  setShowAlert?: React.Dispatch<React.SetStateAction<boolean>>;
  rootAdmin?: boolean;
}> = (props) => {
  const [showAction, isShowAction] = useState<boolean>(false);
  const profile = useSelector(profileInfo);
  const divRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    document.addEventListener("click", handelClick, true);
  }, []);
  const toggleAction = () => {
    isShowAction(!showAction);
  };

  const handelClick = (e: any) => {
    if (
      !divRef.current?.contains(e.target) &&
      !btnRef.current?.contains(e.target)
    ) {
      isShowAction(false);
    }
  };
  return (
    <div className="relative">
      <button
        ref={btnRef}
        onClick={toggleAction}
        className="text-[#979797] px-3 py-1 rounded-md hover:bg-ph-bgIcon hover:text-ph-btn"
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </button>
      {showAction && (
        <div
          ref={divRef}
          className={`absolute z-10 right-1 w-fit grid-flow-col text-left  shadow-lg rounded-md bg-white px-4 py-3 ${
            props.scroll
              ? "top-[20px]"
              : "bottom-[0px]" && props.index
              ? "bottom-[20px]"
              : "top-[20px]"
          }`}
        >
          {props.viewHistory && (
            <Link
              to={props.historyLink}
              className="text-sm text-[#414141] flex items-center w-[132px] py-[5px]"
            >
              <FontAwesomeIcon className="mr-2" icon={faEye} />
              {props.viewHistory}
            </Link>
          )}
          {props.view && (
            <Link
              to={props.viewLink}
              className="text-sm text-[#414141] flex items-center w-[132px] py-[5px]"
            >
              <FontAwesomeIcon className="mr-2" icon={faEye} />
              {props.view}
            </Link>
          )}
          {(profile.role === "A" ||
            profile.role === "C" ||
            (props.editLink.includes("register") && profile.role === "O")) && (
            <Fragment>
              <div className="w-[184px]">
                {props.editLink.includes("users") && props.delete
                  ? !props.delete
                  : !props.delete &&
                    props.rootAdmin === false && (
                      <button
                        onClick={() =>
                          props.setShowAlert && props.setShowAlert(true)
                        }
                        className="text-sm text-[#414141] flex items-center  py-[5px]"
                      >
                        <FontAwesomeIcon className="mr-2" icon={faUserGear} />
                        {t("Users.Assign root admin")}
                      </button>
                    )}
                <Link
                  to={props.editLink}
                  className="text-sm text-[#414141] flex items-center py-[5px]"
                >
                  <FontAwesomeIcon className="mr-2" icon={faPencil} />
                  {t("PlantingFilter.Edit")}
                </Link>
                {(profile.is_root_admin ? true : props.delete) && (
                  <button
                    onClick={() => {
                      isShowAction(false);
                      props.deleteAction();
                    }}
                    className="text-sm text-[#414141] flex items-center py-[5px]"
                  >
                    <FontAwesomeIcon icon={faTrashCan} className="mr-2" />{" "}
                    {t("PlantingFilter.Delete")}
                  </button>
                )}
              </div>
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default ActionItem;
