import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import DeleteAlert from "../../ErrorAndAlert/DeleteAlert";
import { ScientificName } from "../../../type/scientificName";
import ActionItem from "../../Global/ActionItem";
import ScientificNamesTooptip from "./ScientificNamesTooptip";
import { toast } from "react-toastify";

const ScientificNameTableItem: React.FC<{
  names: ScientificName;
  fetchData: () => void;
  lastIndex: number;
}> = (props) => {
  const { i18n, t } = useTranslation();
  const [showDelete, isShowDelete] = useState<boolean>(false);
	const index = props.lastIndex >= 0 ? true : false;
  const scroll = props.lastIndex === 0 ? true : false;

  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/scientific_names/${props.names.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.Successful delete")}`,
    true,
    true
  );
  useEffect(() => {
    if (deleteR !== null) {
      isShowDelete(false);
      props.fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const deleteAction = () => {
    if (props.names.is_deletable) {
      isShowDelete(true);
    } else {
      toast.error(t("Message.Invalid species delete"));
    }
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
  };

  const name =
    props.names.species_name_transes.fr === ""
      ? props.names.species_name_transes.en
      : props.names.species_name_transes.fr;
  return (
    <Fragment>
      <TableCell align="left">
        {i18n.language === "fr" ? name : props.names.species_name_transes.en}
      </TableCell>
      <TableCell>
        <div className="flex items-center">
          {i18n.language === "fr"
            ? props.names.scientific_name_transes[0].fr ||
              props.names.scientific_name_transes[0].en
            : props.names.scientific_name_transes[0].en}
          {props.names.scientific_name_transes.length > 1 && (
            <ScientificNamesTooptip
              names={props.names.scientific_name_transes}
            />
          )}
        </div>
      </TableCell>
      <TableCell align="right">
        <ActionItem
          delete={true}
          editLink={`/setting/scientific-names/edit-scientific-name/${props.names.slug}`}
          deleteAction={deleteAction}
          view=""
          viewLink=""
          historyLink=""
          viewHistory=""
          index={index}
          scroll={scroll}
        />
      </TableCell>
      {showDelete && (
        <DeleteAlert
          type={t("Tree Species.Scientific name")}
          hide={hide}
          deleteAction={deleteItem}
        />
      )}
    </Fragment>
  );
};

export default ScientificNameTableItem;
