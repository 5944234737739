import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterList } from "@fortawesome/pro-light-svg-icons";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { ThemeProvider } from "@emotion/react";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import {
  collaboratorNames,
  plantingFilterDelete,
  plantingFilterSet,
} from "../../../store";
import { selectFieldAction } from "../../../store/selectFields-slice";
import CollaboratorName from "../../../type/collaboratorName";
import { Department } from "../../../type/department";
import useAxios from "../../../hook/useAxios";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { plantingTheme } from "../../Register/RegisterList/PlantingTheme";
import { plantingFilterAction } from "../../../store/plantingFilter-slice";
import CustomDate from "../../Global/CustomDate";
import NumberField from "../../Global/NumberField";
import CustomAutocomplete from "../../Global/CustomAutocomplete";

type FormValues = {
  collaboratorType: string;
  dateFrom: Date;
  dateTo: Date;
  treeFrom: number;
  treeTo: number;
  collaborator: CollaboratorName[];
  partiFrom: number;
  partiTo: number;
  department: Department;
};

const PlantingFilterBox = () => {
  const [advanceFilter, isAdvanceFilter] = useState<boolean>(false);
  const [departmentData, setDepartments] = useState<Department[]>([]);
  const { t } = useTranslation();

  const { response: departmentRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/departments/all/",
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: grpRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/organizers/names/?organizer_type=grp",
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: fmlRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/organizers/names/?organizer_type=fml",
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: indRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/organizers/names/?organizer_type=ind",
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: insRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/organizers/names/?organizer_type=ins",
    "GET",
    true,
    "",
    false,
    false
  );

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    control,
    reset,
    resetField,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>();
  const collaboratorData = useSelector(collaboratorNames);
  const isFilterSet = useSelector(plantingFilterSet);
  const deleteItem = useSelector(plantingFilterDelete);

  const dispatch = useDispatch();
  useEffect(() => {
    switch (deleteItem) {
      case "par":
        resetField("partiFrom");
        resetField("partiTo");
        break;
      case "tree":
        resetField("treeFrom");
        resetField("treeTo");
        break;
      case "date":
        resetField("dateTo");
        resetField("dateFrom");
        break;
      case "collaborator":
        resetField("collaborator");
        break;
      case "collaboratorType":
        resetField("collaboratorType");
        resetField("collaborator");
        break;
      case "department":
        resetField("department");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteItem]);

  useEffect(() => {
    if (departmentRes) {
      setDepartments(departmentRes.data);
    }
    if (indRes) {
      dispatch(selectFieldAction.setInd(indRes.data));
    }
    if (insRes) {
      dispatch(selectFieldAction.setIns(insRes.data));
    }
    if (grpRes) {
      dispatch(selectFieldAction.setGrp(grpRes.data));
    }
    if (fmlRes) {
      dispatch(selectFieldAction.setFml(fmlRes.data));
    }
  }, [indRes, insRes, grpRes, fmlRes, departmentRes, dispatch]);

  const applyFilter = (data: FormValues) => {
    const dateT =
      !data.dateTo || !data.dateTo?.getDate()
        ? ""
        : `${data.dateTo.getDate()}/${
            data.dateTo.getMonth() + 1
          }/${data.dateTo.getFullYear()}`;
    const dateF =
      !data.dateFrom || !data.dateFrom?.getDate()
        ? ""
        : `${data.dateFrom.getDate()}/${
            data.dateFrom.getMonth() + 1
          }/${data.dateFrom.getFullYear()}`;
    if (data.dateFrom > data.dateTo) {
      setError("dateFrom", {
        type: "custom",
        message: `${t("Message.Invalid date filter error")}`,
      });
      return;
    }
    dispatch(
      plantingFilterAction.setFilter({
        dateTo: dateT,
        dateFrom: dateF,
        collaboratorType: data?.collaboratorType || "",
        collaborator: data?.collaborator || [],
        partiFrom: `${data?.partiFrom || ""}`,
        partiTo: `${data?.partiTo || ""}`,
        department: data.department,
        treeFrom: `${data?.treeFrom || ""}`,
        treeTo: `${data?.treeTo || ""}`,
      })
    );
    dispatch(plantingFilterAction.setURL());
    dispatch(plantingFilterAction.isFilterSet());
  };

  const clearFilter = () => {
    reset();
    dispatch(plantingFilterAction.clearFilter());
    dispatch(plantingFilterAction.setURL());
  };

  return (
    <ThemeProvider theme={plantingTheme}>
      <div className="w-full h-fit bg-white p-4 rounded-md relative mt-3">
        <span
          title={t("Tree Species.Advanced filter") + ""}
          className={`${
            advanceFilter
              ? "text-[#989898]"
              : "text-[#066993] hover:text-[#0884B8]"
          } float-right  cursor-pointer`}
          onClick={() => {
            isAdvanceFilter(!advanceFilter);
          }}
        >
          <FontAwesomeIcon
            className="w-[21px] h-[21px]"
            icon={advanceFilter ? faXmark : faFilterList}
          />
        </span>
        <br />
        <form onSubmit={handleSubmit(applyFilter)} className="mt-4">
          <div className="flex flex-wrap justify-between w-full sm:w-[80%] md:w-[65%]">
            <div className="w-full lg:w-[48%]">
              <p className="text-sm text-[#414141] mb-[6px]">
                {t("Plantings.Date of planting")}
              </p>
              <div className="flex items-center">
                <CustomDate
                  clearError={clearErrors}
                  control={control}
                  name="dateFrom"
                  label={t("PlantingFilter.From")}
                />

                <span className="px-2 text-[#414141] text-xs">&#9866;</span>

                <CustomDate
                  control={control}
                  name="dateTo"
                  label={t("PlantingFilter.To")}
                />
              </div>
              {errors?.dateFrom && (
                <p className="error-text">
                  {t("Message.Invalid date filter error")}
                </p>
              )}
            </div>
            <div className="w-full lg:w-[48%] mt-5 lg:mt-0">
              <p className="text-sm text-[#414141] mb-[6px]">
                {t("Plantings.No. of trees")}
              </p>
              <div className="flex items-center">
                <NumberField
                  onChange={() => {}}
                  inputStyle="py-2 placeholder-[#C7C7C7]"
                  getValues={getValues}
                  setValue={setValue}
                  name="treeFrom"
                  placeholder={t("PlantingFilter.From") + ""}
                  register={register("treeFrom")}
                  readOnl={false}
                />
                <span className="px-2 text-[#414141] text-xs">&#9866;</span>
                <NumberField
                  onChange={() => {}}
                  inputStyle="py-2 placeholder-[#C7C7C7]"
                  getValues={getValues}
                  setValue={setValue}
                  name="treeTo"
                  placeholder={t("PlantingFilter.To") + ""}
                  register={register("treeTo")}
                  readOnl={false}
                />
              </div>
            </div>
          </div>
          {advanceFilter && (
            <Fragment>
              <div className="flex flex-wrap justify-between w-full sm:w-[80%] md:w-[65%] mt-5">
                <div className="w-full lg:w-[48%]">
                  <p className="text-sm text-[#414141] mb-[6px]">
                    {t("PlantingFilter.Province")}{" "}
                  </p>
                  <CustomAutocomplete
                    control={control}
                    name="department"
                    selectOptions={departmentData}
                    placeholder={t("PlantingFilter.Province")}
                    getOptionLabel={(option) => option.name}
                  />
                </div>
                <div className="w-full lg:w-[48%] mt-5 lg:mt-0">
                  <p className="text-sm text-[#414141] mb-[6px]">
                    {t("PlantingFilter.Participants")}
                  </p>
                  <div className="flex items-center">
                    <NumberField
                      onChange={() => {}}
                      inputStyle="py-2 placeholder-[#C7C7C7]"
                      getValues={getValues}
                      setValue={setValue}
                      name="partiFrom"
                      placeholder={t("PlantingFilter.From") + ""}
                      register={register("partiFrom")}
                      readOnl={false}
                    />
                    <span className="px-2 text-[#414141] text-xs">&#9866;</span>
                    <NumberField
                      onChange={() => {}}
                      inputStyle="py-2 placeholder-[#C7C7C7]"
                      getValues={getValues}
                      setValue={setValue}
                      name="partiTo"
                      placeholder={t("PlantingFilter.To") + ""}
                      register={register("partiTo")}
                      readOnl={false}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap justify-between w-full sm:w-[80%] md:w-[65%] mt-5">
                <div className="w-full lg:w-[48%]">
                  <p className="text-sm text-[#414141] mb-[6px]">
                    {t("Plantings.Organizer type")}
                  </p>
                  <Controller
                    control={control}
                    name="collaboratorType"
                    render={({ field: { onChange, value } }) => (
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel shrink={false}>
                          {!value && `${t("Plantings.Organizer type")}`}
                        </InputLabel>
                        <Select
                          style={{
                            height: "38px",
                          }}
                          IconComponent={ExpandMoreIcon}
                          variant="outlined"
                          value={value || null}
                          onChange={(e) => {
                            onChange(e as ChangeEvent<Element>);
                            resetField("collaborator");
                            dispatch(
                              selectFieldAction.setCollaboratorNames(
                                e.target.value + ""
                              )
                            );
                          }}
                          sx={{
                            border: "1px solid #eeecec",
                            borderRadius: "4px",
                            "& fieldset": { border: "none" },
                          }}
                        >
                          <MenuItem value="fml">
                            {t("Register.Family")}
                          </MenuItem>
                          <MenuItem value="grp">{t("Register.Group")}</MenuItem>
                          <MenuItem value="ind">
                            {t("Register.Individual")}
                          </MenuItem>
                          <MenuItem value="ins">
                            {t("Register.Institution")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>
                <div className="w-full lg:w-[48%] mt-5 lg:mt-0">
                  <p
                    className={`${
                      getValues("collaboratorType") === undefined
                        ? "text-[#C7C7C7]"
                        : "text-[#414141]"
                    } text-sm mb-[6px]`}
                  >
                    {t("Plantings.Organizer name")}
                  </p>
                  <CustomAutocomplete
                    control={control}
                    name="collaborator"
                    isMulti={true}
                    selectOptions={collaboratorData}
                    placeholder={t("Plantings.Organizer name")}
                    disabled={getValues("collaboratorType") ? false : true}
                    getOptionLabel={(option) => option.name}
                  />
                </div>
              </div>
            </Fragment>
          )}
          <div className="mt-5 sm:mt-0 sm:absolute sm:right-4 sm:bottom-4">
            {isFilterSet && (
              <button
                className="py-2 px-6 text-sm font-medium rounded-3xl text-[#414141] hover:bg-[#F6F6F6] mr-3"
                type="button"
                onClick={clearFilter}
              >
                {t("PlantingFilter.Reset")}
              </button>
            )}
            <button
              type="submit"
              className=" hover:bg-ph-hover hover:text-white text-ph-btn py-2 px-6 border border-ph-btn text-sm font-medium rounded-3xl"
            >
              {t("PlantingFilter.Apply")}
            </button>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};
export default PlantingFilterBox;
