import { Fragment, useState } from "react";
import AddUser from "./components/Users/AddUser/AddUser";
import Navbar from "./components/Navbar/Navbar";
import UserProfile from "./components/Profile/UserProfile";
import Users from "./components/Users/UsrList/Users";
import { Switch, Route, Redirect } from "react-router-dom";
import Wrapper from "./components/Wrapper";
import { useSelector } from "react-redux";
import NotFound from "./components/ErrorAndAlert/NotFound";
import MapPage from "./components/Map/MapPage";
import Login from "./components/Login";
import { login } from "./store";
import ForgotPassword from "./components/Forgot Password/ForgotPassword";
import ResetPassword from "./components/Forgot Password/ResetPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/global.css";
import TreeSpecies from "./components/TreeSpecies/TreeSpeciesList/TreeSpecies";
import AddTreeSpecies from "./components/TreeSpecies/AddTreeSpecies/AddTreeSpecies";
import EditTreeSpecies from "./components/TreeSpecies/EditTreeSpecies/EditTreeSpecies";
import Workshop from "./components/Workshops/WorkshopList/Workshop";
import AddWorkshops from "./components/Workshops/AddWorkshop/AddWorkshops";
import EditWorkshops from "./components/Workshops/EditWorkshop/EditWorkshops";
import Footer from "./components/Footer";
import InvalidURL from "./components/ErrorAndAlert/InvalidURL";
import ScientificNames from "./components/Scientific name/ScientificNameList/ScientificNames";
import AddScientificName from "./components/Scientific name/AddScientificName/AddScientificName";
import EditScientificName from "./components/Scientific name/EditScientificName/EditScientificName";
import Departments from "./components/Departments/DepartmentList/Departments";
import AddDepartment from "./components/Departments/AddDepartment/AddDepartment";
import EditDepartment from "./components/Departments/EditDepartment/EditDepartment";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Incident from "./components/Incidents/IncidentList/Incident";
import GrowthStage from "./components/GrowthStage/GrowthStageList/GrowthStage";
import AddGrowthStage from "./components/GrowthStage/AddGrowthStage/AddGrowthStage";
import EditIncident from "./components/Incidents/EditIncident/EditIncident";
import EditGrowthStage from "./components/GrowthStage/EditGrowthStage/EditGrowthStage";
import AddRegister from "./components/Register/AddRegister/AddRegister";
import EditRegister from "./components/Register/EditRegister/EditRegister";
import ViewRegister from "./components/Register/ViewRegister/ViewRegister";
import Register from "./components/Register/RegisterList/Register";
import EditPlanting from "./components/Plantings/EditPlanting/EditPlanting";
import AddPlanting from "./components/Plantings/AddPlanting/AddPlanting";
import Plantings from "./components/Plantings/PlantingList/Plantings";
import EditUser from "./components/Users/EditUser/EditUser";
import EditProfile from "./components/Profile/UserMyProfile/EditUserMyProfile";
import ChangePassword from "./components/Profile/ChangePassword";
import ProtectedRoute from "./components/Navbar/ProtectedRoute";
import ViewPlanting from "./components/Plantings/ViewPlanting/ViewPlanting";
import ViewWorkshop from "./components/Workshops/ViewWorkshop/ViewWorkshop";
import Statistics from "./components/Statistics/Statistics";
import ViewGrowthHistory from "./components/RegisterHistory/ViewHistory/ViewGrowthHistory";
import BotanicalFamily from "./components/BotanicalFamily/BotanicalFamilyList/BotanicalFamily";
import AddBotanicalFamily from "./components/BotanicalFamily/AddBotanicalFamily/AddBotanicalFamily";
import EditBotanicalFamily from "./components/BotanicalFamily/EditBotanicalFamily/EditBotanicalFamily";
import TreeOrigin from "./components/TreeOrigin/OriginList/TreeOrigin";
import AddTreeOrigin from "./components/TreeOrigin/AddOrigin/AddTreeOrigin";
import EditTreeOrigin from "./components/TreeOrigin/EditOrigin/EditTreeOrigin";
import AddIncident from "./components/Incidents/AddIncident/AddIncident";
import RegisterResponsible from "./components/RegisterResponsible/RegisterResponsibleList/RegisterResponsible";
import AddResponsibleType from "./components/RegisterResponsible/AddRegisterResponsible/AddResponsibleType";
import EditResponsibleType from "./components/RegisterResponsible/EditRegisterResponsible/EditResponsibleType";
import PlantingOrganizers from "./components/PlantingOrganizer/PlantingOrganizerList/PlantingOrganizers";
import AddPlantingOrganizer from "./components/PlantingOrganizer/AddPlantingOrganizer/AddPlantingOrganizer";
import EditPlantingOrganizer from "./components/PlantingOrganizer/EditPlantingOrganizer/EditPlantingOrganizer";
import ImportRegister from "./components/ImportRegister/ImportRegister";

function App() {
  const [showProfile, isShowProfile] = useState<boolean>(false);
  const isLoggin = useSelector(login);
  const showUserProfile = () => {
    isShowProfile(true);
  };
  const hideUserProfile = () => {
    isShowProfile(false);
  };
  return (
    <Fragment>
      <ToastContainer position="top-right" />
      {isLoggin && <Navbar showUserProfile={showUserProfile} />}
      {isLoggin ? (
        <Fragment>
          <Switch>
            <Route path="/statistics" exact>
              <Wrapper>
                <Statistics />
              </Wrapper>
            </Route>
            <Route path="/change-password" exact>
              <Wrapper>
                <ChangePassword />
              </Wrapper>
            </Route>
            <Route path="/workshops" exact>
              <Wrapper>
                <Workshop />
              </Wrapper>
            </Route>
            <Route path="/workshops/add-workshop" exact>
              <ProtectedRoute>
                <Wrapper>
                  <AddWorkshops />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/workshops/edit-workshop/:id" exact>
              <ProtectedRoute>
                <Wrapper>
                  <EditWorkshops />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/workshops/view-workshop/:id" exact>
              <Wrapper>
                <ViewWorkshop />
              </Wrapper>
            </Route>
            <Route path="/setting/tree-species" exact>
              <Wrapper>
                <TreeSpecies />
              </Wrapper>
            </Route>
            <Route path="/setting/tree-species/add-species" exact>
              <ProtectedRoute>
                <Wrapper>
                  <AddTreeSpecies />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/setting/tree-species/edit-species/:id" exact>
              <ProtectedRoute>
                <Wrapper>
                  <EditTreeSpecies />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/plantings" exact>
              <Wrapper>
                <Plantings />
              </Wrapper>
            </Route>
            <Route path="/plantings/add-planting" exact>
              <ProtectedRoute>
                <Wrapper>
                  <AddPlanting />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/plantings/edit-planting/:id" exact>
              <ProtectedRoute>
                <Wrapper>
                  <EditPlanting />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/plantings/view-planting/:id" exact>
              <Wrapper>
                <ViewPlanting />
              </Wrapper>
            </Route>
            <Route path="/tools/import-register/:id" exact>
              <Wrapper>
                <ImportRegister />
              </Wrapper>
            </Route>
            <Route path="/setting/users" exact>
              <Wrapper>
                <Users />
              </Wrapper>
            </Route>
            <Route path="/setting/users/add-user" exact>
              <Wrapper>
                <AddUser />
              </Wrapper>
            </Route>
            <Route path="/setting/users/edit-user/:id" exact>
              <Wrapper>
                <EditUser />
              </Wrapper>
            </Route>
            <Route path="/user/edit-profile" exact>
              <Wrapper>
                <EditProfile />
              </Wrapper>
            </Route>

            <Route path="/setting/organizers/:id" exact>
              <ProtectedRoute>
                <Wrapper>
                  <PlantingOrganizers />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/setting/organizers/add/:id" exact>
              <ProtectedRoute>
                <Wrapper>
                  <AddPlantingOrganizer />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/setting/organizers/edit/:id" exact>
              <ProtectedRoute>
                <Wrapper>
                  <EditPlantingOrganizer />
                </Wrapper>
              </ProtectedRoute>
            </Route>

            <Route path="/setting/responsible" exact>
              <ProtectedRoute>
                <Wrapper>
                  <RegisterResponsible />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/setting/responsible/add-responsible" exact>
              <ProtectedRoute>
                <Wrapper>
                  <AddResponsibleType />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/setting/responsible/edit-responsible/:id" exact>
              <ProtectedRoute>
                <Wrapper>
                  <EditResponsibleType />
                </Wrapper>
              </ProtectedRoute>
            </Route>

            <Route path="/setting/provinces" exact>
              <ProtectedRoute>
                <Wrapper>
                  <Departments />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/setting/provinces/add-province" exact>
              <ProtectedRoute>
                <Wrapper>
                  <AddDepartment />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/setting/provinces/edit-province/:id" exact>
              <ProtectedRoute>
                <Wrapper>
                  <EditDepartment />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/setting/scientific-names" exact>
              <ProtectedRoute>
                <Wrapper>
                  <ScientificNames />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/setting/scientific-names/add-scientific-name" exact>
              <ProtectedRoute>
                <Wrapper>
                  <AddScientificName />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/setting/incidents/add-incident" exact>
              <ProtectedRoute>
                <Wrapper>
                  <AddIncident />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/setting/incidents/edit-incident/:id" exact>
              <ProtectedRoute>
                <Wrapper>
                  <EditIncident />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/setting/incidents" exact>
              <ProtectedRoute>
                <Wrapper>
                  <Incident />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/setting/botanical-families" exact>
              <ProtectedRoute>
                <Wrapper>
                  <BotanicalFamily />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route
              path="/setting/botanical-families/add-botanical-family"
              exact
            >
              <ProtectedRoute>
                <Wrapper>
                  <AddBotanicalFamily />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route
              path="/setting/botanical-families/edit-botanical-family/:id"
              exact
            >
              <ProtectedRoute>
                <Wrapper>
                  <EditBotanicalFamily />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/setting/tree-origins" exact>
              <ProtectedRoute>
                <Wrapper>
                  <TreeOrigin />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/setting/tree-origins/add-tree-origin" exact>
              <ProtectedRoute>
                <Wrapper>
                  <AddTreeOrigin />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/setting/tree-origins/edit-tree-origin/:id" exact>
              <ProtectedRoute>
                <Wrapper>
                  <EditTreeOrigin />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/setting/growth-stages/add-growth-stage" exact>
              <ProtectedRoute>
                <Wrapper>
                  <AddGrowthStage />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/setting/growth-stages/edit-growth-stage/:id" exact>
              <ProtectedRoute>
                <Wrapper>
                  <EditGrowthStage />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/setting/growth-stages" exact>
              <ProtectedRoute>
                <Wrapper>
                  <GrowthStage />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route
              path="/setting/scientific-names/edit-scientific-name/:id"
              exact
            >
              <ProtectedRoute>
                <Wrapper>
                  <EditScientificName />
                </Wrapper>
              </ProtectedRoute>
            </Route>
            <Route path="/registers/:id" exact>
              <Wrapper>
                <Register />
              </Wrapper>
            </Route>
            <Route path="/map" exact>
              <Wrapper>
                <MapPage />
              </Wrapper>
            </Route>
            <Route path="/registers/add-register/:id" exact>
              <Wrapper>
                <AddRegister />
              </Wrapper>
            </Route>
            <Route path="/registers/edit-register/:id" exact>
              <Wrapper>
                <EditRegister />
              </Wrapper>
            </Route>
            <Route path="/registers/view-register/:id" exact>
              <Wrapper>
                <ViewRegister />
              </Wrapper>
            </Route>
            <Route path="/registers/growth-history/:id" exact>
              <Wrapper>
                <ViewGrowthHistory />
              </Wrapper>
            </Route>
            <Route path="/login" exact>
              <Redirect to="/statistics" />
            </Route>
            <Route path="/" exact>
              <Redirect to="/statistics" exact />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
          <Wrapper>
            <div className="parent-container">
              <Footer />
            </div>
          </Wrapper>
        </Fragment>
      ) : (
        <GoogleReCaptchaProvider reCaptchaKey="6LfZYuEhAAAAAGpUOxsFaMK4mmoqE6I1ihgvbWOm">
          <Switch>
            <Route path="/login" exact>
              <Login />
            </Route>
            <Route path="/forgotPassword" exact>
              <ForgotPassword />
            </Route>
            <Route path="/reset-password/:id" exact>
              <ResetPassword />
            </Route>
            <Route path="/invalid-url" exact>
              <InvalidURL />
            </Route>
            <Route path="/" exact>
              <Redirect to="/login" />
            </Route>
            <Route path="*">
              <Redirect to="/login" />
            </Route>
          </Switch>
        </GoogleReCaptchaProvider>
      )}
      {showProfile ? <UserProfile hideUserProfile={hideUserProfile} /> : null}
    </Fragment>
  );
}

export default App;
