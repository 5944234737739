import { ChangeEvent, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { plantingTheme } from "../../Register/RegisterList/PlantingTheme";
import { useDispatch, useSelector } from "react-redux";
import { userFilterDelete, userFilterSet } from "../../../store";
import { useTranslation } from "react-i18next";
import { userFilterAction } from "../../../store/userFilter-slice";

type FormValues = {
  role: string;
  isActive: string;
};

const UserFilterBox = () => {
  const { handleSubmit, resetField, control, reset } = useForm<FormValues>();
  const isFilterSet = useSelector(userFilterSet);
  const deleteItem = useSelector(userFilterDelete);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (deleteItem !== "") {
      resetField(deleteItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteItem]);

  const applyFilter = (data: FormValues) => {
    dispatch(
      userFilterAction.setFilter({
        isActive: data?.isActive || "",
        role: data?.role || "",
      })
    );
    dispatch(userFilterAction.isFilterSet());
    dispatch(userFilterAction.setURL());
  };
  const clearFliter = () => {
    reset();
    dispatch(userFilterAction.clearFilter());
    dispatch(userFilterAction.setURL());
  };
  return (
    <div className="w-full h-fit bg-white p-4 rounded-md relative mt-3">
      <form onSubmit={handleSubmit(applyFilter)}>
        <ThemeProvider theme={plantingTheme}>
          <div className="flex flex-wrap justify-between w-full sm:w-[80%] md:w-[65%] mt-5">
            <div className="w-full lg:w-[48%]">
              <p className="text-sm mb-[6px] text-[#414141]">
                {t("Users.Role")}
              </p>
              <Controller
                control={control}
                name="role"
                render={({ field: { onChange, value } }) => (
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel shrink={false}>
                      {!value && `${t("Users.Role")}`}
                    </InputLabel>
                    <Select
                      style={{ height: "38px" }}
                      IconComponent={ExpandMoreIcon}
                      variant="outlined"
                      value={value || null}
                      onChange={(e) => {
                        onChange(e as ChangeEvent<Element>);
                      }}
                      sx={{
                        border: "1px solid #eeecec",
                        borderRadius: "4px",
                        "& fieldset": { border: "none" },
                      }}
                    >
                      <MenuItem value="A">{t("Users.Admin")}</MenuItem>
                      <MenuItem value="C">{t("Users.Coordinator")}</MenuItem>
                      <MenuItem value="O">
                        {t("Organizer.Collaborator")}
                      </MenuItem>
                      <MenuItem value="V">{t("Users.Visitor")}</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </div>
            <div className="w-full lg:w-[48%] mt-5 lg:mt-0">
              <p className="text-sm mb-[6px] text-[#414141]">
                {t("Province.Status")}
              </p>

              <Controller
                control={control}
                name="isActive"
                render={({ field: { onChange, value } }) => (
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel shrink={false}>
                      {!value && `${t("Workshops.Workshop status")}`}
                    </InputLabel>
                    <Select
                      style={{ height: "38px" }}
                      IconComponent={ExpandMoreIcon}
                      variant="outlined"
                      value={value || null}
                      onChange={(e) => {
                        onChange(e as ChangeEvent<Element>);
                      }}
                      sx={{
                        border: "1px solid #eeecec",
                        borderRadius: "4px",
                        "& fieldset": { border: "none" },
                      }}
                    >
                      <MenuItem value="true">{t("Province.Active")}</MenuItem>
                      <MenuItem value="false">
                        {t("Province.Inactive")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </div>
          </div>
        </ThemeProvider>
        <div className="mt-5 sm:mt-0 sm:absolute sm:right-4 sm:bottom-4">
          {isFilterSet && (
            <button
              onClick={clearFliter}
              className="py-2 px-6 text-sm font-medium rounded-3xl text-[#414141] hover:bg-[#F6F6F6] mr-3"
              type="button"
            >
              {t("PlantingFilter.Reset")}
            </button>
          )}

          <button
            type="submit"
            className=" hover:bg-ph-hover hover:text-white text-ph-btn py-2 px-6 border border-ph-btn text-sm font-medium rounded-3xl"
          >
            {t("PlantingFilter.Apply")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserFilterBox;
