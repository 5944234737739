import { ThemeProvider } from "@emotion/react";
import { theme } from "./FormTheme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  autocompleteClasses,
} from "@mui/material";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  faMagnifyingGlass,
  faCircleInfo,
  faLock,
  faLockOpen,
} from "@fortawesome/pro-light-svg-icons";
import { faLocationDot, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import exifr from "exifr";
import { useTranslation } from "react-i18next";
import file from "../../../type/file";
import TranslationField from "../../../type/translationField";
import DragDropField from "../../Global/DragDropField";
import useAxios from "../../../hook/useAxios";
import OldFile from "../../Global/OldFile";
import SpeciesList from "../../../type/speciesList";
import LanguagePoint from "../../Global/LanguagePoint";
import SelectLanguage from "../../Global/SelectLanguage";
import Warning from "../../ErrorAndAlert/Warning";
import NumberField from "../../Global/NumberField";
import AddRegisterButton from "./AddRegisterButton";
import { ResponsibleType } from "../../../type/responsibleType";
import AddResponsibleType from "./AddResponsibleType";
import LocationPage from "../GetLocation/LocationPage";
import LocationAlert from "../../ErrorAndAlert/LocationAlert";
import CustomAutocomplete from "../../Global/CustomAutocomplete";
import UnlockAgeAlert from "../../ErrorAndAlert/UnlockAgePopup";

type FormValues = {
  treeSpecies: SpeciesList;
  incidents: SpeciesList[];
  height: number;
  diameter: number;
  ageYear: string;
  ageMonth: string;
  responsibleType: ResponsibleType;
  latitude: number;
  longitude: number;
  origin: SpeciesList;
  responsible: string;
  code: string;
  planting_plot: string;
};

const AddRegisterForm: React.FC<{
  send: (data: any) => void;
  selectOptions: {
    treeSpecies: SpeciesList[];
    grp: ResponsibleType[];
    ins: ResponsibleType[];
    ind: ResponsibleType[];
    fml: ResponsibleType[];
    incidents: SpeciesList[];
    origins: SpeciesList[];
  };
  sendResponse: any;
  sendError: any;
  addOrganizer: (type: string, value: ResponsibleType) => void;
  getInfo?: any;
}> = (props) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState<string>("EN");
  const [resChange, isResChange] = useState<boolean>(false);
  const [description, setDescription] = useState<TranslationField>({
    en: "",
    fr: "",
  });
  const [resources, setResources] = useState<file[]>([]);
  const [replanting, isReplanting] = useState<boolean>(false);
  const [addNewRegister, isAddRegister] = useState<boolean>(false);
  const [responsibleType, setResponsibleType] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [cellPhoneImage, setCellPhoneImage] = useState<file[]>([]);
  const [addCollaboratorName, isAddCollaboratorName] = useState<boolean>(false);
  const [oldCellPhoneImage, setOldCellPhoneImage] = useState<string>("");
  const [upload, isUpload] = useState<boolean>(false);
  const [oldResource, setOldResource] = useState<
    {
      id: number;
      file_address: string;
      thumbnail_address: string;
      is_image: boolean;
    }[]
  >([]);
  const [deleteRecourse, setDeleteResource] = useState<number[]>([]);
  const [requestNumber, setRequestNumber] = useState<number>(1);
  const [dirty, isDirty] = useState<boolean>(true);
  const [fileIndex, setFileIndex] = useState<number>(0);
  const [locationView, isLocationView] = useState<boolean>(false);
  const [alert, isAlert] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [unlockAlert, isUnlockAlert] = useState<boolean>(false);
  const [isCategoryChanged, setIsCategoryChanged] = useState<boolean>(false);

  const { id } = useParams<any>();

  const { fetchData: sendFile, response: fileResp } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/files/new/",
    "POST",
    false,
    "",
    true,
    true,
    "multipart/form-data"
  );

  const { fetchData: deleteImage } = useAxios(
    "",
    "DELETE",
    false,
    "",
    true,
    true
  );

  const {
    handleSubmit,
    setValue,
    register,
    getValues,
    resetField,
    clearErrors,
    control,
    formState: { errors },
    setError,
  } = useForm<FormValues>();
  const history = useHistory();
  const handleLangChange = (event: SelectChangeEvent) => {
    setLang(event.target.value);
  };

  useEffect(() => {
    if (props.sendResponse) {
      setRequestNumber((prevState) => prevState - 1);
      if (resources.length > 0) {
        const formData = new FormData();
        formData.append("file_address", resources[fileIndex].data);
        formData.append("model_name", "Register");
        formData.append("input_slug", props.sendResponse.data.slug);
        sendFile(formData);
      }
      if (deleteRecourse.length > 0) {
        deleteRecourse.forEach((r) => {
          setRequestNumber((prevState) => prevState - 1);
          deleteImage(
            { id: r },
            process.env.REACT_APP_API_URL + `/api/admin/files/${r}/delete/`
          );
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sendResponse]);

  useEffect(() => {
    if (props.sendError) {
      if (props.sendError.response.status === 400) {
        isUpload(false);
      }
    }
  }, [props.sendError]);

  useEffect(() => {
    if (props.getInfo) {
      setOldResource(props.getInfo.data.files);
      setOldCellPhoneImage(props.getInfo.data.planted_tree_image);
      setDescription({
        en: props.getInfo.data.brief_description_transes.en,
        fr: props.getInfo.data.brief_description_transes.fr,
      });
      setResponsibleType(props.getInfo.data.responsible_category);
      setValue("responsibleType", {
        id: props.getInfo.data.responsible_type_id,
        slug: props.getInfo.data.responsible_type_slug,
        name: props.getInfo.data.responsible_type_name,
      });
      setValue("responsible", props.getInfo.data.responsible_name);
      setValue("treeSpecies", {
        slug: props.getInfo.data.species_slug,
        name_transes: props.getInfo.data.species_name_transes,
      });
      setValue("height", props.getInfo.data.last_height);
      setValue("diameter", props.getInfo.data.last_diameter);
      setValue("ageYear", props.getInfo.data.age_year);
      setValue("ageMonth", props.getInfo.data.age_month);
      setValue("latitude", props.getInfo.data.latitude);
      setValue("longitude", props.getInfo.data.longitude);
      setValue("incidents", props.getInfo.data.incidents);
      setValue("origin", {
        slug: props.getInfo.data.origin_slug,
        name_transes: props.getInfo.data.origin_transes,
      });
      setValue("code", props.getInfo.data.code);
      setValue("planting_plot", props.getInfo.data.planting_plot);
      isReplanting(props.getInfo.data.is_replanting);
      setLoading(false);
      setIsReadOnly(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getInfo]);

  useEffect(() => {
    if (fileResp) {
      setRequestNumber((prevState) => prevState - 1);
      setFileIndex(fileIndex + 1);
      if (resources.length - 1 >= fileIndex + 1) {
        const formData = new FormData();
        formData.append("file_address", resources[fileIndex + 1].data);
        formData.append("model_name", "Register");
        formData.append("input_slug", props.sendResponse.data.slug);
        sendFile(formData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileResp]);

  useEffect(() => {
    if (requestNumber === 0) {
      resetField("height");
      resetField("latitude");
      resetField("code");
      resetField("longitude");
      resetField("incidents");
      setDescription({ fr: "", en: "" });
      setCellPhoneImage([]);
      setFileIndex(0);
      setResources([]);
      toast.success(
        `${
          props.getInfo
            ? `${t("Message.Successful update")}`
            : `${t("Message.Successful add")}`
        }`
      );
      isUpload(false);
      if (props.getInfo) {
        history.goBack();
      }
      if (addNewRegister) {
        history.replace(`/registers/${id}`);
      }
      setRequestNumber(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestNumber]);

  useEffect(() => {
    if (isCategoryChanged) {
      setValue("responsibleType", { id: 0, slug: "", name: "" });
      clearErrors("responsibleType");
    }
    setIsCategoryChanged(false);
  }, [isCategoryChanged, setValue, clearErrors]);

  const addNewResponsibleName = (type: string, value: ResponsibleType) => {
    setValue("responsibleType", value);
    isResChange(true);
    props.addOrganizer(type, value);
  };

  const insertFile = async (newFile: file[]) => {
    if (!newFile[0].type.includes("image")) {
      toast.error(`${t("Message.Image supported formats")}`);
    } else if (newFile[0].size > 10000000) {
      toast.error(`${t("Message.Maximum file size is 10 MB")}`);
    } else {
      setCellPhoneImage([newFile[0]]);
      try {
        const exifData = await exifr.gps(newFile[0].data);
        if (exifData?.latitude) {
          setValue("latitude", exifData.latitude);
          setValue("longitude", exifData.longitude);
        } else {
          isAlert(true);
        }
      } catch (error) {
        isAlert(true);
      }
      setOldCellPhoneImage("");
    }
  };

  const removeFile = (index: number) => {
    setCellPhoneImage([]);
  };

  const removeOldFile = (index: number) => {
    setOldCellPhoneImage("");
  };

  const descriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (lang === "EN") {
      setDescription({ en: event.target.value, fr: description.fr });
    } else if (lang === "FR") {
      setDescription({ fr: event.target.value, en: description.en });
    }
  };

  const insertResources = (files: file[]) => {
    files.forEach((f) => {
      if (f.size > 10000000) {
        toast.error(`${t("Message.Maximum file size is 10 MB")}`);
      } else {
        setRequestNumber((prevState) => prevState + 1);
        setResources((prevState) => [...prevState, f]);
      }
    });
  };

  const removeOldResource = (fileId: number) => {
    setDeleteResource((prevState) => [...prevState, fileId]);
    setOldResource((prevState) =>
      prevState.filter((prevItem) => prevItem.id !== fileId)
    );
    setRequestNumber((prevState) => prevState + 1);
  };

  const removeResource = (indexFile: number) => {
    setRequestNumber((prevState) => prevState - 1);
    setResources((prevState) =>
      prevState.filter((prevItem, index: number) => index !== indexFile)
    );
  };

  const replantingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    isReplanting(event.target.checked);
  };

  const addRegisterItem = (data: FormValues) => {
    if (description.fr && !description.en) {
      toast.error(`${t("Message.Empty default language error")}`);
    }
    if (
      responsibleType &&
      responsibleType !== "ind" &&
      !getValues("responsibleType").name
    ) {
      setError("responsibleType", {
        type: "required",
        message: `${t("Message.Required field")}`,
      });
    } else {
      isDirty(false);
      const inci: string[] = data.incidents?.map((incident) => incident.slug);
      const formData = new FormData();
      if (resChange) {
        if (responsibleType === "ind") {
          formData.append("type_slug ", props.selectOptions.ind[0].slug);
        } else {
          formData.append("type_slug ", data.responsibleType.slug);
        }
        formData.append("responsible_name", data.responsible);
      }

      formData.append("species", data.treeSpecies.slug);
      formData.append("code", data.code);
      formData.append("is_replanting", replanting + "");
      formData.append("age_year", `${data.ageYear ? data.ageYear + "" : ""}`);
      formData.append("planting_plot", data.planting_plot || "");
      formData.append(
        "age_month",
        `${data.ageMonth ? data.ageMonth + "" : ""}`
      );
      if (!props.getInfo) {
        if (inci?.length > 0) {
          inci.forEach((incid: string, index: number) => {
            formData.append("incidents", incid);
          });
        }
        formData.append("height", `${data.height ? data.height + "" : ""}`);

        formData.append(
          "diameter",
          `${data.diameter ? data.diameter + "" : ""}`
        );
      }
      formData.append("origin", data.origin.slug);
      formData.append("latitude", data.latitude + "");
      formData.append("longitude", data.longitude + "");
      if (!oldCellPhoneImage && cellPhoneImage.length !== 0) {
        formData.append("planted_tree_image", cellPhoneImage[0]?.data);
      } else if (!oldCellPhoneImage && cellPhoneImage.length === 0) {
        formData.append("planted_tree_image", "");
      }
      formData.append("brief_description_transes", JSON.stringify(description));
      isUpload(true);
      props.send(formData);
    }
  };

  const closeMap = () => {
    isLocationView(false);
  };

  const latLongChange = (newLat: number, newLong: number) => {
    isLocationView(false);
    clearErrors("latitude");
    clearErrors("longitude");
    const floatPattern = /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/;
    if (floatPattern.test(String(newLat))) {
      setValue("latitude", newLat);
    } else {
      setError("latitude", {
        type: "pattern",
        message: `${t("Message.Invalid number")}`,
      });
    }
    if (floatPattern.test(String(newLong))) {
      setValue("longitude", newLong);
    } else {
      setError("longitude", {
        type: "pattern",
        message: `${t("Message.Invalid number")}`,
      });
    }
  };

  const handleAddLocation = () => {
    setIsAdd(true);
    isLocationView(true);
    isAlert(false);
  };

  const unlockHandler = () => {
    isUnlockAlert(true);
  };

  const handleConfirm = () => {
    isUnlockAlert(false);
    setIsReadOnly(false);
  };

  const handleCancel = () => {
    isUnlockAlert(false);
  };

  let latErrorDisplayed = false;
  let longErrorDisplayed = false;

  return (
    <Fragment>
      {loading && props.getInfo ? (
        <p className="text-center text-2xl font-medium text-[#414141]">
          {t("Message.Loading")} ...
        </p>
      ) : (
        <form onSubmit={handleSubmit(addRegisterItem)}>
          <ThemeProvider theme={theme}>
            <p className="text-sm text-[#414141] mb-[6px]">
              {t("AddPlanting.Language")}
            </p>
            <SelectLanguage handleLangChange={handleLangChange} lang={lang} />
            <div className="flex flex-wrap justify-between mt-7">
              <div className="w-full md:w-1/2 md:pr-2">
                <p className="text-sm text-[#414141] mb-[6px]">
                  {t("Navbar.Tree species")} *
                </p>
                <CustomAutocomplete
                  rules={{ required: `${t("Message.Required field")}` }}
                  control={control}
                  name="treeSpecies"
                  selectOptions={props.selectOptions.treeSpecies}
                  placeholder={t("Navbar.Tree species")}
                  getOptionLabel={(option) =>
                    i18n.language === "fr"
                      ? option.name_transes?.fr === ""
                        ? option.name_transes?.en
                        : option.name_transes?.fr
                      : option.name_transes?.en
                  }
                />
                {errors.treeSpecies && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
              <div className="w-full md:w-1/2 md:pl-2 mt-7 md:mt-0">
                <p className="text-sm text-[#414141] mb-[6px]">
                  {t("Register.Tree height")}{" "}
                  <span className="text-[#707070]">in “cm”</span>
                </p>
                <NumberField
                  onChange={() => {
                    clearErrors("height");
                  }}
                  getValues={getValues}
                  inputStyle={`py-3 ${
                    !props.getInfo
                      ? "placeholder-[#C7C7C7]"
                      : "placeholder-[#FFFFFF]"
                  }`}
                  name="height"
                  placeholder={t("Register.Tree height") + ""}
                  register={register("height", {
                    pattern: /^[+]?\d+/,
                  })}
                  setValue={setValue}
                  styleClass="w-full"
                  readOnl={props.getInfo ? true : false}
                />
                {errors.height?.type === "pattern" && (
                  <p className="error-text">{t("Message.Invalid number")}</p>
                )}
              </div>
            </div>

            <div className="flex flex-wrap justify-between mt-7">
              <div className="w-full md:w-[48%]">
                <div className="mb-[6px] mt-[-4px] flex justify-between items-center">
                  <p className="text-sm">{t("Register.Planting age")}</p>

                  {props.getInfo && isReadOnly && (
                    <button type="button" onClick={unlockHandler}>
                      <FontAwesomeIcon
                        icon={faLock}
                        className="text-[#414141]"
                      />
                    </button>
                  )}
                  {props.getInfo && !isReadOnly && (
                    <FontAwesomeIcon
                      icon={faLockOpen}
                      className="text-[#414141]"
                    />
                  )}
                </div>
                <div className="flex items-center">
                  <Controller
                    control={control}
                    name="ageYear"
                    render={({ field: { onChange, value } }) => (
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel
                          shrink={false}
                          style={{
                            color: isReadOnly ? "#FFFFFF" : undefined,
                          }}
                        >
                          {!value && `${t("Register.Year")}`}
                        </InputLabel>
                        <Select
                          style={{
                            height: "48px",
                          }}
                          IconComponent={ExpandMoreIcon}
                          variant="outlined"
                          value={value || null}
                          onChange={(e) => {
                            onChange(e as ChangeEvent<Element>);
                          }}
                          disabled={isReadOnly}
                          sx={{
                            border: "1px solid #eeecec",
                            borderRadius: "4px",
                            "& fieldset": {
                              border: "none",
                            },
                            "& .MuiSvgIcon-root": {
                              color: isReadOnly
                                ? "#FFFFFF !important"
                                : undefined,
                            },
                            "& .MuiInputBase-input": {
                              backgroundColor: isReadOnly
                                ? "#A2A1A1  "
                                : undefined,
                            },
                          }}
                        >
                          <MenuItem value="0">0</MenuItem>
                          <MenuItem value="1">1</MenuItem>
                          <MenuItem value="2">2</MenuItem>
                          <MenuItem value="3">3</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                  <span className="px-2 text-[#414141] text-xs">&#9866;</span>
                  <Controller
                    control={control}
                    name="ageMonth"
                    render={({ field: { onChange, value } }) => (
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel
                          shrink={false}
                          style={{
                            color: isReadOnly ? "#FFFFFF" : undefined,
                          }}
                        >
                          {!value && `${t("Register.Month")}`}
                        </InputLabel>
                        <Select
                          style={{ height: "48px" }}
                          IconComponent={ExpandMoreIcon}
                          variant="outlined"
                          value={value || null}
                          onChange={(e) => {
                            onChange(e as ChangeEvent<Element>);
                          }}
                          disabled={isReadOnly}
                          sx={{
                            border: "1px solid #eeecec",
                            borderRadius: "4px",
                            "& fieldset": { border: "none" },
                            "& .MuiSvgIcon-root": {
                              color: isReadOnly
                                ? "#FFFFFF !important"
                                : undefined,
                            },
                            "& .MuiInputBase-input": {
                              backgroundColor: isReadOnly
                                ? "#A2A1A1 !important"
                                : undefined,
                            },
                          }}
                        >
                          <MenuItem value="0">0</MenuItem>
                          <MenuItem value="1">1</MenuItem>
                          <MenuItem value="2">2</MenuItem>
                          <MenuItem value="3">3</MenuItem>
                          <MenuItem value="4">4</MenuItem>
                          <MenuItem value="5">5</MenuItem>
                          <MenuItem value="6">6</MenuItem>
                          <MenuItem value="7">7</MenuItem>
                          <MenuItem value="8">8</MenuItem>
                          <MenuItem value="9">9</MenuItem>
                          <MenuItem value="10">10</MenuItem>
                          <MenuItem value="11">11</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 md:pl-2 mt-7 md:mt-0">
                <p className="text-sm text-[#414141] mb-[6px]">
                  {t("Register.Diameter")}{" "}
                  <span className="text-[#707070]">in “mm”</span>
                </p>
                <NumberField
                  onChange={() => {
                    clearErrors("diameter");
                  }}
                  getValues={getValues}
                  inputStyle={`py-3 ${
                    !props.getInfo
                      ? "placeholder-[#C7C7C7]"
                      : "placeholder-[#FFFFFF]"
                  }`}
                  name="diameter"
                  placeholder={t("Register.Diameter") + ""}
                  register={register("diameter", {
                    pattern: /^[+]?\d+/,
                  })}
                  setValue={setValue}
                  styleClass="w-full"
                  readOnl={props.getInfo ? true : false}
                />
                {errors.diameter?.type === "pattern" && (
                  <p className="error-text">{t("Message.Invalid number")}</p>
                )}
              </div>
            </div>

            <div className="flex flex-wrap justify-between mt-7">
              <div className="w-full md:w-1/2 md:pr-2">
                <p className="text-sm text-[#414141] mb-[6px]">
                  {t("Register.Nursery")} *
                </p>
                <CustomAutocomplete
                  rules={{ required: `${t("Message.Required field")}` }}
                  control={control}
                  name="origin"
                  selectOptions={props.selectOptions.origins}
                  placeholder={t("Register.Nursery")}
                  getOptionLabel={(option) =>
                    i18n.language === "fr"
                      ? option.name_transes?.fr === ""
                        ? option.name_transes?.en
                        : option.name_transes?.fr
                      : option.name_transes?.en
                  }
                />
                {errors.origin && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
              <div className="w-full md:w-1/2 md:pl-2 mt-7 md:mt-0 ">
                <p className="text-sm text-[#414141] mb-[6px]">
                  {t("Incidents.Incidents")}
                </p>
                <CustomAutocomplete
                  control={control}
                  name="incidents"
                  isMulti={true}
                  defaultValue={getValues("incidents")}
                  selectOptions={props.selectOptions.incidents}
                  placeholder={t("Incidents.Incidents")}
                  getOptionLabel={(option) =>
                    i18n.language === "fr"
                      ? option.name_transes?.fr === ""
                        ? option.name_transes?.en
                        : option.name_transes?.fr
                      : option.name_transes?.en
                  }
                  readonly={props.getInfo ? true : false}
                  disabled={props.getInfo ? true : false}
                  incident={props.getInfo ? true : false}
                />
              </div>
            </div>
            <div className="flex flex-wrap justify-between mt-7">
              <div className="w-full sm:w-[48.5%]">
                <p className="text-sm text-[#414141] mb-[6px]">
                  {t("Register.Code")}*
                </p>
                <input
                  {...register("code", {
                    required: `${t("Message.Required field")}`,
                  })}
                  type="text"
                  className="input-field pl-4 py-3 text-sm placeholder-[#C7C7C7]"
                  placeholder={t("Register.Code") + ""}
                />
                {errors.code && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
              <div className="w-full sm:w-[48.5%]">
                <p className="text-sm text-[#414141] mb-[6px]">
                  {t("Register.Planting plot")}
                </p>
                <Controller
                  control={control}
                  name="planting_plot"
                  render={({ field: { onChange, value } }) => (
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel shrink={false}>
                        {!value && `${t("Register.Planting plot")}`}
                      </InputLabel>
                      <Select
                        style={{
                          height: "46px",
                        }}
                        IconComponent={ExpandMoreIcon}
                        variant="outlined"
                        value={value || null}
                        onChange={(e) => {
                          onChange(e as ChangeEvent<Element>);
                        }}
                        sx={{
                          border: "1px solid #eeecec",
                          borderRadius: "4px",
                          "& fieldset": { border: "none" },
                        }}
                      >
                        <MenuItem value="ind">
                          {t("Register.Individual")}
                        </MenuItem>
                        <MenuItem value="com">
                          {t("Register.Communal")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </div>
              <div className="w-full sm:w-[48.5%] mt-7">
                <p className="text-sm text-[#414141] ml-1">
                  {t("Register.Replanting")}
                </p>
                <Switch
                  color="warning"
                  checked={replanting}
                  onChange={replantingChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </div>
            <p className="hr-lines-responsible mt-7">
              {t("Register.Responsible")}
            </p>
            <p className="text-sm text-[#414141] mt-4">
              {t("Register.Responsible")} *
            </p>
            <input
              {...register("responsible", {
                required: `${t("Message.Required field")}`,
              })}
              type="text"
              onChange={() => {
                isResChange(true);
                clearErrors("responsible");
              }}
              className="input-field text-sm pl-4 py-3 placeholder-[#C7C7C7]"
              placeholder={t("Register.Responsible") + ""}
            />

            {errors.responsible && (
              <p className="error-text">{t("Message.Required field")}</p>
            )}
            <div className="flex flex-wrap justify-between mt-7">
              <div className="w-full md:w-1/2 md:pr-2">
                <p className="text-sm text-[#414141] mb-[6px]">
                  {t("Register.Category")} *
                </p>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel shrink={false}>
                    {responsibleType === "" && t("Register.Category")}
                  </InputLabel>
                  <Select
                    style={{ height: "48px" }}
                    IconComponent={ExpandMoreIcon}
                    variant="outlined"
                    value={responsibleType}
                    onChange={(e: SelectChangeEvent<string>) => {
                      isResChange(true);
                      setIsCategoryChanged(true);
                      setResponsibleType(e.target.value);
                    }}
                    sx={{
                      border: "1px solid #eeecec",
                      borderRadius: "4px",
                      "& fieldset": { border: "none" },
                    }}
                  >
                    <MenuItem value="fml">{t("Register.Family")}</MenuItem>
                    <MenuItem value="grp">{t("Register.Group")}</MenuItem>
                    <MenuItem value="ind">{t("Register.Individual")}</MenuItem>
                    <MenuItem value="ins">{t("Register.Institution")}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="w-full md:w-1/2 md:pl-2 mt-7 md:mt-0">
                <p className="text-sm text-[#414141] mb-[6px]">
                  {t("Tree Species.Type")} *
                </p>
                <Controller
                  control={control}
                  name="responsibleType"
                  rules={{
                    validate: (value) =>
                      responsibleType && responsibleType !== "ind"
                        ? value
                          ? true
                          : `${t("Message.Required field")}`
                        : true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      popupIcon={
                        <FontAwesomeIcon
                          className="w-4 h-4"
                          icon={faMagnifyingGlass}
                        />
                      }
                      id="combo-box-demo"
                      disabled={
                        responsibleType && responsibleType !== "ind"
                          ? false
                          : true
                      }
                      // @ts-ignore
                      options={
                        responsibleType === "fml"
                          ? props.selectOptions.fml
                          : responsibleType === "ins"
                          ? props.selectOptions.ins
                          : props.selectOptions.grp
                      }
                      // @ts-ignore
                      getOptionLabel={(option) => option.name}
                      sx={{
                        width: "100%",
                        [`& .${autocompleteClasses.popupIndicator}`]: {
                          transform: "none",
                        },
                        "& .Mui-focused": {
                          outline: "1px solid #439FC6",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          sx={{
                            border: "1px solid #eeecec",
                            borderRadius: "4px",
                            "& fieldset": { border: "none" },
                          }}
                          placeholder={t("Tree Species.Type") + ""}
                        />
                      )}
                      onChange={(
                        event: any,
                        newValue: ResponsibleType | null
                      ) => {
                        isResChange(true);
                        onChange(newValue as ResponsibleType);
                      }}
                      renderOption={(prop, option: any, { selected }) => (
                        <Fragment>
                          {(option.slug === props.selectOptions?.fml[0]?.slug ||
                            option.slug === props.selectOptions?.ins[0]?.slug ||
                            option.slug ===
                              props.selectOptions?.grp[0]?.slug) && (
                            <div className="border-b border-ph-iborder">
                              <button
                                onClick={() => isAddCollaboratorName(true)}
                                className="w-full items-center flex justify-between text-sm px-4 text-[#066993] py-2 "
                              >
                                {t("Register.Create new type")}
                                <FontAwesomeIcon
                                  className="ml-2 float-right"
                                  icon={faPlus}
                                />
                              </button>
                            </div>
                          )}
                          <li {...prop} key={option.slug}>
                            {option.name}
                          </li>
                        </Fragment>
                      )}
                      value={value || null}
                    />
                  )}
                />
                {errors.responsibleType && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>
          </ThemeProvider>
          <p className="text-sm text-[#414141] mt-7 mb-[6px]">
            {t("Register.Planted tree image")}
            <span
              title={t("Register.Tree image hint") + ""}
              className="text-[#707070] ml-2"
            >
              <FontAwesomeIcon icon={faCircleInfo} />
            </span>
          </p>
          <DragDropField
            files={cellPhoneImage}
            format="png, jpg, jpeg (max 10 MB)"
            insertFile={insertFile}
            removeFile={removeFile}
          />

          {oldCellPhoneImage && (
            <OldFile
              address={oldCellPhoneImage}
              id={0}
              delete={true}
              removeOldFile={removeOldFile}
            />
          )}

          <div className="flex flex-wrap justify-between mt-3">
            <div className="w-1/2 pr-2">
              <p className="text-sm text-[#414141] mb-[6px]">
                {t("AddPlanting.Latitude")} *
              </p>
              <input
                {...register("latitude", {
                  required: `${t("Message.Required field")}`,
                  pattern: /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/,
                })}
                onChange={() => {
                  clearErrors("latitude");
                }}
                type="text"
                className="input-field pl-4 py-3 text-sm placeholder-[#C7C7C7]"
                placeholder={t("AddPlanting.Latitude") + ""}
              />
              {errors.latitude?.type === "pattern" && !latErrorDisplayed && (
                <>
                  {(latErrorDisplayed = true)}
                  <p className="error-text">{t("Message.Invalid number")}</p>
                </>
              )}
              {errors.latitude && !latErrorDisplayed && (
                <>
                  {(latErrorDisplayed = true)}
                  <p className="error-text">{t("Message.Required field")}</p>
                </>
              )}
            </div>
            <div className="w-1/2 pl-2">
              <p className="text-sm text-[#414141] mb-[6px]">
                {t("AddPlanting.Longitude")}*
              </p>
              <input
                {...register("longitude", {
                  required: `${t("Message.Required field")}`,
                  pattern: /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/,
                })}
                onChange={() => {
                  clearErrors("longitude");
                }}
                type="text"
                className="input-field pl-4 py-3 text-sm placeholder-[#C7C7C7]"
                placeholder={t("AddPlanting.Longitude") + ""}
              />
              {errors.longitude?.type === "pattern" && !longErrorDisplayed && (
                <>
                  {(longErrorDisplayed = true)}
                  <p className="error-text">{t("Message.Invalid number")}</p>
                </>
              )}
              {errors.longitude && !longErrorDisplayed && (
                <>
                  {(longErrorDisplayed = true)}
                  <p className="error-text">{t("Message.Required field")}</p>
                </>
              )}
            </div>
          </div>
          <button
            onClick={() => {
              isLocationView(true);
              setIsAdd(false);
            }}
            type="button"
            className="text-sm text-[#066993] mb-[6px] mt-5"
          >
            <FontAwesomeIcon className="pr-1" icon={faLocationDot} />
            {t("Register.Edit location on the map")}
          </button>

          <p className="text-sm text-[#414141] mt-7 mb-[6px]">
            {t("AddPlanting.Relevant resources")}{" "}
            <span
              title={t("Register.Register relevant resources hint") + ""}
              className="text-[#707070] ml-2"
            >
              <FontAwesomeIcon icon={faCircleInfo} />
            </span>
          </p>
          <DragDropField
            files={resources}
            format="jpg, jpeg, png, txt, doc, docx, pdf, xls, xlsx (max 10 MB)"
            insertFile={insertResources}
            removeFile={removeResource}
          />
          {oldResource.map((or) => (
            <OldFile
              address={or.file_address}
              id={or.id}
              delete={true}
              removeOldFile={removeOldResource}
              key={or.id}
            />
          ))}

          <p className="text-sm text-[#414141] mt-7 mb-[6px]">
            {t("AddPlanting.Brief description")} <LanguagePoint lang={lang} />
          </p>
          <textarea
            onChange={descriptionChange}
            value={lang === "FR" ? description.fr : description.en}
            rows={7}
            className="input-field resize-none text-sm  pl-4 py-[10px] placeholder-[#C7C7C7]"
            placeholder={t("AddPlanting.Brief description") + ""}
          />
          <AddRegisterButton
            update={props?.getInfo}
            uploading={upload}
            isAddPlanting={() => {
              isAddRegister(true);
            }}
          />
        </form>
      )}
      {addCollaboratorName && (
        <AddResponsibleType
          addResponsibleType={addNewResponsibleName}
          closeAddCollaborator={() => isAddCollaboratorName(false)}
          collaboratorType={responsibleType}
        />
      )}
      {locationView && (
        <LocationPage
          lat={getValues("latitude")}
          long={getValues("longitude")}
          isAdd={isAdd}
          closeMap={closeMap}
          latLongChange={latLongChange}
        />
      )}
      {alert && (
        <LocationAlert alert={true} handleAddLocation={handleAddLocation} />
      )}
      {unlockAlert && (
        <UnlockAgeAlert
          alert={true}
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
        />
      )}
      <Warning when={dirty} onCancel={() => false} onOK={() => true} />
    </Fragment>
  );
};

export default AddRegisterForm;
