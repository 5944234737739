import {
  faCircleMinus,
  faCirclePlus,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NumberField: React.FC<{
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  register: any;
  name: string;
  setValue: any;
  getValues: any;
  clearError?: any;
  styleClass?: string;
  readOnl: boolean;
  inputStyle: string;
}> = (props) => {
  return (
    <div className={`relative ${props?.styleClass}`}>
      <button
        type="button"
        onClick={() => {
          if (!props.readOnl) {
            if (props.getValues(props.name) > 0) {
              props.setValue(
                props.name,
                Number(props.getValues(props.name)) - 1
              );
            }
          }
        }}
        className={`number-field-icons top-[27%] left-2 ${
          props.readOnl ? "bg-[#F7F7F7]" : ""
        }`}
      >
        <FontAwesomeIcon icon={faCircleMinus} size="lg" />
      </button>
      <input
        {...props.register}
        type="number"
        readOnly={props.readOnl}
        onChange={props.onChange}
        placeholder={props.placeholder}
        className={
          props.readOnl
            ? `text-sm  h-12 px-10 text-[#FFFFFF] bg-[#A2A1A1] border border-[#eeecec] outline-none rounded-[4px] w-full ${props.inputStyle}`
            : `text-sm px-10 bg-[#f6f6f6] border border-[#eeecec] outline-none focus:bg-white rounded-[4px] w-full focus:border-[#439FC6] ${props.inputStyle}`
        }
      />
      <button
        type="button"
        onClick={() => {
          if (props?.clearError) {
            props?.clearError(props.name);
          }
          if (!props.readOnl) {
            if (props.getValues(props.name) > 0) {
              props.setValue(
                props.name,
                Number(props.getValues(props.name)) + 1
              );
            } else {
              props.setValue(props.name, 1);
            }
          }
        }}
        className={`number-field-icons top-[27%] right-2 ${
          props.readOnl ? "bg-[#F7F7F7]" : ""
        }`}
      >
        <FontAwesomeIcon icon={faCirclePlus} size="lg" />
      </button>
    </div>
  );
};

export default NumberField;
