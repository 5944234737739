import PaginationItem from '@mui/material/PaginationItem';
import Pagination from "@mui/material/Pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft } from '@fortawesome/pro-solid-svg-icons';
import { faAnglesRight } from '@fortawesome/pro-solid-svg-icons';



const TablePagination: React.FC<{ handleChange: (event: React.ChangeEvent<unknown>, value: number) => void; count: number }> = (
  props
) => {
  return (
    <Pagination
      color="primary"
      onChange={props.handleChange}
      count={props.count}
      siblingCount={0}
      showFirstButton
      showLastButton
      shape="rounded"
      renderItem={(item) => (
        <PaginationItem
          components={{
            first: () => <button {...props}><FontAwesomeIcon className="text-[#979797]" icon={faAnglesLeft} /></button>,
            last: () => <button {...props}><FontAwesomeIcon className="text-[#979797]" icon={faAnglesRight} /></button>
          }}
          {...item}
        />
      )}
    />
  );
};

export default TablePagination;
