import { Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import dayjs from 'dayjs';
import { useState } from 'react';
const minDate = dayjs('2000-01-01');
const CustomDate: React.FC<{
	clearError?: any;
	control: any;
	name: any;
	label: string;
	rules?: any;
}> = (props) => {
	
	const [isDateValid, setIsDateValid] = useState(true);

	return (
		<Controller
			control={props.control}
			rules={props?.rules}
			name={props.name}
			render={({ field: { onChange, value } }) => (
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DatePicker
						components={{
							OpenPickerIcon: CalendarMonthOutlinedIcon,
						}}
						inputFormat="dd/MM/yyyy"
						label={value === undefined && props.label}
						onChange={(newValue) => {
							setIsDateValid(dayjs(newValue).isAfter(minDate));
							onChange(newValue);
							if (props?.clearError) {
								props?.clearError(props.name);
							}
						}}
						value={value || null}
						renderInput={(params) => (
							<TextField
								{...params}
								sx={{
									border: `1px solid ${isDateValid ? '#eeecec' : 'red'}`,
									borderRadius: '4px',
									'& fieldset': { border: 'none' },
								}}
								InputLabelProps={{ shrink: false }}
							/>
						)}
						minDate={minDate.toDate()}
					/>
				</LocalizationProvider>
			)}
		/>
	);
};

export default CustomDate;
