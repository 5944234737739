import { SelectChangeEvent } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import TranslationField from "../../../type/translationField";
import LanguagePoint from "../../Global/LanguagePoint";
import SelectLanguage from "../../Global/SelectLanguage";
import Warning from "../../ErrorAndAlert/Warning";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../Register/AddRegister/FormTheme";
import ScientificName from "./ScientificName";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

const AddScientificNameForm: React.FC<{
  send: (
    name: TranslationField,
    sName: { slug: string; scientific_name_transes: TranslationField }[]
  ) => void;
  sendResponse: any;
  getInfo?: any;
  textButton: string;
}> = (props) => {
  const { t } = useTranslation();
  const [lang, setLang] = useState<string>("EN");
  const [name, setName] = useState<TranslationField>({ en: "", fr: "" });
  const [scientificNames, setScientificNames] = useState<
    { slug: string; scientific_name_transes: TranslationField }[]
  >([{ slug: "", scientific_name_transes: { en: "", fr: "" } }]);
  const [loading, setLoading] = useState<boolean>(true);
  const [dirty, isDirty] = useState<boolean>(true);
  const [showError, isShowError] = useState<boolean>(false);
  const history = useHistory();
  const handleLangChange = (event: SelectChangeEvent) => {
    setLang(event.target.value);
  };
  useEffect(() => {
    if (props.sendResponse) {
      toast.success(
        `${
          props.getInfo
            ? `${t("Message.Successful update")}`
            : `${t("Message.Successful add")}`
        }`
      );
      history.replace("/setting/scientific-names");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sendResponse]);

  useEffect(() => {
    if (props.getInfo) {
      setScientificNames([]);
      setName(props.getInfo.data.name_transes);
      setScientificNames(props.getInfo.data.scientific_names);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getInfo]);

  useEffect(() => {
    if (props.getInfo) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getInfo]);

  const nameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (lang === "EN") {
      setName({ en: event.target.value, fr: name?.fr });
    } else if (lang === "FR") {
      setName({ en: name.en, fr: event.target.value });
    }
  };

  const scientificNameChange = (index: number, value: string) => {
    const item = scientificNames.map((sName, i) => {
      if (i === index) {
        if (lang === "EN") {
          return {
            slug: sName.slug,
            scientific_name_transes: {
              en: value,
              fr: sName.scientific_name_transes.fr,
            },
          };
        } else {
          return {
            slug: sName.slug,
            scientific_name_transes: {
              en: sName.scientific_name_transes.en,
              fr: value,
            },
          };
        }
      } else {
        return sName;
      }
    });
    setScientificNames(item);
  };

  const addScientificName = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let error = false;
    scientificNames.forEach((name) => {
      if (!name.scientific_name_transes.en) {
        error = true;
      }
    });
    if (name.en && !error) {
      isDirty(false);
      props.send(name, scientificNames);
    }
  };
  return (
    <Fragment>
      {loading && props.getInfo ? (
        <p className="text-center text-2xl font-medium text-[#414141]">
          {t("Message.Loading")} ...
        </p>
      ) : (
        <form onSubmit={addScientificName}>
          <ThemeProvider theme={theme}>
            <p className="text-sm text-[#414141] mb-[6px]">
              {t("AddPlanting.Language")}
            </p>
            <SelectLanguage handleLangChange={handleLangChange} lang={lang} />
          </ThemeProvider>
          <p className="text-sm text-[#414141] mb-[6px] mt-7">
            {t("Tree Species.Name")} * <LanguagePoint lang={lang} />
          </p>
          <input
            onChange={nameChange}
            value={lang === "FR" ? name.fr : name.en}
            type="text"
            className="input-field pl-4 py-3 text-sm placeholder-[#C7C7C7]"
            placeholder={t("Tree Species.Name") + ""}
          />
          {showError && !name.en && (
            <Fragment>
              <p className="error-text">{t("Message.Required field")}</p>
            </Fragment>
          )}

          <div className="relative">
            {scientificNames.map((name, index: number) => (
              <Fragment key={index}>
                <p className="text-sm text-[#414141] mb-[6px] mt-7">
                  {t("Tree Species.Scientific name")} *{" "}
                  <LanguagePoint lang={lang} />
                </p>
                <ScientificName
                  index={index}
                  sName={
                    lang === "FR"
                      ? name.scientific_name_transes.fr
                      : name.scientific_name_transes.en
                  }
                  key={index}
                  sNameChange={scientificNameChange}
                />
                {showError && !name.scientific_name_transes.en && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </Fragment>
            ))}
            {scientificNames.length > 1 && (
              <button
                onClick={() => {
                  setScientificNames(
                    scientificNames.filter(
                      (a, index: number) => index !== scientificNames.length - 1
                    )
                  );
                }}
                type="button"
                className="text-sm text-[#D2202A] mt-3 font-medium absolute right-0"
              >
                {t("PlantingFilter.Delete")}
              </button>
            )}

            <br />
            <button
              onClick={() => {
                setScientificNames([
                  ...scientificNames,
                  { slug: "", scientific_name_transes: { en: "", fr: "" } },
                ]);
              }}
              type="button"
              className="text-sm text-ph-btn font-semibold"
            >
              <FontAwesomeIcon icon={faPlus} className="mr-1" />
              {t("Scientific name.Add another scientific name")}
            </button>
          </div>
          <button
            onClick={() => {
              isShowError(true);
            }}
            type="submit"
            className="main-btn text-sm  px-6 py-2 mt-5 float-right font-medium"
          >
            {props.textButton}
          </button>
        </form>
      )}
      <Warning when={dirty} onCancel={() => false} onOK={() => true} />
    </Fragment>
  );
};

export default AddScientificNameForm;
