const FileResource: React.FC<{
  address: string;
  id: number;
}> = (props) => {
  const format = props.address.split(".");
  const realFormat = format[format.length - 1];
  return (
    <div className="w-full flex items-center rounded-md relative mb-2">
      {(realFormat.toLowerCase() === "svg" ||
        realFormat.toLowerCase() === "png" ||
        realFormat.toLowerCase() === "jpg" ||
        realFormat.toLowerCase() === "jpeg") && (
        <img alt="img" src={props.address} className="w-[22px] h-[22px]" />
      )}

      {realFormat === "pdf" && (
        <img alt="img" src="/img/file-pdf.svg" className="w-[16px] h-[22px]" />
      )}
      {realFormat === "txt" && (
        <img alt="img" src="/img/file-txt.svg" className="w-[16px] h-[22px]" />
      )}
      {(realFormat === "docx" || realFormat === "doc") && (
        <img alt="img" src="/img/file-doc.svg" className="w-[16px] h-[22px]" />
      )}
      {(realFormat === "xlsx" || realFormat === "xls") && (
        <img
          alt="img"
          src="/img/file-excel.svg"
          className="w-[16px] h-[22px]"
        />
      )}
      <a
        href={props.address}
        target="_blank"
        rel="noreferrer"
        className="text-sm ml-2 h-5 truncate w-20 sm:w-20 md:w-40 lg:w-75 xl:w-65"
      >
        {props.address.split("/")[5]}
      </a>
    </div>
  );
};

export default FileResource;
