import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTree,
  faDiagramProject,
  faBolt,
  faSeedling,
  faUsers,
  faLeaf,
  faLocationPin,
  faHandshake,
} from "@fortawesome/pro-solid-svg-icons";
import { faFlask } from "@fortawesome/pro-regular-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { profileInfo } from "../../store";
import { Fragment } from "react";
import PlantingOrganizerMenu from "./PlantingOrganizerMenu";

export default function SettingMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const profile = useSelector(profileInfo);
  const { t } = useTranslation();
  const location = useLocation();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = createTheme({
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: "#414141",
            fontFamily: "Inter,sans-serif",
            ":hover": {
              backgroundColor: "white",
            },
            "&.menu-item-content:hover": {
              color: "#439FC6",
              backgroundColor: "white",
              "& .icon": {
                color: "#439FC6",
              },
            },
            "&.Mui-selected": {
              color: "#439FC6",
              backgroundColor: "white",
              "& .icon": {
                color: "#439FC6",
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            color: "#414141",
            fontFamily: "Inter,sans-serif",
            padding: "0px",
            fontWeight: "500",
            textTransform: "capitalize",
            fontSize: "14px",
            ":hover": {
              color: "#439FC6",
              backgroundColor: "white",
              "& .icon": {
                color: "#439FC6",
              },
            },
            ":focus": {
              backgroundColor: "white",
            },
            "&[aria-selected=true]": {
              color: "#439FC6",
              "& .icon": {
                color: "#439FC6",
              },
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {(profile.role === "A" || profile.role === "C") && (
        <Fragment>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            aria-selected={location.pathname.includes("/setting")}
          >
            {t("Navbar.Settings")}
          </Button>
          <Menu
            style={{ overflow: "clip" }}
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={handleClose}
              selected={location.pathname.includes("/setting/incidents")}
              className="menu-item-content text-[#414141]"
            >
              <Link
                to="/setting/incidents"
                className="text-sm font-medium py-[2px] hover:text-[#439FC6] w-full inline-block"
              >
                <FontAwesomeIcon
                  icon={faBolt}
                  className="mr-2 text-[#878787] icon"
                />{" "}
                {t("Incidents.Incidents")}
              </Link>
            </MenuItem>

            <MenuItem
              onClick={handleClose}
              selected={location.pathname.includes("/setting/growth-stages")}
              className="menu-item-content text-[#414141]"
            >
              <Link
                to="/setting/growth-stages"
                className="text-sm font-medium py-[2px] hover:text-[#439FC6] w-full inline-block"
              >
                <FontAwesomeIcon
                  icon={faSeedling}
                  className="mr-2 text-[#878787] icon"
                />
                {t("Growth stages.Growth stages")}
              </Link>
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              selected={location.pathname.includes("/setting/provinces")}
              className="menu-item-content text-[#414141]"
            >
              <Link
                to="/setting/provinces"
                className="text-sm py-[2px] font-medium hover:text-[#439FC6] w-full inline-block"
              >
                <FontAwesomeIcon
                  icon={faDiagramProject}
                  className="mr-2 text-[#878787] icon"
                />
                {t("Navbar.Provinces")}
              </Link>
            </MenuItem>
            {profile.role === "A" && (
              <MenuItem
                onClick={handleClose}
                selected={location.pathname.includes("/setting/users")}
                className="menu-item-content text-[#414141]"
              >
                <Link
                  to="/setting/users"
                  className="text-sm py-[2px] font-medium hover:text-[#439FC6] w-full inline-block"
                >
                  <FontAwesomeIcon
                    icon={faUsers}
                    className="mr-2 text-[#878787] icon"
                  />
                  {t("Users.Users")}
                </Link>
              </MenuItem>
            )}
            <MenuItem>
              <PlantingOrganizerMenu onClose={handleClose} />
            </MenuItem>

            <MenuItem
              onClick={handleClose}
              selected={location.pathname.includes("/setting/responsible")}
              className="menu-item-content text-[#414141]"
            >
              <Link
                to="/setting/responsible"
                className="text-sm font-medium py-[4px] hover:text-[#439FC6] w-full inline-block"
              >
                <FontAwesomeIcon
                  icon={faHandshake}
                  className="mr-2 text-[#878787] icon"
                />
                {t("Tree register responsible.Register responsible type")}
              </Link>
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              selected={location.pathname.includes("/setting/scientific-names")}
              className="menu-item-content text-[#414141]"
            >
              <Link
                to="/setting/scientific-names"
                className="text-sm font-medium py-[2px] hover:text-[#439FC6] w-full inline-block"
              >
                <FontAwesomeIcon
                  icon={faFlask}
                  className="mr-2 text-[#878787] icon"
                />{" "}
                {t("Navbar.Scientific names")}
              </Link>
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              selected={location.pathname.includes("/setting/tree-species")}
              className="menu-item-content text-[#414141]"
            >
              <Link
                to="/setting/tree-species"
                className="text-sm font-medium py-[2px] hover:text-[#439FC6] w-full inline-block"
              >
                <FontAwesomeIcon
                  icon={faTree}
                  className="mr-2 text-[#878787] icon"
                />{" "}
                {t("Navbar.Tree species")}
              </Link>
            </MenuItem>

            <MenuItem
              onClick={handleClose}
              selected={location.pathname.includes(
                "/setting/botanical-families"
              )}
              className="menu-item-content text-[#414141]"
            >
              <Link
                to="/setting/botanical-families"
                className="text-sm font-medium py-[2px] hover:text-[#439FC6] w-full inline-block"
              >
                <FontAwesomeIcon
                  icon={faLeaf}
                  className="mr-2 text-[#878787] icon"
                />
                {t("Botanical families.Botanical families")}
              </Link>
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              selected={location.pathname.includes("/setting/tree-origins")}
              className="menu-item-content text-[#414141]"
            >
              <Link
                to="/setting/tree-origins"
                className="text-sm font-medium py-[2px] hover:text-[#439FC6] w-full inline-block"
              >
                <FontAwesomeIcon
                  icon={faLocationPin}
                  className="mr-2 text-[#878787] icon"
                />{" "}
                {t("Tree origins.Nurseries")}
              </Link>
            </MenuItem>
          </Menu>
        </Fragment>
      )}
    </ThemeProvider>
  );
}
