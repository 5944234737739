import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BreadCrumb from "../../Global/BreadCrumb";
import BotanicalFamilyTableContent from "../../BotanicalFamily/BotanicalFamilyList/BotanicalFamilyTableContent";
import SearchField from "../../Global/SearchField";

const GrowthStage = () => {
  const [search, setSearch] = useState<string>("");
  const [sendSearch, setSendSearch] = useState<string>("");
  const { t } = useTranslation();
  const applySearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSendSearch(search);
  };

  return (
    <div className="parent-container">
      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-[#414141] text-[16px] mt-5">
          {t("Growth stages.Growth stages")}
        </h1>
        <Link
          to="/setting/growth-stages/add-growth-stage"
          className="main-btn px-[23px] py-[10px] text-sm"
        >
          {t("Growth stages.Add growth stage")}
        </Link>
      </div>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          { name: t("Growth stages.Growth stages"), url: "" },
        ]}
      />
      <SearchField
        applySearch={applySearch}
        change={(e) => setSearch(e.target.value)}
        searchValue={search}
      />
      <div className="p-4 bg-white mt-3 rounded-md">
        <BotanicalFamilyTableContent
          search={sendSearch}
          link="/api/admin/growth_stages"
          title={t("Growth stages.Growth stage")}
          deleteMessage={t("Message.Invalid growth stage delete")}
          editLink="/setting/growth-stages/edit-growth-stage"
        />
        <br />
      </div>
    </div>
  );
};

export default GrowthStage;
