import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import TranslationField from "../../../type/translationField";
import BreadCrumb from "../../Global/BreadCrumb";
import AddScientificNameForm from "../AddScientificName/AddScientificNameForm";
import Loading from "../../Global/Loading";

const EditScientificName = () => {
  const { id } = useParams<any>();
  const { fetchData: editSName, response: editResponse } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/scientific_names/${id}/edit/`,
    "PATCH",
    false,
    "",
    true,
    true
  );

  const {
    response: getEditResponse,
    fetchData: getName,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/scientific_names/${id}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );

  const { t } = useTranslation();

  useEffect(() => {
    getName({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const addName = (
    name: TranslationField,
    sName: { slug: string; scientific_name_transes: TranslationField }[]
  ) => {
    let newScientificNames: TranslationField[] = [];
    let oldScientificNames: {
      slug: string;
      scientific_name_transes: TranslationField;
    }[] = [];

    sName.forEach((name) => {
      if (name.slug) {
        oldScientificNames.push(name);
      } else {
        newScientificNames.push(name.scientific_name_transes);
      }
    });
    editSName({
      name_transes: name,
      old_scientific_names: oldScientificNames,
      new_scientific_name_transes: newScientificNames,
    });
  };
  return (
    <div className="parent-container">
      <h1 className="font-semibold text-[#414141] text-[16px] mt-5">
        {t("Scientific name.Edit scientific name")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          {
            name: t("Navbar.Scientific names"),
            url: "/setting/scientific-names",
          },
          { name: t("Scientific name.Edit scientific name"), url: "" },
        ]}
      />
      {loading ? (
        <Loading loading={true} />
      ) : (
        <div className="flex flex-wrap justify-between mt-4">
          <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
            <AddScientificNameForm
              send={addName}
              sendResponse={editResponse}
              getInfo={getEditResponse}
              textButton={t("Tree Species.Update")}
            />
          </div>
          <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
            <img
              src="/img/Add scientific name.svg"
              alt="Add scientific name"
              className="mt-4 ml-2 xl:ml-10"
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default EditScientificName;
