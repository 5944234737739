import { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import zxcvbn from "zxcvbn";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import PasswordStrength from "../Forgot Password/PasswordStrength";
import { useForm } from "react-hook-form";
import Warning from "../ErrorAndAlert/Warning";

type password = {
  showText: boolean;
  text: string;
  strength: number;
};
type FormValues = {
  oldPassword: string;
};
const ChangePasswordForm: React.FC<{
  send: (data: any) => void;
}> = (props) => {
  const [password, setPassword] = useState<password>({
    showText: false,
    text: "",
    strength: -1,
  });
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passError, isPassError] = useState<boolean>(false);
  const [confPassError, isConfPassError] = useState<boolean>(false);
  const [disable, isDisable] = useState<boolean>(true);
  const [dirty, isDirty] = useState<boolean>(true);
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>();

  const passwordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let score = 0;
    if (
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
        event.target.value
      )
    ) {
      score = 5;
    } else {
      score = zxcvbn(event.target.value).score;
    }
    setPassword((prevState) => {
      return {
        ...prevState,
        text: event.target.value,
        strength: score,
      };
    });
    if (score === 5 && event.target.value === confirmPassword) {
      isDisable(false);
    } else {
      isDisable(true);
    }
  };

  const confChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
    if (password.strength === 5 && event.target.value === password.text) {
      isDisable(false);
    } else {
      isDisable(true);
    }
  };

  const changePassword = (data: FormValues) => {
    isDirty(false);
    props.send({
      password: data.oldPassword,
      new_password: password.text,
      confirm_password: confirmPassword,
    });
  };
  return (
    <Fragment>
      <form onSubmit={handleSubmit(changePassword)} className="mt-7">
        <p className="text-sm text-[#414141] mb-[6px]">
          {t("Login.Password")} *
        </p>
        <input
          {...register("oldPassword", {
            required: `${t("Message.Required field")}`,
          })}
          type="password"
          className="input-field text-sm pl-4 py-3 w-full sm:w-[48.5%] placeholder-[#C7C7C7]"
          placeholder={t("Login.Password") + ""}
        />
        {errors.oldPassword && (
          <p className="error-text">{t("Message.Required field")}</p>
        )}
        <p className="text-sm text-[#414141] mb-[6px] mt-7">
          {t("Login.New password")} *
        </p>
        <div className="relative">
          <input
            onBlur={() => {
              isPassError(true);
            }}
            onChange={passwordChange}
            value={password?.text}
            type={password.showText ? "text" : "password"}
            placeholder={t("Login.New password") + ""}
            className="input-field text-sm py-3 pl-6 placeholder-[#C7C7C7]"
          />
          <span
            onClick={() => {
              setPassword((prevState) => {
                return {
                  ...prevState,
                  showText: !prevState.showText,
                };
              });
            }}
            className="text-[#979797] absolute right-3 top-3 cursor-pointer"
          >
            <FontAwesomeIcon icon={password.showText ? faEye : faEyeSlash} />
          </span>
        </div>

        {password?.text && (
          <PasswordStrength
            strengthNum={password?.strength}
            password={password.text}
          />
        )}
        <p className="text-sm text-[#414141] mb-[6px] mt-7">
          {t("Users.Password confirmation")} *
        </p>
        <div className="relative">
          <input
            disabled={password.strength !== 5}
            onBlur={() => {
              isConfPassError(true);
            }}
            onChange={confChange}
            value={confirmPassword}
            type="password"
            placeholder={t("Users.Password confirmation") + ""}
            className="input-field text-sm py-3 pl-6 placeholder-[#C7C7C7]"
          />
          {confirmPassword.trim() !== "" &&
            confirmPassword === password.text && (
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="text-ph-btn w-5 h-5 absolute right-3 top-3"
              />
            )}
        </div>
        {passError && password.strength !== 5 && (
          <p className="error-text">{t("Login.Password criteria error")}</p>
        )}
        {confPassError && password.text !== confirmPassword && (
          <p className="error-text">
            {t("Login.The repeated password does not match.")}
          </p>
        )}
        <button
          disabled={disable}
          type="submit"
          className={`${
            disable ? "main-btn-disable" : "main-btn"
          } px-6 mt-9 py-2 text-sm float-right font-medium`}
        >
          {t("Tree Species.Update")}
        </button>
      </form>
      <Warning when={dirty} onCancel={() => false} onOK={() => true} />
    </Fragment>
  );
};

export default ChangePasswordForm;
