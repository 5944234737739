import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEnvelope,
	faArrowRightFromBracket,
	faUser,
	faLock,
} from '@fortawesome/pro-solid-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { LoginSliceActions } from '../../store/login-slice';
import { Link, useHistory } from 'react-router-dom';
import { profileInfo, refreshToken } from '../../store';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import SignOutAlert from '../ErrorAndAlert/SignOutAlert';
import { useState } from 'react';

const UserProfile: React.FC<{ hideUserProfile: () => void }> = (props) => {
	const {hideUserProfile} = props;
	const { t } = useTranslation();
	const refresh = useSelector(refreshToken);
	const profile = useSelector(profileInfo);
	const dispatch = useDispatch();
	const history = useHistory();
	const [showSignOut, isShowSignOut] = useState<boolean>(false);

	const APICall = () => {
		axios
			.post(process.env.REACT_APP_API_URL + '/api/token/blacklist/', {
				refresh: refresh,
			})
			.then(function (response: any) {
				dispatch(LoginSliceActions.userLogOut());
				history.replace('/');
				history.block(() => {});
				isShowSignOut(false)
				hideUserProfile();
			})
			.catch(function (error) {
				console.log('An error occurred during sign out:', error);
			});
	};
	const signOut = () => {
		isShowSignOut(!showSignOut);
	};

	const hide = () => {
		isShowSignOut(false);
	};

	const hideProfile = () => {
		hideUserProfile();
	};

	return (
		<div className="w-full h-full overflow-hidden bg-ph-gray bg-opacity-70 flex fixed top-0 justify-end z-10">
			<div
				onClick={hideUserProfile}
				className="w-0 md:w-[50%] lg:w-[72%] h-full "
			></div>
			<div className="w-[381px] overflow-y-scroll h-full p-7 z-10 bg-white">
				<div className="flex justify-between items-center">
					<h1 className="font-semibold text-[#414141]">
						{t('Navbar.User profile')}
					</h1>
					<button onClick={hideUserProfile}>
						<FontAwesomeIcon className="text-ph-grayD w-4" icon={faXmark} />
					</button>
				</div>
				<div className="flex items-center mt-8 border-b-2 border-ph-pborder pb-8">
					{profile.profile_picture ? (
						<img
							className="rounded-md w-[83px] h-[83px] overflow-hidden object-cover"
							alt="profile"
							src={profile.profile_picture}
						/>
					) : (
						<div className="flex items-center justify-center rounded-md w-[83px] h-[83px] overflow-hidden object-cover bg-[#f6f6f6]">
							<FontAwesomeIcon
								className="text-[#9D9A9ABD] text-4xl"
								icon={faUser}
							/>
						</div>
					)}

					<div className="ml-5">
						<p className="font-medium">{profile.first_name}</p>
						<p className="mt-1 text-sm">
							<FontAwesomeIcon className="text-ph-btn mt-2" icon={faEnvelope} />{' '}
							<span>{profile.email}</span>
						</p>
					</div>
				</div>
				<Link
					to={'/user/edit-profile/'}
					onClick={hideProfile}
					className="flex items-center mt-5 text-[#414141] hover:text-ph-btn cursor-pointer"
				>
					<div className="bg-ph-bgIcon w-9 h-9 text-center rounded-md">
						<FontAwesomeIcon
							icon={faUser}
							className="text-ph-btn w-[14px] h-4 mt-[10px]"
						/>
					</div>
					<p className="font-semibold cursor-pointer text-sm ml-3">
						{t('Navbar.My profile')}
					</p>
				</Link>
				<div
					onClick={() => {
						history.replace('/change-password');
						hideUserProfile();
					}}
					className="flex items-center text-[#414141] mt-5 hover:text-ph-btn cursor-pointer"
				>
					<div className="bg-ph-bgIcon  w-9 h-9 text-center rounded-md">
						<FontAwesomeIcon
							icon={faLock}
							className="text-ph-btn w-[14px] h-4 mt-[10px]"
						/>
					</div>
					<p className="font-semibold cursor-pointer text-sm  ml-3">
						{t('Change password.Change password')}
					</p>
				</div>
				<div
					onClick={signOut}
					className="flex items-center mt-5 text-[#414141]  cursor-pointer"
				>
					<div className="bg-ph-bgIcon w-9 h-9  text-center rounded-md">
						<FontAwesomeIcon
							icon={faArrowRightFromBracket}
							className="text-ph-btn w-[14px] h-4 mt-[10px]"
						/>
					</div>
					<p className="font-semibold cursor-pointer text-sm ml-3 hover:text-ph-btn">
						{t('Navbar.Sign out')}
					</p>
					{showSignOut && (
						<SignOutAlert
							hide={hide}
							signOutAction={APICall}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default UserProfile;
