import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/pro-light-svg-icons";

const FileAccepted: React.FC<{
  fileIndex: number;
  fileName: string;
  fileSize: number;
  fileUrl: string;
  fileType: string;
  removeAction: (fileIndex: number) => void;
}> = (props) => {
  const { t } = useTranslation();

  const removeFile = () => {
    props.removeAction(props.fileIndex);
  };
  return (
    <div className="w-full flex items-center rounded-md bg-[#FCFCFC] border border-[#EEECEC] relative mb-2">
      {props.fileType.includes("image") && (
        <img alt="img" src={props.fileUrl} className="w-[60px] h-[60px]" />
      )}
      {props.fileType.includes("pdf") && (
        <img alt="img" src="/img/file-pdf.svg" className="w-[60px] h-[60px]" />
      )}
      {props.fileType.includes("wordprocessingml") && (
        <img alt="img" src="/img/file-doc.svg" className="w-[60px] h-[60px]" />
      )}
      {(props.fileType.includes("spreadsheetml") ||
        props.fileType.includes("text/csv")) && (
        <img
          alt="img"
          src="/img/file-excel.svg"
          className="w-[60px] h-[60px]"
        />
      )}
      {props.fileType.includes("text/plain") && (
        <img alt="img" src="/img/file-txt.svg" className="w-[60px] h-[60px]" />
      )}

      <div className="ml-3">
        <p className="text-sm font-medium h-5 w-[220px] whitespace-nowrap overflow-hidden text-ellipsis">
          {props.fileName}
        </p>
        <p className="text-xs">{props.fileSize / 1000} kB</p>
      </div>
      <p
        onClick={removeFile}
        className="absolute right-3 text-[#414141] text-sm hover:text-[#D2202A] cursor-pointer"
      >
        <FontAwesomeIcon icon={faTrashCan} className="mr-2" />
        {t("PlantingFilter.Delete")}
      </p>
    </div>
  );
};

export default FileAccepted;
