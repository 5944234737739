import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Warning from "../../ErrorAndAlert/Warning";
import Status from "../../Global/Status";

type FormValues = {
  name: string;
};

const AddPlantingOrganizerForm: React.FC<{
  send: (data: any) => void;
  sendResponse: any;
  getInfo?: any;
  textButton: string;
  type: string;
}> = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [dirty, isDirty] = useState<boolean>(true);
  const [active, isActive] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    if (props.sendResponse) {
      toast.success(
        `${
          props.getInfo
            ? `${t("Message.Successful update")}`
            : `${t("Message.Successful add")}`
        }`
      );
      history.replace(`/setting/organizers/${props.type}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sendResponse]);

  useEffect(() => {
    if (props.getInfo) {
      setLoading(true);
      setValue("name", props.getInfo.data.name);
      isActive(props.getInfo.data.is_active);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getInfo]);

  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm<FormValues>();

  const addCollaborator = (data: FormValues) => {
    isDirty(false);
    props.send({
      type: props.type,
      name: data.name,
      is_active: active,
    });
  };
  return (
    <Fragment>
      {loading && props.getInfo ? (
        <p className="text-center text-2xl font-bold">
          {t("Message.Loading")} ...
        </p>
      ) : (
        <form onSubmit={handleSubmit(addCollaborator)}>
          <p className="text-[#414141] text-sm mb-[6px]">
            {t("Tree Species.Name")} *
          </p>
          <input
            {...register("name", {
              required: `${t("Message.Required field")}`,
            })}
            type="text"
            className="input-field pl-4 py-3 text-sm placeholder-[#C7C7C7]"
            placeholder={t("Tree Species.Name") + ""}
          />
          {errors.name && <p className="error-text">{errors.name.message}</p>}

          <Status
            active={active}
            isActive={(value) => {
              isActive(value);
            }}
          />

          <button
            type="submit"
            className="main-btn text-sm  px-6 py-2 mt-5 float-right font-medium"
          >
            {props.textButton}
          </button>
        </form>
      )}
      <Warning when={dirty} onCancel={() => false} onOK={() => true} />
    </Fragment>
  );
};

export default AddPlantingOrganizerForm;
