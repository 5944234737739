import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

const AppliedItem: React.FC<{
  type: string;
  value: string;
  removeFilter: () => void;
}> = (props) => {
  const removerFilter = () => {
   props.removeFilter();
  };
  return (
    <div className="applied-filter-item">
      <p className="text-xs text-[#414141]">
        {props.type}: <span className="font-medium">{props.value}</span> 
      </p>
      <span className="relative text-sm pl-2" onClick={removerFilter}>
        <FontAwesomeIcon icon={faXmark} />
      </span>
    </div>
  );
};

export default AppliedItem;
