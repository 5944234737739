import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const Status: React.FC<{
  active: boolean;
  isActive: (value: boolean) => void;
}> = (props) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <p className="text-sm text-[#414141] mb-[6px] mt-7">
        {t("Workshops.Workshop status")}
      </p>
      <div className="rounded-xl w-fit bg-[#f6f6f6] text-[#C7C7C7] text-sm border border-[#eeecec]">
        <button
          type="button"
          onClick={() => {
            props.isActive(false);
          }}
          className={`${
            !props.active &&
            "bg-white text-[#439FC6] shadow-[0_0_2px_rgba(0,0,0,0.16)]"
          } rounded-xl px-2 py-1`}
        >
          {t("Province.Inactive")}
        </button>
        <button
          type="button"
          onClick={() => {
            props.isActive(true);
          }}
          className={`${
            props.active &&
            "bg-[#439FC6] text-white shadow-[0_0_2px_rgba(0,0,0,0.16)]"
          } px-2 rounded-xl py-1`}
        >
          {t("Province.Active")}
        </button>
      </div>
    </Fragment>
  );
};

export default Status;
