import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

export default function Tools() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const location = useLocation();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = createTheme({
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: "#414141",
            fontFamily: "Inter,sans-serif",
            ":hover": {
              backgroundColor: "white",
            },
            "&.menu-item-content:hover": {
              color: "#439FC6",
              backgroundColor: "white",
              "& .icon": {
                color: "#439FC6",
              },
            },
            "&.Mui-selected": {
              color: "#439FC6",
              backgroundColor: "white",
              "& .icon": {
                color: "#439FC6",
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            color: "#414141",
            fontFamily: "Inter,sans-serif",
            padding: "0px",
            marginLeft: "-12px",
            fontWeight: "500",
            textTransform: "capitalize",
            fontSize: "14px",
            ":hover": {
              color: "#439FC6",
              backgroundColor: "white",
              "& .icon": {
                color: "#439FC6",
              },
            },
            ":focus": {
              backgroundColor: "white",
            },
            "&[aria-selected=true]": {
              color: "#439FC6",
              "& .icon": {
                color: "#439FC6",
              },
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        aria-selected={location.pathname.includes("/tools")}
        onClick={handleClick}
      >
        {t("Import register.Tools")}{" "}
      </Button>
      <Menu
        style={{ overflow: "clip" }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          selected={location.pathname.includes("/tools/import-register")}
          onClick={handleClose}
        >
          <Link
            to="/tools/import-register/all"
            className="text-sm py-[2px] font-normal hover:text-[#439FC6] first-line:w-full mt-1 inline-block"
          >
            <FontAwesomeIcon icon={faDownload} className="mr-2 icon" />{" "}
            {t("Import register.Imported registers")}
          </Link>
        </MenuItem>
      </Menu>
    </ThemeProvider>
  );
}
