import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const SearchField: React.FC<{
	searchValue: string;
	change: (event: React.ChangeEvent<HTMLInputElement>) => void;
	applySearch: (e: React.FormEvent<HTMLFormElement>) => void;
}> = (props) => {
	const { t } = useTranslation();

	const handleIconClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
		e.preventDefault();
		props.applySearch(e as unknown as React.FormEvent<HTMLFormElement>);
	};
	return (
		<form onSubmit={props.applySearch} className="relative">
			<input
				type="text"
				onChange={(e) => {
					props.change(e);
				}}
				value={props.searchValue}
				placeholder={t('Navbar.Search') + ''}
				className="py-2 rounded-md mt-2 border border-white w-full focus:outline-none focus:border focus:border-[#439FC6] text-sm  pl-10"
			/>

			<FontAwesomeIcon
				className="text-gray-400 absolute left-3 top-[18px] hover:text-gray-700 hover:cursor-pointer"
				icon={faMagnifyingGlass}
				onClick={handleIconClick}
			/>
		</form>
	);
};

export default SearchField;
