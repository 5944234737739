import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import OldFile from "../../Global/OldFile";
import EditSlider from "../../Global/EditSlider";
import TranslationField from "../../../type/translationField";
import Loading from "../../Global/Loading";

type FormValues = {
  slug: string;
  name_transes: TranslationField;
  department_name: string;
  municipality: string;
  community: string;
  date_from: string;
  date_to: string;
  time_from: string;
  time_to: string;
  psa_students: number;
  school_students: number;
  psa_tutors: number;
  school_tutors: number;
  farmers: number;
  promoters: number;
  junior_youth_parents: number;
  community_leaders: number;
  other_participants: number;
  description_transes: TranslationField;
  files: {
    id: number;
    file_address: string;
    thumbnail_address: string;
  }[];
};

const ViewWorkshop = () => {
  const [workshop, setWorkshop] = useState<FormValues>({
    slug: "",
    name_transes: { en: "", fr: "" },
    department_name: "",
    municipality: "",
    community: "",
    date_from: "",
    date_to: "",
    time_from: "",
    time_to: "",
    psa_students: 0,
    school_students: 0,
    psa_tutors: 0,
    school_tutors: 0,
    farmers: 0,
    promoters: 0,
    junior_youth_parents: 0,
    community_leaders: 0,
    other_participants: 0,
    description_transes: { en: "", fr: "" },
    files: [
      {
        id: 0,
        file_address: "",
        thumbnail_address: "",
      },
    ],
  });

  const { i18n, t } = useTranslation();
  const { id } = useParams<any>();

  const {
    response: getEditResponse,
    fetchData: getWorkshop,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/workshop/${id}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    if (getEditResponse) {
      setWorkshop(getEditResponse.data);
    }
  }, [getEditResponse]);

  useEffect(() => {
    getWorkshop({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-[#414141] text-[16px] mt-5">
        {t("Workshops.View workshop")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          {
            name: t("Workshops.Workshops"),
            url: "/workshops",
          },
          { name: t("Workshops.View workshop"), url: "" },
        ]}
      />
      {loading ? (
        <Loading loading={true} />
      ) : (
        <div className="flex flex-wrap justify-between mt-4">
          <div className="w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
            <div className="bg-[#F7F7F7] rounded-md p-5">
              <div className="flex">
                <p className="text-sm text-[#414141] w-40 py-3">
                  {t("Tree Species.Name")}
                </p>
                <p className="text-sm text-[#414141] font-medium max-w-[350px] break-words  pl-4 py-3 ">
                  {i18n.language === "fr"
                    ? workshop.name_transes.fr || workshop.name_transes.en
                    : workshop.name_transes.en}
                </p>
              </div>
              <div className="flex">
                <p className="text-sm text-[#414141] w-40 py-3">
                  {t("PlantingFilter.Province")}{" "}
                </p>
                <p className="text-sm text-[#414141] font-medium pl-4 py-3">
                  {workshop.department_name}
                </p>
              </div>
              <div className="flex">
                <p className="text-sm text-[#414141] w-40 py-3">
                  {t("Plantings.District")}
                </p>
                <p className="text-sm text-[#414141] font-medium pl-4 py-3">
                  {workshop.municipality}
                </p>
              </div>
              <div className="flex">
                <p className="text-sm text-[#414141] w-40 py-3">
                  {t("Plantings.Community")}
                </p>
                <p className="text-sm text-[#414141] font-medium pl-4 py-3">
                  {workshop.community}
                </p>
              </div>
            </div>
            <div className="flex bg-[#F7F7F7] rounded-md p-5 mt-3">
              <div>
                <p className="text-sm py-3 text-[#414141] ">
                  {t("Workshops.Start date")}
                </p>
                <p className="text-sm py-3 text-[#414141]">
                  {t("Workshops.End date")}
                </p>
                <p className="text-sm py-3 text-[#414141]">
                  {t("Workshops.Start time")}
                </p>
                <p className="text-sm py-3 text-[#414141]">
                  {t("Workshops.End time")}
                </p>
              </div>
              <div className="pl-28">
                <p className="text-sm py-3 font-medium text-[#414141]">
                  {workshop.date_from}
                </p>
                <p className="text-sm py-3 font-medium text-[#414141]">
                  {workshop.date_to}
                </p>
                <p className="text-sm py-3 font-medium text-[#414141]">
                  {workshop.time_from || "-"}
                </p>
                <p className="text-sm py-3 font-medium text-[#414141]">
                  {workshop.time_to || "-"}
                </p>
              </div>
            </div>
            <div className="flex bg-[#F7F7F7] rounded-md p-5 mt-3">
              <div>
                <p className="text-sm font-semibold text-[#414141] mb-4">
                  {t("PlantingFilter.Participants")}
                </p>
                <p className="text-sm py-3 text-[#414141] ">
                  {t("Workshops.PSA students")}
                </p>
                <p className="text-sm py-3 text-[#414141]">
                  {t("Workshops.School students")}
                </p>
                <p className="text-sm py-3 text-[#414141]">
                  {t("Workshops.PSA tutors")}
                </p>
                <p className="text-sm py-3 text-[#414141]">
                  {t("Workshops.School teachers")}
                </p>
                <p className="text-sm py-3 text-[#414141]">
                  {t("Workshops.Farmers")}
                </p>
                <p className="text-sm py-3 text-[#414141]">
                  {t("Workshops.Promoters")}
                </p>
                <p className="text-sm py-3 text-[#414141]">
                  {t("Workshops.Junior youth parents")}
                </p>
                <p className="text-sm py-3 text-[#414141]">
                  {t("Workshops.Community leaders")}
                </p>
                <p className="text-sm py-3 text-[#414141]">
                  {t("Workshops.Other participants")}
                </p>
              </div>
              <div className="pl-14">
                <p className="text-sm py-3 mt-9 font-medium text-[#414141]">
                  {workshop.psa_students}
                </p>
                <p className="text-sm py-3 font-medium text-[#414141]">
                  {workshop.school_students}
                </p>
                <p className="text-sm py-3 font-medium text-[#414141]">
                  {workshop.psa_tutors}
                </p>
                <p className="text-sm py-3 font-medium text-[#414141]">
                  {workshop.school_tutors}
                </p>
                <p className="text-sm py-3">{workshop.farmers}</p>
                <p className="text-sm py-3">{workshop.promoters}</p>
                <p className="text-sm py-3">{workshop.junior_youth_parents}</p>
                <p className="text-sm py-3">{workshop.community_leaders}</p>
                <p className="text-sm py-3 font-medium text-[#414141]">
                  {workshop.other_participants}
                </p>
              </div>
            </div>
            <div className="flex bg-[#F7F7F7] rounded-md p-5 mt-3">
              <div>
                <p className="text-sm text-[#414141]">
                  {t("Tree Species.Description")}
                </p>
              </div>
              <div className="pl-5">
                <p className="text-sm text-[#414141] max-w-[400px] break-words ">
                  {i18n.language === "fr"
                    ? workshop.description_transes.fr ||
                      workshop.description_transes.en ||
                      "-"
                    : workshop.description_transes.en || "-"}
                </p>
              </div>
            </div>
            <div className="bg-[#F7F7F7] rounded-md p-5 mt-3">
              <div>
                <p className="text-sm font-semibold text-[#414141] mb-4">
                  {t("Workshops.Images")}
                </p>
                {workshop.files.map((file: any) => (
                  <OldFile
                    address={file.file_address}
                    id={file.id}
                    delete={false}
                    removeOldFile={() => {}}
                    key={file.id}
                  />
                ))}
              </div>
            </div>
            <Link
              to="/workshops"
              className="main-btn text-sm  px-6 py-2 mt-5 float-right font-medium"
            >
              {t("Workshops.Back to workshops")}
            </Link>
          </div>
          <EditSlider
            images={["/img/Add Workshop1.svg"]}
            response={getEditResponse}
          />
        </div>
      )}
    </div>
  );
};

export default ViewWorkshop;
