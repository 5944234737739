import { configureStore } from "@reduxjs/toolkit";
import departmentFilterSlice from "./departmentFilter-slice";
import loginSlice from "./login-slice";
import mapFilterSlice from "./mapFilter-slice";
import selectFieldsSlice from "./selectFields-slice";
import speciesFilterSlice from "./speciesFilter-slice";
import workshopFilterSlice from "./workshopFilter-slice";
import registerFilterSlice from "./registerFilter-slice";
import plantingFilterSlice from "./plantingFilter-slice";
import userFilterSlice from "./userFilter-slice";
import responsibleFilterSlice from "./responsibleFilter-slice";
import plantingOrganizerFilterSlice from "./plantingOrganizerFilter-slice";

const store = configureStore({
  reducer: {
    login: loginSlice.reducer,
    selectField: selectFieldsSlice.reducer,
    registerFilter: registerFilterSlice.reducer,
    speciesFilter: speciesFilterSlice.reducer,
    mapFilter: mapFilterSlice.reducer,
    workshopFilter: workshopFilterSlice.reducer,
    plantingFilter: plantingFilterSlice.reducer,
    departmentFilter: departmentFilterSlice.reducer,
    plantingOrganizerFilter: plantingOrganizerFilterSlice.reducer,
    userFilter: userFilterSlice.reducer,
    responsibleFilter: responsibleFilterSlice.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;
export const login = (state: RootState) => state.login.isLogin;
export const accessToken = (state: RootState) => state.login.token;
export const refreshToken = (state: RootState) => state.login.refresh;
export const profileInfo = (state: RootState) => state.login.profile;
export const collaboratorNames = (state: RootState) =>
  state.selectField.collaboratorNames;
export const department = (state: RootState) => state.selectField.department;
export const speciesList = (state: RootState) => state.selectField.speciesList;
export const registerURL = (state: RootState) => state.registerFilter.url;
export const registerFilterSet = (state: RootState) =>
  state.registerFilter.filterSet;
export const registerFilters = (state: RootState) =>
  state.registerFilter.filterItem;
export const registerFilterDelete = (state: RootState) =>
  state.registerFilter.deleteFilter;
export const mapURL = (state: RootState) => state.mapFilter.url;
export const mapFilterSet = (state: RootState) => state.mapFilter.filterSet;
export const mapFilters = (state: RootState) => state.mapFilter.filterItem;
export const mapFilterDelete = (state: RootState) =>
  state.mapFilter.deleteFilter;
export const speciesURL = (state: RootState) => state.speciesFilter.url;
export const speciesFilterSet = (state: RootState) =>
  state.speciesFilter.filterSet;
export const speciesFilters = (state: RootState) =>
  state.speciesFilter.filterItem;
export const speciesFilterDelete = (state: RootState) =>
  state.speciesFilter.deleteFilter;
export const workshopURL = (state: RootState) => state.workshopFilter.url;
export const workshopFilterSet = (state: RootState) =>
  state.workshopFilter.filterSet;
export const workshopFilters = (state: RootState) =>
  state.workshopFilter.filterItem;
export const workshopFilterDelete = (state: RootState) =>
  state.workshopFilter.deleteFilter;
export const plantingURL = (state: RootState) => state.plantingFilter.url;
export const plantingFilterSet = (state: RootState) =>
  state.plantingFilter.filterSet;
export const plantingFilters = (state: RootState) =>
  state.plantingFilter.filterItem;
export const plantingFilterDelete = (state: RootState) =>
  state.plantingFilter.deleteFilter;
export const departmentURL = (state: RootState) => state.departmentFilter.url;
export const departmentFilterSet = (state: RootState) =>
  state.departmentFilter.filterSet;
export const departmentFilters = (state: RootState) =>
  state.departmentFilter.filterItem;
export const plantingOrganizerURL = (state: RootState) =>
  state.plantingOrganizerFilter.url;
export const plantingOrganizerFilterSet = (state: RootState) =>
  state.plantingOrganizerFilter.filterSet;
export const plantingOrganizerFilters = (state: RootState) =>
  state.plantingOrganizerFilter.filterItem;
export const userURL = (state: RootState) => state.userFilter.url;
export const userFilterSet = (state: RootState) => state.userFilter.filterSet;
export const userFilters = (state: RootState) => state.userFilter.filterItem;
export const userFilterDelete = (state: RootState) =>
  state.userFilter.deleteFilter;
export const responsibleURL = (state: RootState) => state.responsibleFilter.url;
export const responsibleFilterSet = (state: RootState) =>
  state.responsibleFilter.filterSet;
export const responsibleFilters = (state: RootState) =>
  state.responsibleFilter.filterItem;
export const responsibleFilterDelete = (state: RootState) =>
  state.responsibleFilter.deleteFilter;

export default store;
