import { Fragment, useState } from "react";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  autocompleteClasses,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Department } from "../../../type/department";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import AddOrganizerName from "./AddOrganizerName";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon sx={{ color: "#066993" }} fontSize="small" />;

const Organizer: React.FC<{
  selectOptions: {
    department: Department[];
    grp: Department[];
    ins: Department[];
    ind: Department[];
    fml: Department[];
  };
  organizer: { type: string; collaborators: Department[] };
  index: number;
  addOrganizer: (type: string, value: Department) => void;
  typeChange: (index: number, value: string) => void;
  nameChange: (index: number, value: Department[]) => void;
  indSelect: (value: boolean) => void;
  isIndSelect: boolean;
  showError: boolean;
}> = (props) => {
  const [addCollaboratorName, isAddCollaboratorName] = useState<boolean>(false);
  const { t } = useTranslation();
  const indData = props.selectOptions.ind;
  const insData = props.selectOptions.ins;
  const grpData = props.selectOptions.grp;
  const fmlData = props.selectOptions.fml;
  const addNewOrganizerName = (type: string, value: Department) => {
    props.addOrganizer(type, value);
    let name: Department[] = [];
    if (type === "ind") {
      name = [...props.organizer.collaborators, value];
    } else {
      name = [value];
    }
    props.nameChange(props.index, name);
  };

  return (
    <div className="flex flex-wrap justify-between mt-5">
      <div className="w-full sm:w-1/2 md:pr-2">
        <p className="text-sm text-[#414141] mb-[6px]">
          {t("Tree Species.Type")} *
        </p>
        <FormControl style={{ width: "100%" }}>
          <InputLabel shrink={false}>
            {props.organizer.type === "" && t("Tree Species.Type")}
          </InputLabel>
          <Select
            sx={{
              border: "1px solid #eeecec",
              borderRadius: "4px",
              "& fieldset": { border: "none" },
            }}
            style={{ height: "48px" }}
            IconComponent={ExpandMoreIcon}
            variant="outlined"
            value={props.organizer.type}
            onChange={(e) => {
              if (!props.isIndSelect || e.target.value !== "ind") {
                props.typeChange(props.index, e.target.value);
              }
              if (e.target.value === "ind") {
                props.indSelect(true);
              }
              if (props.organizer.type === "ind" && e.target.value !== "ind") {
                props.indSelect(false);
              }
            }}
          >
            <MenuItem value="fml">{t("Register.Family")}</MenuItem>
            <MenuItem value="grp">{t("Register.Group")}</MenuItem>
            <MenuItem value="ind">{t("Register.Individual")}</MenuItem>
            <MenuItem value="ins">{t("Register.Institution")}</MenuItem>
          </Select>
        </FormControl>
        {props.showError && !props.organizer.type && (
          <p className="error-text">{t("Message.Required field")}</p>
        )}
      </div>
      <div className="w-full sm:w-1/2 sm:pl-2 mt-7 sm:mt-0">
        <p className="text-sm text-[#414141] mb-[6px]">
          {t("Tree Species.Name")} *
        </p>
        {props.organizer.type === "ind" && (
          <Autocomplete
            popupIcon={
              <FontAwesomeIcon className="w-4 h-4" icon={faMagnifyingGlass} />
            }
            // @ts-ignore
            multiple={true}
            limitTags={1}
            id="checkboxes-tags-demo"
            options={indData}
            disableCloseOnSelect
            getOptionLabel={(option: any) => option.name}
            renderOption={(prop, option: any, { selected }) => (
              <Fragment>
                {option.slug === indData[0].slug && (
                  <div className="px-7 border-b border-ph-iborder">
                    <button
                      onClick={() => isAddCollaboratorName(true)}
                      className="text-sm w-full items-center flex justify-between text-[#066993] py-2 "
                    >
                      {t("Plantings.Create organizer")}
                      <FontAwesomeIcon className="ml-2" icon={faPlus} />
                    </button>
                  </div>
                )}
                <li {...prop} key={option.slug}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              </Fragment>
            )}
            sx={{
              width: "100%",
              [`& .${autocompleteClasses.popupIndicator}`]: {
                transform: "none",
              },
              "& .Mui-focused": {
                outline: "1px solid #439FC6",
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  border: "1px solid #eeecec",
                  borderRadius: "4px",
                  "& fieldset": { border: "none" },
                }}
                placeholder={
                  props.organizer.collaborators?.length === 0
                    ? t("Tree Species.Name") + ""
                    : ""
                }
              />
            )}
            // @ts-ignore
            onChange={(event, newValue) => {
              props.nameChange(props.index, newValue);
            }}
            value={props.organizer.collaborators}
          />
        )}
        {props.organizer.type !== "ind" && (
          <Autocomplete
            popupIcon={
              <FontAwesomeIcon className="w-4 h-4" icon={faMagnifyingGlass} />
            }
            id="combo-box-demo"
            disabled={props.organizer.type === "" ? true : false}
            // @ts-ignore
            options={
              props.organizer.type === "grp"
                ? grpData
                : props.organizer.type === "ins"
                ? insData
                : fmlData
            }
            getOptionLabel={(option: any) => option.name}
            sx={{
              width: "100%",
              [`& .${autocompleteClasses.popupIndicator}`]: {
                transform: "none",
              },
              "& .Mui-focused": {
                outline: "1px solid #439FC6",
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                }}
                sx={{
                  border: "1px solid #eeecec",
                  borderRadius: "4px",
                  "& fieldset": { border: "none" },
                }}
                placeholder={t("Tree Species.Name") + ""}
              />
            )}
            // @ts-ignore
            onChange={(event, newValue) => {
              props.nameChange(props.index, [newValue as Department]);
            }}
            renderOption={(prop, option: any, { selected }) => (
              <Fragment>
                {(option.slug === grpData[0].slug ||
                  option.slug === insData[0].slug ||
                  option.slug === fmlData[0].slug) && (
                  <div className="px-4 border-b border-ph-iborder">
                    <button
                      onClick={() => isAddCollaboratorName(true)}
                      className="text-sm w-full items-center flex justify-between text-[#066993] py-2 "
                    >
                      {t("Plantings.Create organizer")}
                      <FontAwesomeIcon className="ml-2" icon={faPlus} />
                    </button>
                  </div>
                )}
                <li {...prop} key={option.slug}>
                  {option.name}
                </li>
              </Fragment>
            )}
            value={props.organizer?.collaborators[0] || null}
          />
        )}
        {props.showError && !props.organizer.collaborators[0] && (
          <p className="error-text">{t("Message.Required field")}</p>
        )}
      </div>
      {addCollaboratorName && (
        <AddOrganizerName
          addOrganizer={addNewOrganizerName}
          closeAddOrganizer={() => {
            isAddCollaboratorName(false);
          }}
          organizerType={props.organizer.type}
        />
      )}
    </div>
  );
};

export default Organizer;
