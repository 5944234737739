import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../hook/useAxios";
import file from "../../type/file";
import { toast } from "react-toastify";
import DragDropField from "../Global/DragDropField";

const AddImportRegister: React.FC<{
  closePopOver: () => void;
  fetchImportData: () => void;
  slug: any;
}> = (props) => {
  const [csvFile, setCsvFile] = useState<file[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [mandatoryError, isMandatoryError] = useState<boolean>(false);
  const { t } = useTranslation();
  const { fetchData, response, error } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/registers/${props.slug}/import/`,
    "POST",
    false,
    `${t("Message.Import process initialization message")}`,
    true,
    true
  );
  useEffect(() => {
    if (response) {
      props.fetchImportData();
      setLoading(false);
      props.closePopOver();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  const insertFile = (newFile: file[]) => {
    if (!newFile[0].type.includes("text/csv")) {
      toast.error(`${t("Message.The supported formats is CSV")}`);
    } else if (newFile[0].size > 3000000) {
      toast.error(`${t("Message.Maximum file size is 3 MB")}`);
    } else {
      setCsvFile([newFile[0]]);
      isMandatoryError(false);
    }
  };

  const removeFile = (index: number) => {
    setCsvFile([]);
  };

  const importFile = () => {
    if (csvFile.length !== 0) {
      const formData = new FormData();
      formData.append("file_address", csvFile[0]?.data);
      setLoading(true);
      fetchData(formData);
    } else {
      isMandatoryError(true);
    }
  };

  return (
    <div className="w-full h-full z-[9990] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0">
      <div className="bg-white w-full h-fit p-8 sm:w-[731px] rounded-md shadow-md">
        <h2 className="font-semibold mb-6 text-[#414141]">
          {t("Import register.Import registers")}
        </h2>
        <div className="bg-[#F7F7F7] h-[250px] overflow-y-scroll scrollbar-hide p-2 rounded-md text-sm  text-[#414141]">
          <h3 className="font-medium">
            {t("Import register.Import CSV file rules")}
          </h3>
          <ol className="list-decimal ml-5">
            <li className="mt-[10px]">{t("Import register.1")}</li>
            <li className="mt-[10px]">{t("Import register.12")}</li>
            <li className="mt-[10px]">{t("Import register.2")}</li>
            <li className="mt-[10px]">{t("Import register.3")}</li>
            <li className="mt-[10px]">{t("Import register.4")}</li>
            <li className="mt-[10px]">{t("Import register.5")}</li>
            <li className="mt-[10px]">{t("Import register.6")}</li>
            <li className="mt-[10px]">{t("Import register.7")}</li>
            <li className="mt-[10px]">{t("Import register.8")}</li>
            <li className="mt-[10px]">{t("Import register.9")}</li>
            <li className="mt-[10px]">{t("Import register.10")}</li>
            <li className="mt-[10px]">{t("Import register.11")}</li>
            <li className="mt-[10px]">{t("Import register.13")}</li>
            <li className="mt-[10px]">{t("Import register.14")}</li>
            <li className="mt-[10px]">{t("Import register.15")}</li>
            <li className="mt-[10px]">{t("Import register.16")}</li>
          </ol>
        </div>
        <p className="text-sm text-[#414141] mt-7 mb-[6px]">
          {t("Import register.Registers CSV file")}
        </p>
        <DragDropField
          files={csvFile}
          format="csv (max 3 MB)"
          insertFile={insertFile}
          removeFile={removeFile}
          mandatoryError={mandatoryError}
        />
        <button
          onClick={importFile}
          disabled={loading ? true : false}
          type="button"
          className="bg-ph-btn float-right text-sm mt-6 text-white rounded-3xl px-6 py-2 hover:bg-ph-hover font-medium"
        >
          {loading ? t("Message.Uploading files") : t("Import register.Import")}
        </button>
        {!loading && (
          <button
            onClick={() => {
              props.closePopOver();
            }}
            type="button"
            className="text-[#626262] mr-4 float-right text-sm mt-6 rounded-3xl px-6 py-2 font-medium"
          >
            {t("Message.Cancel")}
          </button>
        )}
      </div>
    </div>
  );
};

export default AddImportRegister;
