import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/pro-solid-svg-icons";
import TranslationField from "../../../type/translationField";
import { useTranslation } from "react-i18next";

const IncidentInfo: React.FC<{
  incidents: {
    name_transes: TranslationField;
    slug: string;
    featured_icon: string;
  }[];
  index?: boolean;
  scroll?: boolean;
}> = (props) => {
  const { i18n } = useTranslation();
  return (
    <div className="tree-number">
      {props.incidents.length}
      <div
        className={` incident-info shadow-2xl  ${
          props.scroll
            ? "top-[20px]"
            : "bottom-[0px]" && props.index
            ? "bottom-[20px]"
            : "top-[20px]"
        }`}
      >
        {props.incidents.map((incident) => (
          <div className="py-2 px-[15px] flex items-center" key={incident.slug}>
            {incident.featured_icon ? (
              // eslint-disable-next-line jsx-a11y/img-redundant-alt
              <img
                src={incident.featured_icon}
                alt="incident image"
                className="w-[14px] h-[14px] mr-3 object-cover"
              />
            ) : (
              <FontAwesomeIcon icon={faBolt} className="text-[#979797] mr-3" />
            )}
            <p>
              {i18n.language === "fr"
                ? incident.name_transes.fr === ""
                  ? incident.name_transes.en
                  : incident.name_transes.fr
                : incident.name_transes.en}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IncidentInfo;
