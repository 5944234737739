import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Warning: React.FC<{
  when: boolean;
  onOK: () => boolean;
  onCancel: () => boolean;
}> = (props) => {
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [currentPath, setCurrentPath] = useState<string>("");
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (props.when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return "true";
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, props.when]);

  const handleOK = useCallback(async () => {
    if (props.onOK) {
      const canRoute = await Promise.resolve(props.onOK());
      if (canRoute && currentPath !== history.location.pathname) {
        history.block(() => {});
        history.push(currentPath);
      } else {
        setShowPrompt(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath, history, props.onOK]);

  const handleCancel = useCallback(async () => {
    if (props.onCancel) {
      const canRoute = await Promise.resolve(props.onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath, history, props.onCancel]);

  return showPrompt ? (
    <div className="w-full h-full bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0 z-50">
      <div className="bg-white w-full h-fit px-10 py-14 sm:w-[492px] sm:h-[435px] rounded-md shadow-md text-center">
        <FontAwesomeIcon
          className="w-[104px] h-[92px] text-[#FE9D2B]"
          icon={faTriangleExclamation}
        />
        <h1 className="mt-4 text-[25px] font-bold text-[#FE9D2B]">
          {t("Message.Warning")}!
        </h1>
        <p className="mt-6">{t("Message.Leave alert")}</p>
        <div className="mt-7 border-t border-gray-300 pt-8">
          <button
            onClick={handleCancel}
            className="mr-2 px-12 py-3 text-sm font-medium"
          >
            {t("Message.Stay")}
          </button>
          
          <button
            onClick={handleOK}
            className="px-12 py-3 bg-[#FE9D2B] text-white text-sm rounded-3xl font-medium"
          >
            {t("Message.Leave")}
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default Warning;
