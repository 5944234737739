import { useTranslation } from "react-i18next";
import BreadCrumb from "../Global/BreadCrumb";

const HistoryBreadcrumb: React.FC<{ breadCrumb: any }> = (props) => {
  const { i18n, t } = useTranslation();

  return (
    <div>
      <h1 className="font-semibold text-[#414141] text-[16px] mt-5">
        {t("Growth history.Growth history")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Register.Register"), url: "/registers/all" },
          {
            name: `${props.breadCrumb?.data.date}-${
              props.breadCrumb?.data.department_name
            }-${
              i18n.language === "fr"
                ? props.breadCrumb?.data.species_name_transes.fr ||
                  props.breadCrumb?.data.species_name_transes.en
                : props.breadCrumb?.data.species_name_transes.en
            }-${props.breadCrumb?.data.unique_id}`,
            url: "",
          },
          { name: t("Growth history.Growth history"), url: "" },
        ]}
      />
    </div>
  );
};
export default HistoryBreadcrumb;
