import { useTranslation } from "react-i18next";

const ScientificName: React.FC<{
  sName: string;
  index: number;
  sNameChange: (index: number, value: string) => void;
}> = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <input
        onChange={(e) => {
          props.sNameChange(props.index, e.target.value);
        }}
        value={props.sName}
        type="text"
        className="input-field pl-4 py-3 text-sm placeholder-[#C7C7C7]"
        placeholder={t("Tree Species.Scientific name") + ""}
      />
    </div>
  );
};

export default ScientificName;
