import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import AddHistoryForm from "../AddHistory/AddHistoryForm";
import SpeciesList from "../../../type/speciesList";
import { GrowthHistory } from "../../../type/growthHistory";

const EditHistory: React.FC<{
  onUpdate: (data: any) => void;
  setActiveDate: (newActiveItem: string) => void;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  histories: GrowthHistory[];
  editViewHandler: () => void;
  selectedIndex: number;
  selectOptions: {
    growth_stage: SpeciesList[];
    incident: SpeciesList[];
  };
}> = (props) => {
  const { t } = useTranslation();

  const { fetchData, response, error } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/registers/histories/${
        props.histories[props.selectedIndex].slug
      }/edit/`,
    "PUT",
    false,
    "",
    true,
    true
  );

  const { response: getEditResponse } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/registers/histories/${
        props.histories[props.selectedIndex].slug
      }/edit/`,
    "GET",
    true,
    "",
    true,
    false
  );

  const closeEditForm = () => {
    props.editViewHandler();
  };

  return (
    <div className="flex justify-center">
      <div className="w-full h-fit sc:w-[550px]">
        <AddHistoryForm
          onSubmit={props.onUpdate}
          setActiveDate={props.setActiveDate}
          setIsEdit={props.setIsEdit}
          closeForm={closeEditForm}
          sendError={error}
          selectOptions={props.selectOptions}
          send={fetchData}
          sendResponse={response}
          getInfo={getEditResponse}
          textButton={t("Tree Species.Update")}
          title={t("Growth history.Edit growth history")}
        />
      </div>
    </div>
  );
};

export default EditHistory;
