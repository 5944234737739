import { Fragment } from "react";
import TableCell from "@mui/material/TableCell";
import TranslationField from "../../../type/translationField";
import { useTranslation } from "react-i18next";

const RegisterTableItem: React.FC<{
  name_transes: TranslationField;
  type_transes: TranslationField;
  trees_count: number;
  dead_trees?: number;
}> = (props) => {
  const { i18n } = useTranslation();
  const name =
    props.name_transes?.fr === ""
      ? props.name_transes.en
      : props.name_transes.fr;
  return (
    <Fragment>
      <TableCell>
        {i18n.language === "fr" ? name : props.name_transes.en}
      </TableCell>
      <TableCell>
        {i18n.language === "fr" ? props.type_transes.fr : props.type_transes.en}
      </TableCell>
      <TableCell align="center">{props.trees_count}</TableCell>
      <TableCell align="center">{props.dead_trees}</TableCell>
    </Fragment>
  );
};

export default RegisterTableItem;
