import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import DeleteAlert from "../../ErrorAndAlert/DeleteAlert";
import { Workshops } from "../../../type/workshops";
import WorkshopParticipants from "./WorkshopParticipants";
import ActionItem from "../../Global/ActionItem";
import { Link } from "react-router-dom";

const WorkshopTableItem: React.FC<{
  workshopItem: Workshops;
  fetchData: () => void;
  lastIndex: number;
}> = (props) => {
  const { t, i18n } = useTranslation();
  const [showDelete, isShowDelete] = useState<boolean>(false);
  const index = props.lastIndex >= 0 ? true : false;
  const scroll = props.lastIndex === 0 ? true : false;

  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/workshop/${props.workshopItem.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.Successful delete")}`,
    true,
    true
  );

  useEffect(() => {
    if (deleteR) {
      props.fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const deleteAction = () => {
    isShowDelete(true);
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
    isShowDelete(false);
  };
  const name =
    props.workshopItem.name_transes.fr === ""
      ? props.workshopItem.name_transes.en
      : props.workshopItem.name_transes.fr;
  const desc =
    props.workshopItem.description_transes.fr === ""
      ? props.workshopItem.description_transes.en
      : props.workshopItem.description_transes.fr;
  return (
    <Fragment>
      <TableCell align="left">
        <Link
          className="hover:text-[#439FC6]"
          to={`/workshops/view-workshop/${props.workshopItem.slug}`}
        >
          <div
            title={
              i18n.language === "fr" ? name : props.workshopItem.name_transes.en
            }
            className="name-overflow"
          >
            {i18n.language === "fr" ? name : props.workshopItem.name_transes.en}
          </div>
        </Link>
      </TableCell>
      <TableCell align="left">
        {props.workshopItem.date_from} <br />
        {props.workshopItem.time_from}
      </TableCell>
      <TableCell align="left">
        {props.workshopItem.date_to}
        <br />
        {props.workshopItem.time_to}
      </TableCell>
      <TableCell align="left">{props.workshopItem.department}</TableCell>
      <TableCell align="left">{props.workshopItem.municipality}</TableCell>
      <TableCell align="left">{props.workshopItem.community}</TableCell>
      <TableCell align="left">
        <div
          title={
            i18n.language === "fr"
              ? desc
              : props.workshopItem.description_transes.en
          }
          className="desc-tooltip"
        >
          {i18n.language === "fr"
            ? desc
            : props.workshopItem.description_transes.en}
        </div>
      </TableCell>
      <TableCell align="left">
        <WorkshopParticipants
          totalNum={props.workshopItem.participants_total}
          others={props.workshopItem.other_participants}
          psaS={props.workshopItem.psa_students}
          psaT={props.workshopItem.psa_tutors}
          schoolS={props.workshopItem.school_students}
          schoolT={props.workshopItem.school_tutors}
          index={index}
          scroll={scroll}
          farmers={props.workshopItem.farmers}
          promoters={props.workshopItem.promoters}
          junior_youth_parents={props.workshopItem.junior_youth_parents}
          community_leaders={props.workshopItem.community_leaders}
        />
      </TableCell>
      <TableCell align="right">
        <ActionItem
          delete={true}
          editLink={`/workshops/edit-workshop/${props.workshopItem.slug}`}
          deleteAction={deleteAction}
          view={t("Workshops.View workshop")}
          viewLink={`/workshops/view-workshop/${props.workshopItem.slug}`}
          historyLink=""
          viewHistory=""
          index={index}
          scroll={scroll}
        />
      </TableCell>
      {showDelete && (
        <DeleteAlert
          type={t("Workshops.Workshop")}
          hide={hide}
          deleteAction={deleteItem}
        />
      )}
    </Fragment>
  );
};

export default WorkshopTableItem;
