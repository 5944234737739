import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { TableContainer } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@emotion/react";
import TableFooter from "@mui/material/TableFooter";
import PlantingTableItem from "./PlantingTableItem";
import { TableThem } from "../../../styles/TableThem";
import TableHeader from "../TableHeader";
import { useTranslation } from "react-i18next";
import { getComparator, sortedRowInformation } from "../tableUtils";
import TablePagination from "../../Global/TablePagination";

const PlantingTableContent: React.FC<{
  plantings: {
    department_name: string;
    plantings: number;
    planted_trees: number;
    participants: number;
    dead_trees: number;
  }[];
  showDead: boolean;
}> = (props) => {
  const [orderDirection, setOrderDirection] = useState<"desc" | "asc">("asc");
  const [valueToOrderBy, setValueToOrderBy] = useState<string>("name");
  const [page, setPage] = useState(0);
  const { t } = useTranslation();

  const handleRequestSort = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    property: string
  ) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value - 1);
  };
  useEffect(() => {
    setPage(0);
  }, [props.plantings]);
  return (
    <TableContainer>
      <ThemeProvider theme={TableThem}>
        <Table>
          <TableHeader
            valueToOrderBy={valueToOrderBy}
            orderDirection={orderDirection}
            handleRequestSort={handleRequestSort}
            titels={[
              {
                name: t("Plantings.Province"),
                value: "department",
                align: "left",
              },
              {
                name: t("Navbar.Planting events"),
                value: "plantings",
                align: "center",
              },
              {
                name: t("Statistics.Planted trees"),
                value: "planted_trees",
                align: "center",
              },
              props.showDead
                ? {
                    name: t("Statistics.Dead trees"),
                    value: "dead_trees",
                    align: "center",
                  }
                : {
                    name: "",
                    value: "",
                    align: "center",
                  },
              {
                name: t("PlantingFilter.Participants"),
                value: "participants",
                align: "center",
              },
            ]}
          />
          <TableBody>
            {sortedRowInformation(
              props.plantings,
              getComparator(orderDirection, valueToOrderBy)
            )
              .slice(page * 3, page * 3 + 3)
              .map((planting: any, index: number) => (
                <TableRow key={index}>
                  <PlantingTableItem
                    department={planting.department_name}
                    plantingEvents={planting.plantings}
                    plantedTrees={planting.planted_trees}
                    dead_trees={props.showDead ? planting.dead_trees : null}
                    participants={planting.participants}
                  />
                </TableRow>
              ))}
            {page === Math.ceil(props.plantings.length / 3) - 1 &&
              props.plantings.length % 3 === 1 && (
                <Fragment>
                  <TableRow></TableRow>
                  <TableRow></TableRow>
                </Fragment>
              )}
            {page === Math.ceil(props.plantings.length / 3) - 1 &&
              props.plantings.length % 3 === 2 && (
                <Fragment>
                  <TableRow></TableRow>
                </Fragment>
              )}
          </TableBody>
          <TableFooter
            style={{ border: "none", height: "70px", position: "relative" }}
          >
            <Box
              sx={{ flexShrink: 0 }}
              style={{ position: "absolute", left: "0px", top: "20px" }}
            >
              <Stack spacing={2}>
                <TablePagination
                  count={Math.ceil(props.plantings.length / 3)}
                  handleChange={handleChangePage}
                />
              </Stack>
            </Box>
          </TableFooter>
        </Table>
      </ThemeProvider>
    </TableContainer>
  );
};
export default PlantingTableContent;
