import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect } from "react";
import useAxios from "../../hook/useAxios";
import { toast } from "react-toastify";

const Export: React.FC<{
  url: string;
  name: string;
  title: string;
  handleLoading: () => void;
  needBorder: boolean;
}> = (props) => {
  const { t } = useTranslation();

  const { fetchData, response: exportResponse } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/${props.name}/export${props.url}`,
    "GET",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    if (exportResponse) {
      handleExport();
      props.handleLoading();
      toast.success(`${t("Message.Successful Export")}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportResponse]);

  const handleExport = () => {
    const csv = exportResponse.data;
    // Set the encoding to UTF-8 in order to support French characters
    const BOMprefix = "\uFEFF";
    const blob = new Blob([`${BOMprefix}${csv}`], {
      type: "text/csv;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    try {
      const filename =
        exportResponse.headers["content-disposition"].split("filename=")[1];
      a.download = filename;
    } catch (error) {
      a.download = props.name + ".csv";
    }
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleExportClick = () => {
    props.handleLoading();
    fetchData({});
  };

  return (
    <Fragment>
      <span
        title={props.title}
        className="cursor-pointer text-[#066993]"
        onClick={handleExportClick}
      >
        <FontAwesomeIcon className="mr-4 w-5 h-[18px]" icon={faFileExport} />
      </span>
      {props.needBorder && (
        <span className="border-l-[1px] solid text-[#eeecec] mr-4" />
      )}
    </Fragment>
  );
};

export default Export;
