import { ChangeEvent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useAxios from "../../../hook/useAxios";
import { plantingTheme } from "../../Register/RegisterList/PlantingTheme";
import { useDispatch, useSelector } from "react-redux";
import { speciesFilterAction } from "../../../store/speciesFilter-slice";
import { speciesFilterDelete, speciesFilterSet } from "../../../store";
import { SpeciesBaseId } from "../../../type/SpeciesBaseId";
import { SpeciesBaseValue } from "../../../type/speciesBaseValue";
import { useTranslation } from "react-i18next";
import CustomAutocomplete from "../../Global/CustomAutocomplete";

type FormValues = {
  type: SpeciesBaseId;
  abundance: string;
};
const SpeciesFilterBox = () => {
  const [speciesType, setSpeciesType] = useState<SpeciesBaseId[]>([]);
  const [abundance, setAbundance] = useState<SpeciesBaseValue[]>([]);
  const { response: dropDownDataRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/base_data/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { handleSubmit, resetField, control, reset } = useForm<FormValues>();
  const isFilterSet = useSelector(speciesFilterSet);
  const deleteItem = useSelector(speciesFilterDelete);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (deleteItem !== "") {
      resetField(deleteItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteItem]);
  useEffect(() => {
    if (dropDownDataRes) {
      setSpeciesType(dropDownDataRes.data.species_type_transes);
      setAbundance(dropDownDataRes.data.abundance_choices_transes);
    }
  }, [dropDownDataRes]);

  const applyFilter = (data: FormValues) => {
    dispatch(
      speciesFilterAction.setFilter({
        abundance: `${data.abundance === undefined ? "" : data.abundance}`,
        type: data.type,
      })
    );
    dispatch(speciesFilterAction.isFilterSet());
    dispatch(speciesFilterAction.setURL());
  };
  const clearFliter = () => {
    reset();
    dispatch(speciesFilterAction.clearFilter());
    dispatch(speciesFilterAction.setURL());
  };
  return (
    <div className="w-full h-fit bg-white p-4 rounded-md relative mt-3">
      <form onSubmit={handleSubmit(applyFilter)}>
        <ThemeProvider theme={plantingTheme}>
          <div className="flex flex-wrap justify-between w-full sm:w-[80%] md:w-[65%] mt-5">
            <div className="w-full lg:w-[48%]">
              <p className="text-sm mb-[6px] text-[#414141]">
                {t("Tree Species.Type")}
              </p>
              <CustomAutocomplete
                control={control}
                name="type"
                selectOptions={speciesType}
                placeholder={t("Tree Species.Type")}
                getOptionLabel={(option) =>
                  i18n.language === "fr" ? option.fr : option.en
                }
              />
            </div>
            <div className="w-full lg:w-[48%] mt-5 lg:mt-0">
              <p className="text-sm mb-[6px] text-[#414141]">
                {t("Tree Species.Abundance")}
              </p>

              <Controller
                control={control}
                name="abundance"
                render={({ field: { onChange, value } }) => (
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel shrink={false}>
                      {value === undefined && `${t("Tree Species.Abundance")}`}
                    </InputLabel>
                    <Select
                      style={{ height: "38px" }}
                      IconComponent={ExpandMoreIcon}
                      variant="outlined"
                      value={value || null}
                      onChange={(e) => {
                        onChange(e as ChangeEvent<Element>);
                      }}
                      sx={{
                        border: "1px solid #eeecec",
                        borderRadius: "4px",
                        "& fieldset": { border: "none" },
                      }}
                    >
                      {abundance.map((ab) => (
                        <MenuItem value={ab.value} key={ab.value}>
                          {i18n.language === "fr" ? ab.fr : ab.en}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </div>
          </div>
        </ThemeProvider>
        <div className="mt-5 sm:mt-0 sm:absolute sm:right-4 sm:bottom-4">
          {isFilterSet && (
            <button
              onClick={clearFliter}
              className="py-2 px-6 text-sm font-medium rounded-3xl text-[#414141] hover:bg-[#F6F6F6] mr-3"
              type="button"
            >
              {t("PlantingFilter.Reset")}
            </button>
          )}

          <button
            type="submit"
            className=" hover:bg-ph-hover hover:text-white text-ph-btn py-2 px-6 border border-ph-btn text-sm font-medium rounded-3xl"
          >
            {t("PlantingFilter.Apply")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SpeciesFilterBox;
