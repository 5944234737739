import React, { useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import file from "../../type/file";
import FileAccepted from "../Register/AddRegister/FileAccepted";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "24px",
  borderWidth: 1,
  borderRadius: 2,
  borderColor: "#C7C7C7",
  backgroundColor: "#FCFCFC",
  border: "1px dashed",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#439FC6",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const DragDropField: React.FC<{
  format: string;
  removeFile: (index: number) => void;
  insertFile: (file: file[]) => void;
  files: file[];
  mandatoryError?: boolean;
}> = (props) => {
  const { t } = useTranslation();
  const removeAction = (fileIndex: number) => {
    props.removeFile(fileIndex);
  };
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "image/jpeg": [
          ".jpeg",
          ".png",
          ".svg",
          ".pdf",
          ".docx",
          ".xlsx",
          ".csv",
          ".txt",
        ],
      },
      onDrop: (acceptedFiles: any) => {
        let f: file[] = [];
        for (const accFile of acceptedFiles) {
          f.push({
            name: accFile.name,
            size: accFile.size,
            url: accFile.type.includes("image")
              ? URL.createObjectURL(accFile)
              : "",
            type: accFile.type,
            data: accFile,
          });
        }
        props.insertFile(f);
      },
    });
  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject || props.mandatoryError ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject, props.mandatoryError]
  );

  const thumbs = props.files.map(
    (fi, index: number) =>
      fi.type !== "deleted" && (
        <FileAccepted
          fileName={fi.name}
          fileSize={fi.size}
          fileUrl={fi.url}
          fileIndex={index}
          fileType={fi.type}
          key={index}
          removeAction={removeAction}
        />
      )
  );

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      props.files.forEach((fileI) => {
        if (fileI.url !== "") {
          URL.revokeObjectURL(fileI.url);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p className="text-center text-xs">
          {t("Message.Drag & Drop or")}{" "}
          <span className="text-[#066993]">{t("Message.Click")}</span>{" "}
          {t("Message.To browse")}
          <br /> {props.format}
        </p>
      </div>
      {props.mandatoryError && (
        <p className="error-text">{t("Message.Required field")}</p>
      )}
      <aside
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          marginTop: 16,
        }}
      >
        {thumbs}
      </aside>
    </section>
  );
};

export default DragDropField;
