import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUserGroup,
  faBuilding,
} from "@fortawesome/pro-solid-svg-icons";

const OrganizerName: React.FC<{
  organizers: { type: string; slug: string; name: string }[];
  index?: boolean;
  scroll?: boolean;
}> = (props) => {
  return (
    <div className="tree-number">
      {props.organizers.length}
      <div
        className={`incident-info shadow-2xl ${
          props.scroll
            ? "top-[20px]"
            : "bottom-[0px]" && props.index
            ? "bottom-[20px]"
            : "top-[20px]"
        }`}
      >
        {props.organizers.map((name) => (
          <div className="py-2 px-[15px] flex items-center" key={name.slug}>
            <FontAwesomeIcon
              icon={
                name.type === "ind"
                  ? faUser
                  : name.type === "grp" || name.type === "fml"
                  ? faUserGroup
                  : faBuilding
              }
              className="text-[#979797] mr-3"
            />
            <p>{name.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrganizerName;
