import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";

const LanguagePoint: React.FC<{ lang?: string }> = (props) => {
  return (
    <span className="ml-2 text-[#979797] text-xs">
      <FontAwesomeIcon
        className={`${
          props.lang === "EN" ? "text-red-600" : "text-[#2853B4]"
        } "w-2 h-2 pb-[2px] mr-1`}
        icon={faCircle}
      />
      {props.lang === "EN" ? "EN" : "FR"}
    </span>
  );
};

export default LanguagePoint;
