import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import DeleteAlert from "../../ErrorAndAlert/DeleteAlert";
import ActionItem from "../../Global/ActionItem";
import { UsersRecord } from "../../../type/user";
import { useDispatch, useSelector } from "react-redux";
import { profileInfo } from "../../../store";
import RootAdminAssign from "../../ErrorAndAlert/RootAdminAssign";
import { LoginSliceActions } from "../../../store/login-slice";
import { faUserGear } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UserTableItem: React.FC<{
  user: UsersRecord;
  fetchData: () => void;
  lastIndex: number;
}> = (props) => {
  const { i18n, t } = useTranslation();
  const profile = useSelector(profileInfo);
  const [showDelete, isShowDelete] = useState<boolean>(false);
  const [rootAdmin, setRootAdmin] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const dispatch = useDispatch();
  const index = props.lastIndex >= 0 ? true : false;
  const scroll = props.lastIndex === 0 ? true : false;

  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/users/${props.user.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.Successful delete")}`,
    true,
    true
  );

  const { fetchData: fetchAssign } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/users/${props.user.slug}/assign-root-admin/`,
    "PUT",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    if (deleteR !== null) {
      isShowDelete(false);
      props.fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const rootAdminAction = async () => {
    try {
      setRootAdmin(true);
      await fetchAssign({
        is_root_admin: true,
      });
      await props.fetchData();
      dispatch(LoginSliceActions.userRoleChange());
      setRootAdmin(false);
    } catch (error) {
      console.error("Error updating root admin:", error);
      setRootAdmin(false);
    }
  };

  const deleteAction = () => {
    isShowDelete(true);
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
  };
  const active =
    i18n.language === "fr"
      ? props.user.is_active_transes.fr
      : props.user.is_active_transes.en;
  return (
    <Fragment>
      <TableCell align="left">
        <div className="flex items-center">
          {props.user.profile_image ? (
            // eslint-disable-next-line jsx-a11y/img-redundant-alt
            <img
              src={props.user.profile_image}
              alt="profile picture"
              className="w-10 h-10 mr-2 object-cover rounded-md"
            />
          ) : (
            <div className="flex items-center justify-center w-10 h-10 rounded-md object-cover bg-[#F9FAFB] mr-2">
              <p className="text-[#066993]">
                {props.user.first_name[0]}
                {props.user.last_name[0]}
              </p>
            </div>
          )}

          <p className="w-32">
            <span className="text-[#414141]">
              {props.user.first_name} {props.user.last_name}
            </span>
          </p>
          {props.user.is_root_admin && (
            <span className="text-[#414141]">
              <FontAwesomeIcon icon={faUserGear} />
            </span>
          )}
        </div>
      </TableCell>
      <TableCell>{props.user.email}</TableCell>
      <TableCell>
        {i18n.language === "fr"
          ? props.user.role_transes.fr
          : props.user.role_transes.en}
      </TableCell>
      <TableCell>
        <p
          className={`${
            props.user.is_active_transes.en === "Active"
              ? "bg-[#CDE6F0] text-[#066993]"
              : "bg-[#FFDBDE] text-[#D2202A]"
          } text-center w-16 py-1 rounded-xl`}
        >
          {active}
        </p>
      </TableCell>
      <TableCell>
        {props.user.last_login && (
          <p>
            {props.user.last_login.split(" ")[0]} <br />
            {props.user.last_login.split(" ")[1]}
          </p>
        )}
      </TableCell>
      {profile.is_root_admin && (
        <TableCell align="right">
          {!props.user.is_root_admin && (
            <ActionItem
              delete={props.user.is_deletable}
              editLink={`/setting/users/edit-user/${props.user.slug}`}
              deleteAction={deleteAction}
              view=""
              viewLink=""
              historyLink=""
              viewHistory=""
              index={index}
              scroll={scroll}
              setShowAlert={setShowAlert}
              rootAdmin={props.user.is_root_admin}
            />
          )}
        </TableCell>
      )}
      {showAlert && (
        <RootAdminAssign
          name={props.user.first_name + " " + props.user.last_name}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          onRootAdminAction={rootAdminAction}
        />
      )}
      {showDelete && (
        <DeleteAlert
          type={t("Users.User")}
          hide={hide}
          deleteAction={deleteItem}
        />
      )}
    </Fragment>
  );
};

export default UserTableItem;
