import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import SpeciesList from "../type/speciesList";
import { ResponsibleType } from "../type/responsibleType";

type RegisterFilter = {
  collaboratorType: string;
  dateFrom: string;
  dateTo: string;
  treeSpecies: SpeciesList | null | undefined;
  collaborator: ResponsibleType | null | undefined;
};
const initialState: {
  filterItem: RegisterFilter;
  url: string;
  filterSet: boolean;
  deleteFilter:
    | "collaboratorType"
    | "date"
    | "treeSpecies"
    | "collaboratorName"
    | "";
} = {
  filterItem: {
    collaboratorType: "",
    collaborator: null,
    dateFrom: "",
    dateTo: "",
    treeSpecies: null,
  },
  url: "/?",
  filterSet: false,
  deleteFilter: "",
};

const registerFilterSlice = createSlice({
  name: "registerFilter",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<RegisterFilter>) => {
      state.filterItem = action.payload;
      state.deleteFilter = "";
    },
    removeDate: (state) => {
      state.filterItem.dateFrom = "";
      state.filterItem.dateTo = "";
      state.deleteFilter = "date";
    },
    removeTreeSpecies: (state) => {
      state.filterItem.treeSpecies = null;
      state.deleteFilter = "treeSpecies";
    },
    removeCollaboratorName: (state) => {
      state.filterItem.collaborator = null;
      state.deleteFilter = "collaboratorName";
    },
    removeCollaboratorType: (state) => {
      state.filterItem.collaboratorType = "";
      state.filterItem.collaborator = null;
      state.deleteFilter = "collaboratorType";
    },
    setURL: (state) => {
      state.url = `/?date_from=${state.filterItem.dateFrom}&date_to=${
        state.filterItem.dateTo
      }&responsible_category=${
        state.filterItem.collaboratorType
      }&responsible_type=${
        state.filterItem.collaborator ? state.filterItem.collaborator.slug : ""
      }&species=${
        state.filterItem.treeSpecies ? state.filterItem.treeSpecies.slug : ""
      }`;
    },
    clearFilter: (state) => {
      state.filterSet = false;
      state.filterItem.collaborator = null;
      state.filterItem.treeSpecies = null;
      state.filterItem.dateFrom = "";
      state.filterItem.dateTo = "";
      state.filterItem.collaboratorType = "";
      state.deleteFilter = "";
    },
    isFilterSet: (state) => {
      if (
        !state.filterItem.collaborator &&
        state.filterItem.dateFrom === "" &&
        state.filterItem.dateTo === "" &&
        !state.filterItem.treeSpecies &&
        state.filterItem.collaboratorType === ""
      ) {
        state.filterSet = false;
      } else {
        state.filterSet = true;
      }
    },
  },
});

export const registerFilterAction = registerFilterSlice.actions;
export default registerFilterSlice;
