import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import DeleteAlert from "../../ErrorAndAlert/DeleteAlert";
import { Planting } from "../../../type/planting";
import WorkshopParticipants from "../../Workshops/WorkshopList/WorkshopParticipants";
import InfoPopover from "../../Register/RegisterList/InfoPopover";
import TreesDetail from "./TreeDetail";
import OrganizerName from "./OrganizerName";
import PlantingActionMenu from "./PlantingActionMenu";
import { Link } from "react-router-dom";

const PlantingTableItem: React.FC<{
  planting: Planting;
  fetchData: () => void;
  lastIndex: number;
}> = (props) => {
  const { t, i18n } = useTranslation();
  const [showDelete, isShowDelete] = useState<boolean>(false);
  
  const index = props.lastIndex >= 0 ? true : false;

  const scroll = props.lastIndex === 0 ? true : false;

  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/plantings/${props.planting.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.Successful delete")}`,
    true,
    true
  );

  useEffect(() => {
    if (deleteR) {
      isShowDelete(false);
      props.fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const deleteAction = () => {
    isShowDelete(true);
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
  };

  const name =
    props.planting.name_transes.fr === ""
      ? props.planting.name_transes.en
      : props.planting.name_transes.fr;

  // Convert the string date to a Date object
  const getPlantingDate = () => {
    const plantingDate = new Date(
      props.planting.date.split("/").reverse().join("-") // Convert to "yyyy-mm-dd" format
    );
    return plantingDate;
  };

  return (
    <Fragment>
      <TableCell align="left">
        <Link
          className="hover:text-[#0884B8]"
          to={`/plantings/view-planting/${props.planting.slug}`}
        >
          <div
            title={
              i18n.language === "fr" ? name : props.planting.name_transes.en
            }
            className="name-overflow"
          >
            {i18n.language === "fr" ? name : props.planting.name_transes.en}
          </div>
        </Link>
      </TableCell>
      <TableCell align="left">{props.planting.date}</TableCell>
      <TableCell align="left">
        <div className=" flex items-center">
          {" "}
          {props.planting.department}
          {props.planting.description_transes.en !== "" && (
            <InfoPopover
              index={index}
              scroll={scroll}
              desc={props.planting.description_transes}
            />
          )}
        </div>
      </TableCell>
      <TableCell align="left">{props.planting.municipality}</TableCell>
      <TableCell align="left">{props.planting.community}</TableCell>
      <TableCell align="left">
        <OrganizerName
          organizers={props.planting.organizers}
          index={index}
          scroll={scroll}
        />
      </TableCell>
      <TableCell align="left">{props.planting.planted_trees}</TableCell>
      <TableCell align="left">
        {props.planting.registered_trees > 0 ? (
          <div className="flex items-center">
            <TreesDetail
              num={props.planting.registered_trees}
              trees={props.planting.trees_info}
              index={index}
              scroll={scroll}
            />
            {props.planting.registered_trees > props.planting.planted_trees && (
              <InfoPopover
                index={index}
                scroll={scroll}
                reg={true}
                desc={{
                  en: "The registered trees are more than planted trees.",
                  fr: "Les arbres enregistrés sont plus nombreux que les arbres plantés.",
                }}
              />
            )}
          </div>
        ) : (
          <p className="ml-[6px]">0</p>
        )}
      </TableCell>
      <TableCell align="left">
        <WorkshopParticipants
          totalNum={props.planting.participants_total}
          others={props.planting.other_participants}
          psaS={props.planting.psa_students}
          psaT={props.planting.psa_tutors}
          schoolS={props.planting.school_students}
          schoolT={props.planting.school_tutors}
          farmers={props.planting.farmers}
          promoters={props.planting.promoters}
          junior_youth_parents={props.planting.junior_youth_parents}
          community_leaders={props.planting.community_leaders}
          index={index}
          scroll={scroll}
        />
      </TableCell>
      <TableCell align="center">
        <PlantingActionMenu
          deleteAction={deleteAction}
          plantedTrees={props.planting.planted_trees}
          registeredTrees={props.planting.registered_trees}
          slug={props.planting.slug}
          plantingDate={getPlantingDate()}
          index={index}
          scroll={scroll}
        />
      </TableCell>
      {showDelete && (
        <DeleteAlert
          message={
            props.planting.registered_trees > 0
              ? t("Message.Delete planting with its registers alert") + ""
              : ""
          }
          type={t("Plantings.Planting")}
          hide={hide}
          deleteAction={deleteItem}
        />
      )}
    </Fragment>
  );
};

export default PlantingTableItem;
