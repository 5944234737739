import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';

const SignOutAlert: React.FC<{
	hide: () => void;
	signOutAction: () => void;
	message?: string;
}> = (props) => {
	const { hide, signOutAction } = props;
	
	const { t } = useTranslation();
	const signOutItem = () => {
		signOutAction();
	};
	const hidePopOver = () => {
		hide();
	};
	return (
		<div className="w-full h-full bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0 z-50">
			<div className="bg-white w-full h-fit px-10 py-14 sm:w-[492px] rounded-md shadow-md text-center">
				<FontAwesomeIcon
					className="w-[104px] h-[104px] text-[#D2202A]"
					icon={faCircleExclamation}
				/>
				<h1 className="mt-4 text-[25px] font-bold text-[#D2202A]">
					{t('Navbar.Sign out')}
				</h1>
				<p className="mt-5">
					{props?.message || (
						<Fragment>
							{t('Message.Are you sure you want to')} <br />{' '}
							<span className="font-bold">{t('Navbar.sign out')} </span>
						</Fragment>
					)}
				</p>
				<div className="mt-7 border-t border-gray-300 pt-8">
					<button
						onClick={hidePopOver}
						className="mr-2 px-12 py-3 text-sm font-medium"
					>
						{t('Message.No')}
					</button>
					<button
						onClick={signOutItem}
						className="px-12 py-3 bg-[#D2202A] text-white text-sm rounded-3xl font-medium"
					>
						{t('Message.Yes')}
					</button>
				</div>
			</div>
		</div>
	);
};

export default SignOutAlert;
