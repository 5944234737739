import { Fragment} from "react";
import TableCell from "@mui/material/TableCell";

const PlantingTableItem: React.FC<{
  department: string;
  plantingEvents: number;
  plantedTrees : number;
  dead_trees: number;
  participants: number;
}> = (props) => {
  return (
    <Fragment>
      <TableCell>{props.department}</TableCell>
      <TableCell align="center">{props.plantingEvents}</TableCell>
      <TableCell align="center">{props.plantedTrees}</TableCell>
      <TableCell align="center">{props.dead_trees}</TableCell>
      <TableCell align="center">{props.participants}</TableCell>
    </Fragment>
  );
};

export default PlantingTableItem;
