import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper.min.css";
import "./slider.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import { responseFile } from "../../type/responseFile";

SwiperCore.use([Navigation, Autoplay, Pagination]);
const Slider: React.FC<{ images: string[] | responseFile[]; text: boolean }> = (props) => {
  return (
    <div className="slider-container">
      <Swiper
        navigation
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        speed={800}
        pagination={true}
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
      >
        {props.images.map((image: any, index) => (
          <SwiperSlide key={index} style={{ zIndex: "10" }}>
            <img
              src={image?.thumbnail_address || image}
              alt="picture1"
              className="mt-10 md:mt-3 w-full object-contain h-[380px]"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
