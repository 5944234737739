import { Fragment } from "react";
import TableCell from "@mui/material/TableCell";

const WorkshopTableItem: React.FC<{
  department_name: string;
  workshop_count: number;
  total_participants: number;
}> = (props) => {
  return (
    <Fragment>
      <TableCell align="left">{props.department_name}</TableCell>
      <TableCell align="center">{props.workshop_count}</TableCell>
      <TableCell align="center">{props.total_participants}</TableCell>
    </Fragment>
  );
};

export default WorkshopTableItem;
