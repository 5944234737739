import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Warning from "../../ErrorAndAlert/Warning";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../Register/AddRegister/FormTheme";
import Status from "../../Global/Status";

type FormValues = {
  name: string;
  category: string;
};

const AddResponsibleTypeForm: React.FC<{
  send: (data: any) => void;
  sendResponse: any;
  getInfo?: any;
  textButton: string;
}> = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [dirty, isDirty] = useState<boolean>(true);
  const [active, isActive] = useState<boolean>(true);
  const [editable, isEditable] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    if (props.sendResponse) {
      toast.success(
        `${
          props.getInfo
            ? `${t("Message.Successful update")}`
            : `${t("Message.Successful add")}`
        }`
      );
      history.replace("/setting/responsible");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sendResponse]);

  useEffect(() => {
    if (props.getInfo) {
      setLoading(true);
      setValue("name", props.getInfo.data.name);
      setValue("category", props.getInfo.data.category);
      isActive(props.getInfo.data.is_active);
      isEditable(props.getInfo.data.is_editable);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getInfo]);

  const {
    handleSubmit,
    setValue,
    register,
    control,
    formState: { errors },
  } = useForm<FormValues>();

  const addResponsibleType = (data: FormValues) => {
    isDirty(false);
    if (!editable && data.category !== props.getInfo.data.category) {
      toast.error(t("Message.Invalid responsible category edit"));
    } else {
      props.send({
        name: data.name,
        category: data.category,
        is_active: active,
      });
    }
  };

  return (
    <Fragment>
      {loading && props.getInfo ? (
        <p className="text-center text-2xl font-medium text-[#414141]">
          {t("Message.Loading")} ...
        </p>
      ) : (
        <form onSubmit={handleSubmit(addResponsibleType)}>
          <p className="text-sm text-[#414141] mb-[6px]">
            {t("Tree register responsible.Responsible type")} *
          </p>
          <input
            {...register("name", {
              required: "required",
            })}
            type="text"
            className="input-field pl-4 py-3 text-sm placeholder-[#C7C7C7]"
            placeholder={t("Tree register responsible.Responsible type") + ""}
          />
          {errors.name && (
            <p className="error-text">{t("Message.Required field")}</p>
          )}

          <Status
            active={active}
            isActive={(value) => {
              isActive(value);
            }}
          />
          <p className="text-sm text-[#414141] mb-[6px] mt-7">
            {t("Tree register responsible.Responsible category")} *
          </p>
          <ThemeProvider theme={theme}>
            <Controller
              control={control}
              name="category"
              rules={{ required: `${t("Message.Required field")}` }}
              render={({ field: { onChange, value } }) => (
                <FormControl style={{ width: "100%" }}>
                  <InputLabel shrink={false}>
                    {!value &&
                      t("Tree register responsible.Responsible category")}
                  </InputLabel>
                  <Select
                    style={{ height: "48px" }}
                    IconComponent={ExpandMoreIcon}
                    variant="outlined"
                    value={value}
                    onChange={(e) => {
                      onChange(e as ChangeEvent<Element>);
                    }}
                    sx={{
                      border: "1px solid #eeecec",
                      borderRadius: "4px",
                      "& fieldset": {
                        border: "none",
                      },
                    }}
                  >
                    <MenuItem value="fml">{t("Register.Family")}</MenuItem>
                    <MenuItem value="grp">{t("Register.Group")}</MenuItem>
                    <MenuItem value="ins">{t("Register.Institution")}</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </ThemeProvider>
          {errors.category && (
            <p className="error-text">{t("Message.Required field")}</p>
          )}
          <button
            type="submit"
            className="main-btn text-sm px-6 py-2 mt-5 float-right font-medium"
          >
            {props.textButton}
          </button>
        </form>
      )}
      <Warning when={dirty} onCancel={() => false} onOK={() => true} />
    </Fragment>
  );
};

export default AddResponsibleTypeForm;
