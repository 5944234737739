import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import AddBotanicalFamilyForm from "../../BotanicalFamily/AddBotanicalFamily/AddBotanicalFamilyForm";
import Loading from "../../Global/Loading";

const EditTreeOrigin = () => {
  const { id } = useParams<any>();
  const { fetchData: editTreeOrigin, response: editResponse } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/origins/${id}/edit/`,
    "PUT",
    false,
    "",
    true,
    true
  );

  const {
    response: getEditResponse,
    fetchData: getTreeOrigin,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/origins/${id}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );

  const { t } = useTranslation();

  useEffect(() => {
    getTreeOrigin({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-[#414141] text-[16px] mt-5">
        {t("Tree origins.Edit nursery")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          { name: t("Tree origins.Nurseries"), url: "/setting/tree-origins" },
          { name: t("Tree origins.Edit nursery"), url: "" },
        ]}
      />
      {loading ? (
        <Loading loading={true} />
      ) : (
        <div className="flex flex-wrap justify-between mt-4">
          <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
            <AddBotanicalFamilyForm
              link="/setting/tree-origins"
              title={t("Register.Nursery")}
              send={editTreeOrigin}
              sendResponse={editResponse}
              getInfo={getEditResponse}
              textButton={t("Tree Species.Update")}
            />
          </div>
          <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
            <img
              src="/img/Botanical families.svg"
              alt="Edit incident"
              className="mt-4 ml-2 xl:ml-10"
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default EditTreeOrigin;
