import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../Global/BreadCrumb";
import SearchField from "../Global/SearchField";
import { useParams } from "react-router-dom";
import { ImportRegisterRecord } from "../../type/importRegister";
import useAxios from "../../hook/useAxios";
import { TableThem } from "../../styles/TableThem";
import { ThemeProvider } from "@emotion/react";
import TablePagination from "../Global/TablePagination";
import AddImportRegister from "./AddImportRegister";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faDownload } from "@fortawesome/pro-regular-svg-icons";
import DownloadTempleteImport from "./DownloadTempleteImport";
import GenericTable from "../Global/GenericTable";
import ImportRegisterTableItem from "./ImportRegisterTableItem";
const ImportRegister = () => {
  const [importList, setImportList] = useState<ImportRegisterRecord[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [sendSearch, setSendSearch] = useState<string>("");
  const [addImport, isAddImport] = useState<boolean>(false);
  const [rotate, setRotate] = useState(false);
  const [downloadTempleteImport, isDownloadTempleteImport] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const { id } = useParams<any>();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const {
    fetchData: getImport,
    response,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/imports${
        id === "all" ? "" : `/${id}`
      }/?page=${page}&search=${sendSearch}&ordering=${ordering}`,
    "GET",
    false,
    "",
    true,
    true,
    "application/json",
    timeZone
  );
  const { response: breadCrumb, fetchData: getBreadCrumb } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/plantings/${id}/breadcrumb-data/`,
    "GET",
    false,
    "",
    false,
    false
  );

  useEffect(() => {
    getImport({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sendSearch, ordering, id]);

  useEffect(() => {
    if (id !== "all" && response) {
      getBreadCrumb({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, response]);
  useEffect(() => {
    if (response !== null) {
      setCount(response.data.count);
      setImportList(response.data.results);
    }
  }, [response]);

  useEffect(() => {
    setPage(1);
  }, [sendSearch]);

  const refresh = () => {
    getImport({});
    setRotate(true);
    setTimeout(() => {
      setRotate(false);
    }, 2000);
  };

  const order = (value: string) => {
    setOrdering(value);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const applySearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSendSearch(search);
  };
  return (
    <div className="parent-container">
      <div className="flex justify-between items-center mt-5">
        <div>
          <h1 className="font-semibold text-[#414141] text-[16px] inline-block mr-5">
            {id === "all"
              ? t("Import register.Imported registers")
              : t("Import register.Import registers")}
          </h1>
          <button type="button" onClick={refresh} className="text-[#414141]">
            <FontAwesomeIcon
              className={`${
                rotate ? "animate-spin" : ""
              } duration-1000 ease-linear`}
              icon={faArrowsRotate}
            />
          </button>
        </div>
        {id !== "all" && (
          <div>
            <span
              title={t("Import register.Download import register file") + ""}
              className="cursor-pointer text-[#066993] border-r border-[#eeecec] mr-4"
              onClick={() => {
                isDownloadTempleteImport(true);
              }}
            >
              <FontAwesomeIcon
                className="mr-4 w-5 h-[18px]"
                icon={faDownload}
              />
            </span>
            <button
              onClick={() => {
                isAddImport(true);
              }}
              className="main-btn px-[23px] py-[10px] text-sm"
            >
              {t("Import register.Import registers")}
            </button>
          </div>
        )}
      </div>
      {id === "all" ? (
        <BreadCrumb
          breadcrumb={[
            { name: t("Import register.Tools"), url: "" },
            {
              name: t("Import register.Imported registers"),
              url: "",
            },
          ]}
        />
      ) : (
        <BreadCrumb
          breadcrumb={[
            {
              name: `${breadCrumb?.data.date}-${breadCrumb?.data.department_name}`,
              url: "",
            },
            {
              name: t("Import register.Import registers"),
              url: "",
            },
          ]}
        />
      )}

      <SearchField
        applySearch={applySearch}
        change={(e) => setSearch(e.target.value)}
        searchValue={search}
      />
      <div className="p-4 bg-white mt-3 rounded-md">
        <GenericTable
          dataList={importList}
          fetchData={() => {
            getImport({});
          }}
          loading={loading}
          setOrderingList={order}
          titles={[
            { name: t("Import register.Event name"), value: "name" },
            {
              name: t("Import register.Event date"),
              value: "date",
            },
            {
              name: t("Import register.Import file"),
              value: "",
            },
            { name: t("Workshops.Workshop status"), value: "status" },
            { name: t("Import register.Imported rows"), value: "" },
            {
              name: t("Import register.End time"),
              value: "completion_time",
            },
            { name: t("Import register.Unimported register log"), value: "" },
          ]}
          renderItem={(importRecord, fetchData) => (
            <ImportRegisterTableItem
              importItem={importRecord}
              fetchData={() => {}}
            />
          )}
          showAction={false}
        />

        {importList.length > 0 && (
          <div className="flex justify-between items-center mt-4">
            <ThemeProvider theme={TableThem}>
              <TablePagination
                count={Math.ceil(count / 10)}
                handleChange={handleChange}
              />
            </ThemeProvider>
            <p className="text-sm text-[#414141]">
              {" "}
              {`${t("Message.Showing")} ${(page - 1) * 10 + 1} - ${
                (page - 1) * 10 + importList.length
              } ${t("Message.Of")} ${count}`}
            </p>
          </div>
        )}
        <br />
      </div>
      {downloadTempleteImport && (
        <DownloadTempleteImport
          closePopOver={() => {
            isDownloadTempleteImport(false);
          }}
        />
      )}
      {addImport && (
        <AddImportRegister
          closePopOver={() => {
            isAddImport(false);
          }}
          fetchImportData={() => {
            getImport({});
          }}
          slug={id}
        />
      )}
    </div>
  );
};

export default ImportRegister;
