import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../hook/useAxios";
import { MapMarker } from "../../type/mapMarker";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { useSelector } from "react-redux";
import { profileInfo } from "../../store";

const Map: React.FC<{
  markerList: MapMarker[];
  kmlData: string | undefined;
  search: boolean;
  searchingStateHandler: () => void;
}> = (props) => {
  const [infoMarker, setInfoMarker] = useState<{
    marker: google.maps.Marker;
    markerObj: MapMarker;
  }>();
  const profile = useSelector(profileInfo);
  const googleMapRef = useRef<HTMLDivElement>(null);

  const { t, i18n } = useTranslation();
  let googleMap: google.maps.Map;
  let parser;
  let infoWindow: google.maps.InfoWindow = new window.google.maps.InfoWindow(
    {}
  );
  const [currentInfoWindow, setCurrentInfoWindow] =
    useState<google.maps.InfoWindow>(infoWindow);

  const { fetchData, response } = useAxios("", "GET", false, "", true, true);

  useEffect(() => {
    if (response) {
      props.searchingStateHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (
      response &&
      infoMarker?.markerObj.slug === response?.data?.slug &&
      !props.search
    ) {
      const url = window.location.href;
      currentInfoWindow.setContent(`
           <style>
             td {
               font-size: 14px;
              font-weight: 400;
               width : 200px;
              padding-top : 10px;
             }
             td, th {
              text-align: left;
              color:#414141;
             }
             h1{
              font-weight: 600;
              display:inline;
              color:#414141;
             }

            a{
              display:inline;
              margin-left:10px;
              color:#414141;
            }
             </style><div>
             <h1>${response.data.code}</h1>
             ${
               profile.role !== "V" &&
               `<a href='${url.split("/")[0]}/registers/edit-register/${
                 response.data.slug
               }'>`
             }<img style='width:12px; height:12px; display:inline;' src='/img/Edit pen.svg' alt='edit'></a>
        ${
          response.data.planted_tree_image
            ? `<img style='width:495px; margin-top:8px; height:300px; object-fit: cover;' src=${response.data.planted_tree_image} alt='Tree'>`
            : ""
        } <div
             style="padding: 10px; border-radius: 8px; background-color: #f7f7f7; margin-top: 10px;"
           >
           <table>
          <tr>
           <td>${t("Map.Species")}</td>
           <td>${
             i18n.language === "fr"
               ? response.data.species_name_transes.fr === ""
                 ? response.data.species_name_transes.en
                 : response.data.species_name_transes.fr
               : response.data.species_name_transes.en
           }</td>
         </tr>
         <tr>
         <td>
         ${t("Map.Nursery")}
         </td>
         <td >${
           i18n.language === "fr"
             ? response.data.origin_transes.fr === ""
               ? response.data.origin_transes.en
               : response.data.origin_transes.fr
             : response.data.origin_transes.en
         }</td>
         </tr>
         <tr>
         <td>
         ${t("Map.Height “cm”")}
         </td>
         <td >${response.data.height || ""}</td>
         </tr>
         <tr>
         <td>
         ${t("Map.Diameter “mm”")}
         </td>
         <td >${response.data.diameter || ""}</td>
         </tr>
         <tr>
         <td>
         ${t("Register.Age")}
         </td>
         <td >${
           response.data.age_year !== 0
             ? `${response.data.age_year} ${t("Register.Year")}`
             : ""
         }
         ${
           response.data.age_year !== 0 && response.data.age_month !== 0
             ? ","
             : ""
         }
             ${
               response.data.age_month !== 0
                 ? ` ${response.data.age_month} ${t("Register.Month")}`
                 : ""
             }
             </td>
         </tr>
         <tr>
          <td>${t("Plantings.Date of planting")}</td>
          <td>${response.data.planting_date}</td>
         </tr>
       </table></div>
           <div
             style="
               padding: 10px;
               border-radius: 8px;
               background-color: #f7f7f7;
               margin-top: 10px;
             "
          >
           <table>

           <tr>
          <td>${t("Register.Responsible category")}</td>
          <td>${
            i18n.language === "fr"
              ? response.data.responsible_category_transes.fr
              : response.data.responsible_category_transes.en
          }</td>
         </tr>
         <tr>
          <td>${t("Register.Responsible type")}</td>
          <td>${response.data.responsible_type || ""}</td>
         </tr>
         <tr>
          <td>${t("Register.Responsible")}</td>
          <td>${response.data.responsible_name}</td>
         </tr>
       </table>
           </div>
           <div
             style="
               padding: 10px;
               border-radius: 8px;
               background-color: #f7f7f7;
               margin-top: 10px;
            "
           >
           <table>
           <tr>
             <td>${t("Register.Latitude")}</td>
            <td>${response.data.latitude}</td>
           </tr>
          <tr>
             <td>${t("Register.Longitude")}</td>
             <td>${response.data.longitude}</td>
           </tr>
           <tr>
          <td>
           ${t("Plantings.Province")}
           </td>
           <td >${response.data.department}</td>
          </tr>
           <tr>
           <td>
           ${t("Plantings.District")}
           </td>
           <td >${response.data.municipality}</td>
           </tr>
           <tr>
           <td>
           ${t("Plantings.Community")}
           </td>
           <td >${response.data.community || ""}</td>
           </tr>
         </table>
            </div>
             <div style="padding:10px;
               border-radius: 8px;
               background-color: #f7f7f7;
               margin-top: 10px;">
               <table>
               <tr>
                 <td>${t("Map.Description")}</td>
                 <td>${
                   i18n.language === "fr"
                     ? response.data.description_transes.fr === ""
                       ? response.data.description_transes.en
                       : response.data.description_transes.fr
                     : response.data.description_transes.en
                 }</td>
               </tr>
             </table>
               </div>
           </div>
           
           `);
      currentInfoWindow.open(googleMap, infoMarker?.marker);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInfoWindow, response, infoMarker, props.search]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    googleMap = initGoogleMap();
    let bounds = new window.google.maps.LatLngBounds();
    const markers: google.maps.Marker[] = [];
    props.markerList.forEach((x) => {
      const marker: any = createMarker(x);
      bounds.extend(marker.position);
      markers.push(marker);
      googleMap.setCenter(new google.maps.LatLng(-14.6811018, 27.8295816));
      googleMap.setZoom(7);
    });
    const cluster = new MarkerClusterer({
      map: googleMap,
      markers: markers,
    });

    if (props.kmlData !== "") {
      /* eslint-disable new-cap */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line react-hooks/exhaustive-deps
      parser = new geoXML3.parser({ map: googleMap });
      /* eslint-enable */
      parser.parseKmlString(props.kmlData);
    }
  }, []);

  // initialize the google map
  const initGoogleMap = () => {
    return new window.google.maps.Map(googleMapRef.current! as HTMLElement, {
      zoom: 6,
      center: { lat: -14.6811018, lng: 27.8295816 },
      mapId: "de709e4ae98647a3",
    });
  };

  // create marker on google map
  const createMarker = (markerObj: MapMarker) => {
    const marker = new window.google.maps.Marker({
      position: { lat: markerObj.latitude, lng: markerObj.longitude },
      map: googleMap,
      icon: {
        url: markerObj.featured_icon,
        // set marker width and height
        scaledSize: new window.google.maps.Size(25, 25),
      },
    });

    marker.addListener("click", () => {
      if (currentInfoWindow) {
        currentInfoWindow.close();
      }

      fetchData(
        { slug: markerObj.slug },
        process.env.REACT_APP_API_URL +
          `/api/admin/map/${markerObj.slug}/details/`
      );
      setInfoMarker({ marker: marker, markerObj: markerObj });
      setCurrentInfoWindow(infoWindow);
    });

    return marker;
  };
  return <div ref={googleMapRef} className="w-full h-[580px]" />;
};

export default Map;
