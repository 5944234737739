import { Fragment } from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";

export const themeSelect = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#F6F6F6",
          "&.Mui-focused": {
            outline: "1px solid #439FC6",
            backgroundColor: "white",
            border: "none",
          },
        },
        notchedOutline: {
          borderColor: "#EEECEC",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#414141",
          fontSize: "14px",
          fontFamily: "Inter,sans-serif",
          ":hover": {
            color: "#439FC6",
            backgroundColor: "white",
            "& .icon": {
              color: "#439FC6",
            },
          },
          "&.Mui-selected": {
            color: "#439FC6",
            backgroundColor: "white",
            "& .icon": {
              color: "#439FC6",
            },
            ":hover": {
              backgroundColor: "white",
            },
          },
        },
      },
    },
  },
});

const SelectLanguage: React.FC<{
  handleLangChange: (event: SelectChangeEvent) => void;
  lang: string;
}> = (props) => {
  const getSelectedValue = (value: string) => {
    switch (value) {
      case "EN":
        return (
          <div className="text-sm flex items-center text-[#414141]">
            <FontAwesomeIcon
              className="text-red-600 w-2 h-2 pb-[2px] mr-2"
              icon={faCircle}
            />
            English
            <p className="text-xs  ml-1 text-[#414141DE]">(EN)</p>
          </div>
        );
      case "FR":
        return (
          <div className="text-sm flex items-center text-[#414141]">
            <FontAwesomeIcon
              className="text-[#2853B4] w-2 h-2 pb-[2px] mr-2"
              icon={faCircle}
            />
            French
            <p className="text-xs  ml-1 text-[#414141DE]"> (FR)</p>
          </div>
        );
      default:
        return "";
    }
  };
  return (
    <ThemeProvider theme={themeSelect}>
      <Fragment>
        <Select
          label="Choose Language"
          style={{ width: "48.5%", height: "46px" }}
          IconComponent={ExpandMoreIcon}
          value={props.lang}
          onChange={props.handleLangChange}
          sx={{
            border: "1px solid #eeecec",
            borderRadius: "4px",
            "& fieldset": { border: "none" },
          }}
          renderValue={(selectedValue) =>
            getSelectedValue(selectedValue as string)
          }
        >
          <MenuItem value={"EN"}>
            <FontAwesomeIcon
              className="text-red-600 w-2 h-2 pb-[2px] mr-2 icon"
              icon={faCircle}
            />
            English (EN)
          </MenuItem>
          <MenuItem value={"FR"}>
            <FontAwesomeIcon
              className="text-[#2853B4] w-2 h-2 pb-[2px] mr-2"
              icon={faCircle}
            />
            French (FR)
          </MenuItem>
        </Select>
      </Fragment>
    </ThemeProvider>
  );
};

export default SelectLanguage;
