import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import { responseFile } from "../../../type/responseFile";
import TranslationField from "../../../type/translationField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-light-svg-icons";
import OldFile from "../../Global/OldFile";
import EditSlider from "../../Global/EditSlider";
import Loading from "../../Global/Loading";

type FormValues = {
  slug: string;
  species_name_transes: TranslationField;
  incidents: {
    name_transes: TranslationField;
    slug: string;
    featured_icon: string;
  }[];
  first_height: number;
  last_height: number;
  last_diameter: number;
  current_age_year: number;
  current_age_month: number;
  age_year: number;
  age_month: number;
  first_diameter: number;
  origin_transes: TranslationField;
  code: string;
  is_replanting: boolean;
  responsible_category: string;
  responsible_type_name: string;
  responsible_name: string;
  treeImage: string;
  latitude: number;
  longitude: number;
  planting_plot: string;
  files: responseFile[];
  brief_description_transes: TranslationField;
};
const ViewRegister = () => {
  const [register, setRegister] = useState<FormValues>({
    slug: "",
    species_name_transes: { en: "", fr: "" },
    incidents: [],
    first_height: 0,
    current_age_month: 0,
    current_age_year: 0,
    first_diameter: 0,
    last_diameter: 0,
    last_height: 0,
    age_month: 0,
    age_year: 0,
    origin_transes: { en: "", fr: "" },
    code: "",
    planting_plot: "",
    is_replanting: false,
    responsible_category: "",
    responsible_type_name: "",
    responsible_name: "",
    treeImage: "",
    latitude: 0.0,
    longitude: 0.0,
    files: [
      {
        id: 0,
        file_address: "",
        thumbnail_address: "",
        is_image: false,
      },
    ],
    brief_description_transes: { en: "", fr: "" },
  });
  const [treeImage, setTreeImage] = useState<string>("");
  const { i18n, t } = useTranslation();
  const { id } = useParams<any>();
  const history = useHistory();

  const {
    response: getEditResponse,
    fetchData: getRegister,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/registers/${id}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );

  const { fetchData: getEventId, response: breadCrumb } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/plantings/${getEditResponse?.data?.planting_slug}/breadcrumb-data/?register_slug=${id}`,
    "GET",
    false,
    "",
    false,
    false
  );

  useEffect(() => {
    if (getEditResponse) {
      setRegister(getEditResponse.data);
      setTreeImage(getEditResponse.data.planted_tree_image);
      let files: responseFile[] = [...getEditResponse.data.files];
      files.push({
        file_address: getEditResponse.data.planted_tree_image,
        id: 1,
        is_image: true,
        thumbnail_address: getEditResponse.data.planted_tree_image,
      });
    }
  }, [getEditResponse]);

  useEffect(() => {
    getRegister({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getEditResponse) {
      getEventId({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEditResponse]);

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-[#414141] text-[16px] mt-5">
        {t("Register.View register")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Register.Register"), url: "/registers/all" },
          {
            name: `${breadCrumb?.data.date}-${
              breadCrumb?.data.department_name
            }-${
              i18n.language === "fr"
                ? breadCrumb?.data.species_name_transes.fr ||
                  breadCrumb?.data.species_name_transes.en
                : breadCrumb?.data.species_name_transes.en
            }-${breadCrumb?.data.unique_id}`,
            url: "",
          },
          { name: t("Register.View register"), url: "" },
        ]}
      />
      {loading ? (
        <Loading loading={true} />
      ) : (
        <div className="flex flex-wrap justify-between mt-4">
          <div className="w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
            <div className="flex bg-[#F7F7F7] rounded-md p-5">
              <div>
                <p className="text-sm text-[#414141] py-3">
                  {t("Register.Tree species")}
                </p>
                <p className="text-sm text-[#414141] py-3">
                  {t("Register.Nursery")}
                </p>
                <p className="text-sm text-[#414141] py-3">
                  {t("Incidents.Incidents")}
                </p>
                <p className="text-sm text-[#414141] py-3">
                  {t("Register.Code")}
                </p>
                <p className="text-sm text-[#414141] py-3">
                  {t("Register.Replanting")}
                </p>
                {register.planting_plot !== "" && (
                  <p className="text-sm text-[#414141] py-3">
                    {t("Register.Planting plot")}
                  </p>
                )}
              </div>
              <div className="pl-16">
                <p className="text-sm text-[#414141] font-medium py-3">
                  {i18n.language === "fr"
                    ? register.species_name_transes.fr ||
                      register.species_name_transes.en
                    : register.species_name_transes.en}
                </p>
                <p className="text-sm text-[#414141] font-medium py-3">
                  {i18n.language === "fr"
                    ? register.origin_transes.fr || register.origin_transes.en
                    : register.origin_transes.en}
                </p>
                {register.incidents.length > 0 ? (
                  <div className="flex">
                    {register.incidents.map((incident) => (
                      <div
                        className="py-3 pr-4 text-[#414141] text-sm font-medium flex items-center"
                        key={incident.slug}
                      >
                        <p>
                          {i18n.language === "fr"
                            ? incident?.name_transes.fr === ""
                              ? incident?.name_transes.en
                              : incident?.name_transes.fr
                            : incident?.name_transes.en}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-sm text-[#414141] font-medium py-3">-</p>
                )}
                <p className="text-sm text-[#414141] font-medium py-3">
                  {register.code}
                </p>
                <p className="text-sm text-[#414141] font-medium py-3">
                  {register.is_replanting && (
                    <FontAwesomeIcon
                      className="text-lg text-ph-btn"
                      icon={faCircleCheck}
                    />
                  )}
                </p>
                {register.planting_plot && (
                  <p
                    className={`text-sm text-[#414141] font-medium ${
                      register.is_replanting ? "py-3" : "pt-[32.5px]"
                    }`}
                  >
                    {register.planting_plot === "ind"
                      ? t("Register.Individual")
                      : t("Register.Communal")}
                  </p>
                )}
              </div>
            </div>

            <div className="flex bg-[#F7F7F7] rounded-md p-5 mt-3">
              <div>
                <p className="text-sm font-semibold text-[#414141] py-3">
                  {t("Register.Planting details")}
                </p>
                <p className="text-sm text-[#414141] py-3">
                  {t("Register.Tree height")}{" "}
                  <span className="text-[#707070]">in “cm”</span>
                </p>
                <p className="text-sm text-[#414141] py-3">
                  {t("Register.Diameter")}{" "}
                  <span className="text-[#707070]">in “mm”</span>
                </p>
                <p className="text-sm text-[#414141] py-3">
                  {t("Register.Age")}
                </p>
              </div>
              <div className="pl-16">
                <p className="text-sm text-[#414141] font-medium py-3 mt-11">
                  {register.first_height || "-"}
                </p>
                <p className="text-sm text-[#414141] font-medium py-3">
                  {register.first_diameter || "-"}
                </p>
                <p className="text-sm text-[#414141] font-medium py-3">
                  {register.age_year && register.age_year !== 0
                    ? `${register.age_year} ${t("Register.Year")}`
                    : ""}
                  {register.age_year !== 0 &&
                  register.age_year &&
                  register.age_month &&
                  register.age_month !== 0
                    ? `,`
                    : ""}
                  {register.age_month && register.age_month !== 0
                    ? ` ${register.age_month} ${t("Register.Month")}`
                    : ""}
                </p>
              </div>
            </div>

            <div className="flex bg-[#F7F7F7] rounded-md p-5 mt-3">
              <div>
                <p className="text-sm font-semibold text-[#414141] py-3">
                  {t("Register.Current details")}
                </p>
                <p className="text-sm text-[#414141] py-3">
                  {t("Register.Tree height")}{" "}
                  <span className="text-[#707070]">in “cm”</span>
                </p>
                <p className="text-sm text-[#414141] py-3">
                  {t("Register.Diameter")}{" "}
                  <span className="text-[#707070]">in “mm”</span>
                </p>
                <p className="text-sm text-[#414141] py-3">
                  {t("Register.Age")}
                </p>
              </div>
              <div className="pl-16">
                <p className="text-sm text-[#414141] font-medium py-3 mt-11">
                  {register.last_height || "-"}
                </p>
                <p className="text-sm text-[#414141] font-medium py-3">
                  {register.last_diameter || "-"}
                </p>
                <p className="text-sm text-[#414141] font-medium py-3">
                  {register.current_age_year !== 0
                    ? `${register.current_age_year} ${t("Register.Year")}`
                    : ""}
                  {register.current_age_year !== 0 &&
                  register.current_age_month !== 0
                    ? `,`
                    : ""}
                  {register.current_age_month !== 0
                    ? ` ${register.current_age_month} ${t("Register.Month")}`
                    : ""}
                </p>
              </div>
            </div>

            <div className="flex bg-[#F7F7F7] rounded-md p-5 mt-3">
              <div>
                <p className="text-sm font-semibold text-[#414141] py-3">
                  {t("Register.Responsible")}
                </p>
                <p className="text-sm py-3 text-[#414141]">
                  {t("Register.Responsible")}
                </p>
                <p className="text-sm py-3 text-[#414141]">
                  {t("Register.Category")}
                </p>
                <p className="text-sm py-3 text-[#414141]">
                  {t("Tree Species.Type")}
                </p>
              </div>
              <div className="pl-16">
                <p className="text-sm py-3 mt-12 font-medium text-[#414141]">
                  {register.responsible_name}
                </p>
                <p className="text-sm py-3 font-medium text-[#414141]">
                  {register.responsible_category === "ind"
                    ? t("Register.Individual")
                    : register.responsible_category === "grp"
                    ? t("Register.Group")
                    : register.responsible_category === "ins"
                    ? t("Register.Institution")
                    : t("Register.Family")}
                </p>
                <p className="text-sm py-3 font-medium text-[#414141]">
                  {register.responsible_type_name || "-"}
                </p>
              </div>
            </div>
            <div className="bg-[#F7F7F7] rounded-md p-5 mt-3">
              <div>
                <p className="text-sm font-semibold text-[#414141] mb-4">
                  {t("Register.Planted tree image")}
                </p>
                {treeImage && (
                  <OldFile
                    address={treeImage}
                    id={0}
                    delete={false}
                    removeOldFile={() => {}}
                  />
                )}
              </div>
            </div>
            <div className="flex bg-[#F7F7F7] rounded-md p-5 mt-3">
              <div>
                <p className="text-sm">{t("Register.Latitude")}</p>
                <p className="text-sm mt-4">{t("Register.Longitude")}</p>
              </div>
              <div className="pl-16">
                <p className="text-sm font-medium">{register.latitude}</p>
                <p className="text-sm mt-4 font-medium">{register.longitude}</p>
              </div>
            </div>
            <div className="bg-[#F7F7F7] rounded-md p-5 mt-3">
              <div>
                <p className="text-sm font-semibold text-[#414141] mb-4">
                  {t("AddPlanting.Relevant resources")}
                </p>
                {register.files.map((file: any) => (
                  <OldFile
                    address={file.file_address}
                    id={file.id}
                    delete={false}
                    removeOldFile={() => {}}
                    key={file.id}
                  />
                ))}
              </div>
            </div>
            <div className="flex bg-[#F7F7F7] rounded-md p-5 mt-3">
              <div>
                <p className="text-sm w-36">
                  {t("AddPlanting.Brief description")}
                </p>
              </div>
              <div className="pl-6">
                <p className="text-sm font-medium  max-w-[250px] break-words  ">
                  {i18n.language === "fr"
                    ? register.brief_description_transes.fr ||
                      register.brief_description_transes.en ||
                      "-"
                    : register.brief_description_transes.en || "-"}
                </p>
              </div>
            </div>
            <button
              type="button"
              onClick={() => history.goBack()}
              className="main-btn text-sm  px-6 py-2 mt-5 float-right font-medium"
            >
              {t("Register.Back to register")}
            </button>
          </div>
          <EditSlider
            images={["/img/Add-register-1.svg", "/img/Add-register-2.svg"]}
            response={getEditResponse}
          />
        </div>
      )}
    </div>
  );
};

export default ViewRegister;
