import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useAxios from "../../../hook/useAxios";
import { ResponsibleType } from "../../../type/responsibleType";

const AddResponsibleType: React.FC<{
  closeAddCollaborator: () => void;
  addResponsibleType: (type: string, value: ResponsibleType) => void;
  collaboratorType: string;
}> = (props) => {
  const [name, setName] = useState<string>("");
  const [mandatoryError, isMandatoryError] = useState<boolean>(false);
  const { t } = useTranslation();
  const { fetchData, response } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/responsible/type/add/",
    "POST",
    false,
    `${t("Message.Successful add")}`,
    true,
    true
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (response) {
      props.addResponsibleType(props.collaboratorType, {
        id: response.data.id,
        name: response.data.name,
        slug: response.data.slug,
      });
      props.closeAddCollaborator();
      setName("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, dispatch]);

  const addCollaboartorName = () => {
    if (name) {
      fetchData({
        name: name,
        category: props.collaboratorType,
      });
    } else {
      isMandatoryError(true);
    }
  };

  return (
    <div className="w-full h-full z-[9990] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0">
      <div className="bg-white w-full h-fit px-9 py-12 sm:w-[492px] sm:h-[327px] rounded-md shadow-md">
        <h2 className="font-semibold mb-6 text-[#414141]">
          {t("Register.Create new type")}
        </h2>
        <label className="text-sm" htmlFor="addCollaborator">
          {t("Register.Enter the new type")}
        </label>
        <input
          type="text"
          onChange={(e) => {
            setName(e.target.value);
            isMandatoryError(false);
          }}
          value={name}
          placeholder={t("Tree Species.Type") + ""}
          className="input-field text-sm pl-4 mt-4 py-[9px] placeholder-[#C7C7C7]"
        />
        {mandatoryError && (
          <p className="error-text">{t("Message.Required field")}</p>
        )}
        <button
          onClick={addCollaboartorName}
          type="button"
          className="bg-ph-btn float-right text-sm mt-16 text-white rounded-3xl px-6 py-2 hover:bg-ph-hover font-medium"
        >
          {t("Plantings.Create")}
        </button>
        <button
          onClick={() => {
            props.closeAddCollaborator();
          }}
          type="button"
          className="text-[#626262] mr-4 float-right text-sm mt-16 rounded-3xl px-6 py-2 font-medium"
        >
          {t("Message.Cancel")}
        </button>
      </div>
    </div>
  );
};

export default AddResponsibleType;
