import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faUser,
  faUsers,
  faSeedling,
  faClipboardCheck,
} from "@fortawesome/pro-regular-svg-icons";
import { faCalendarDays } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import useAxios from "../../../hook/useAxios";
import DeleteAlert from "../../ErrorAndAlert/DeleteAlert";
import { Planting } from "../../../type/planting";
import WorkshopSlider from "../../Workshops/WorkshopList/WorkshopSlider";
import WorkshopParticipants from "../../Workshops/WorkshopList/WorkshopParticipants";
import OrganizerName from "./OrganizerName";
import TreesDetail from "./TreeDetail";
import PlantingActionMenu from "./PlantingActionMenu";
import { Link } from "react-router-dom";
import InfoPopover from "../../Register/RegisterList/InfoPopover";

const PlantingGrid: React.FC<{ plantings: Planting; fetchData: () => void }> = (
  props
) => {
  const { i18n, t } = useTranslation();

  const [showDelete, isShowDelete] = useState<boolean>(false);
  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/plantings/${props.plantings.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.Successful delete")}`,
    true,
    true
  );

  useEffect(() => {
    if (deleteR) {
      props.fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const deleteAction = () => {
    isShowDelete(true);
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
    isShowDelete(false);
  };
  const name =
    props.plantings.name_transes.fr === ""
      ? props.plantings.name_transes.en
      : props.plantings.name_transes.fr;

  const desc =
    props.plantings.description_transes.fr === ""
      ? props.plantings.description_transes.en
      : props.plantings.description_transes.fr;

  const image = props.plantings.resources.filter((image) => image.is_image);
  return (
    <Fragment>
      <div className="w-full lg:w-[49%] mb-4 p-5 rounded-md shadow-sm border border-[#EEECEC] text-sm hover:shadow-lg">
        <div className="flex justify-between items-center">
          <Link
            className="font-semibold text-[#414141] hover:text-[#0884B8]"
            to={`/plantings/view-planting/${props.plantings.slug}`}
          >
            {i18n.language === "fr" ? name : props.plantings.name_transes.en}
          </Link>
          <PlantingActionMenu
            deleteAction={deleteAction}
            plantedTrees={props.plantings.planted_trees}
            registeredTrees={props.plantings.registered_trees}
            slug={props.plantings.slug}
            plantingDate={
              new Date(props.plantings.date.split("/").reverse().join("-"))
            }
          />
        </div>
        {image?.length > 0 ? (
          <WorkshopSlider images={image} />
        ) : (
          <div className="w-full h-[269px] bg-[#F7F7F7] rounded-md mt-2 flex items-center justify-center">
            <img src="/img/No Pic placeholder illu.svg" alt="noPic" />
          </div>
        )}

        <p
          title={
            i18n.language === "fr"
              ? desc
              : props.plantings.description_transes.en
          }
          className="py-2 grid-desc"
        >
          {i18n.language === "fr"
            ? desc
            : props.plantings.description_transes.en}
        </p>
        <hr />
        <div className="mt-2">
          <div className="sm:flex py-2">
            <div className="w-full sm:w-[50%]">
              <span className="text-[#979797]">
                <FontAwesomeIcon icon={faCalendarDays} />{" "}
                {t("Plantings.Date of planting")}:
              </span>
              <p className="w-9 inline pl-6">{props.plantings.date}</p>
            </div>
            <div className="w-[250px] mt-5 sm:mt-0 h-5 whitespace-nowrap overflow-hidden text-ellipsis">
              <span className="text-[#979797]">
                <FontAwesomeIcon icon={faLocationDot} />{" "}
                {t("Plantings.Province")}:
              </span>
              <p className="inline ml-4">{props.plantings.department}</p>
            </div>
          </div>

          <div className="sm:flex items-center py-2">
            <div className="w-full sm:w-[50%] mt-2 sm:mt-0">
              <span className="text-[#979797] pr-5">
                <FontAwesomeIcon icon={faSeedling} />{" "}
                {t("Plantings.Planted tree(s)")}:
              </span>
              <p className="w-9 inline px-3">{props.plantings.planted_trees}</p>
            </div>
            <div className="mt-5 sm:mt-0">
              <span className="text-[#979797]">
                <FontAwesomeIcon icon={faUsers} /> {t("Plantings.Organizer(s)")}
                :
              </span>
              <div className="inline-block ml-2">
                <OrganizerName organizers={props.plantings.organizers} />
              </div>
            </div>
          </div>

          <div className="sm:flex items-center mt-1">
            <div className="w-full sm:w-[50%]">
              <span className="text-[#979797] pr-2">
                <FontAwesomeIcon icon={faClipboardCheck} />{" "}
                {t("Plantings.Registered tree(s)")}:
              </span>
              <div className="inline-block">
                {props.plantings.registered_trees > 0 ? (
                  <div className="flex items-center">
                    <TreesDetail
                      num={props.plantings.registered_trees}
                      trees={props.plantings.trees_info}
                    />
                    {props.plantings.registered_trees >
                      props.plantings.planted_trees && (
                      <InfoPopover
                        desc={{
                          en: "The registered trees are more than planted trees.",
                          fr: "Les arbres enregistrés sont plus nombreux que les arbres plantés.",
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <p className="ml-[6px]">0</p>
                )}
              </div>
            </div>
            <div className="mt-5 sm:mt-0">
              <span className="text-[#979797]">
                <FontAwesomeIcon icon={faUser} />{" "}
                {t("PlantingFilter.Participants")}:
              </span>
              <p className="inline px-3">
                <WorkshopParticipants
                  totalNum={props.plantings.participants_total}
                  others={props.plantings.other_participants}
                  psaS={props.plantings.psa_students}
                  psaT={props.plantings.psa_tutors}
                  schoolS={props.plantings.school_students}
                  schoolT={props.plantings.school_tutors}
                  farmers={props.plantings.farmers}
                  promoters={props.plantings.promoters}
                  junior_youth_parents={props.plantings.junior_youth_parents}
                  community_leaders={props.plantings.community_leaders}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      {showDelete && (
        <DeleteAlert
          message={
            props.plantings.registered_trees > 0
              ? t("Message.Delete planting with its registers alert") + ""
              : ""
          }
          type={t("Plantings.Planting")}
          hide={hide}
          deleteAction={deleteItem}
        />
      )}
    </Fragment>
  );
};

export default PlantingGrid;
