import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useCallback, useEffect, useState } from "react";
import zxcvbn from "zxcvbn";
import PasswordStrength from "./PasswordStrength";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "react-toastify";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";
import Logo from "./Logo";

type password = {
  showText: boolean;
  text: string;
  strength: number;
};
const ResetPassword = () => {
  const [password, setPassword] = useState<password>({
    showText: false,
    text: "",
    strength: -1,
  });
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passError, isPassError] = useState<boolean>(false);
  const [confPassError, isConfPassError] = useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string>("");
  const [disable, isDisable] = useState<boolean>(true);
  const [valid, isValid] = useState<boolean>(false);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams<any>();
  const history = useHistory();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + `/api/update-password/${id}`)
      .then(function (response: any) {
        isValid(true);
      })
      .catch(function (error) {
        isValid(false);
        history.replace("/invalid-url");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onVerify = useCallback((token: string) => {
    setRecaptchaToken(token);
  }, []);

  const passwordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let score = 0;
    if (
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
        event.target.value
      )
    ) {
      score = 5;
    } else {
      score = zxcvbn(event.target.value).score;
    }
    setPassword((prevState) => {
      return {
        ...prevState,
        text: event.target.value,
        strength: score,
      };
    });
    if (score === 5 && event.target.value === confirmPassword) {
      isDisable(false);
    } else {
      isDisable(true);
    }
  };

  const confChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
    if (password.strength === 5 && event.target.value === password.text) {
      isDisable(false);
    } else {
      isDisable(true);
    }
  };

  const changePassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setRefreshReCaptcha((r) => !r);
    axios
      .put(process.env.REACT_APP_API_URL + `/api/update-password/${id}/`, {
        password: password.text,
        repeat_password: confirmPassword,
        google_recaptcha: recaptchaToken,
      })
      .then(function (response) {
        history.replace("/login");
        toast.success(t("Login.Successful password update"));
      })
      .catch(function (err) {
        if (err.response.status === 400) {
          toast.error(t("Message.Captcha error"));
        }
      });
  };

  return (
    <Fragment>
      {valid && (
        <div className="h-screen login-bg bg-cover flex justify-center items-center min-h-[550px]">
          <div className="bg-white w-[451px] h-fit shadow-lg rounded-md p-9 relative">
            <Logo />
            <h1 className="font-semibold text-center mt-8 text-[#414141]">
              {t("Login.Create new password")}
            </h1>
            <p className="text-sm text-center mt-8">
              {t("Login.Enter your new password")}
            </p>
            <form onSubmit={changePassword} className="mt-9">
              <div className="relative">
                <input
                  onBlur={() => {
                    isPassError(true);
                  }}
                  onChange={passwordChange}
                  value={password?.text}
                  type={password.showText ? "text" : "password"}
                  placeholder={t("Login.New password") + ""}
                  className="input-field text-sm py-3 pl-6"
                />
                <span
                  onClick={() => {
                    setPassword((prevState) => {
                      return {
                        ...prevState,
                        showText: !prevState.showText,
                      };
                    });
                  }}
                  className="text-[#979797] absolute right-3 top-3 cursor-pointer"
                >
                  <FontAwesomeIcon
                    icon={password.showText ? faEye : faEyeSlash}
                  />
                </span>
              </div>

              {password?.text.trim() === "" ? (
                <p className="text-xs">
                  {t("Login.Password criteria description")}
                </p>
              ) : (
                <PasswordStrength
                  strengthNum={password?.strength}
                  password={password.text}
                />
              )}

              <div className="relative">
                <input
                  disabled={password.strength !== 5}
                  onBlur={() => {
                    isConfPassError(true);
                  }}
                  onChange={confChange}
                  value={confirmPassword}
                  type="password"
                  placeholder={t("Login.Confirm password") + ""}
                  className="input-field text-sm py-3 pl-6 mt-6"
                />
                {confirmPassword.trim() !== "" &&
                  confirmPassword === password.text && (
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="text-ph-btn w-5 h-5 absolute right-3 top-9"
                    />
                  )}
              </div>
              {passError && password.strength !== 5 && (
                <p className="error-text">
                  {t("Login.Password criteria error")}
                </p>
              )}
              {confPassError && password.text !== confirmPassword && (
                <p className="error-text">
                  {t("Login.The repeated password does not match.")}
                </p>
              )}
              <GoogleReCaptcha
                onVerify={onVerify}
                refreshReCaptcha={refreshReCaptcha}
              />
              <button
                disabled={disable}
                type="submit"
                className={`${
                  disable ? "main-btn-disable" : "main-btn"
                } w-full mt-9 py-3 text-sm font-medium`}
              >
                {t("Login.Save changes")}
              </button>
            </form>
            <div className="text-center mt-8">
              <Footer />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ResetPassword;
