import { Fragment, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { TableContainer } from "@mui/material";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@emotion/react";
import TableFooter from "@mui/material/TableFooter";
import { TableThem } from "../../../styles/TableThem";
import useAxios from "../../../hook/useAxios";
import { useTranslation } from "react-i18next";
import { Incidents } from "../../../type/incidents";
import TableHeader from "../../Global/TableHeader";
import TablePagination from "../../Global/TablePagination";
import Loading from "../../Global/Loading";
import BotanicalFamilyTableItem from "../../BotanicalFamily/BotanicalFamilyList/BotanicalFamilyTableItem";

const IncidentTableContent: React.FC<{
  search: string;
  link: string;
  title: string;
  deleteMessage: string;
  editLink: string;
  featured_icon?: string;
}> = (props) => {
  const [incidents, setIncidents] = useState<Incidents[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const { t } = useTranslation();
  const { fetchData, response, loading } = useAxios(
    process.env.REACT_APP_API_URL +
      `${props.link}/?ordering=${ordering}&page=${page}&search=${props.search}`,
    "GET",
    true,
    "",
    true,
    false
  );

  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setIncidents(response.data.results);
    }
  }, [response]);

  useEffect(() => {
    setPage(1);
  }, [props.search]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const order = (value: string) => {
    setOrdering(value);
  };
  return (
    <Fragment>
      {incidents.length > 0 ? (
        <TableContainer>
          <ThemeProvider theme={TableThem}>
            <Table>
              <TableHeader
                setOrdering={order}
                titels={[
                  { name: props.title, value: "name" },
                  { name: t("Map.Description"), value: "description" },
                  {
                    name: t("Tree Species.Featured icon"),
                    value: "featured_icon",
                  },
                ]}
              />

              <TableBody>
                {incidents.map((incident: Incidents) => (
                  <TableRow key={incident.slug}>
                    <BotanicalFamilyTableItem
                      editLink={props.editLink}
                      link={props.link}
                      title={props.title}
                      deleteMessage={props.deleteMessage}
                      botanicalFamilies={incident}
                      fetchData={() => {
                        fetchData({});
                      }}
                      key={incident.slug}
                      featured_icon={incident.featured_icon}
                    />
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter
                style={{ border: "none", height: "70px", position: "relative" }}
              >
                <Box
                  sx={{ flexShrink: 0 }}
                  style={{ position: "absolute", left: "0px", top: "20px" }}
                >
                  <Stack spacing={2}>
                    <TablePagination
                      count={Math.ceil(count / 10)}
                      handleChange={handleChange}
                    />
                  </Stack>
                </Box>
                <p className="absolute right-0 top-5 text-sm text-[#414141]">{`${t(
                  "Message.Showing"
                )} ${(page - 1) * 10 + 1} - ${
                  (page - 1) * 10 + incidents.length
                } ${t("Message.Of")} ${count}`}</p>
              </TableFooter>
            </Table>
          </ThemeProvider>
        </TableContainer>
      ) : (
        <Loading loading={loading} />
      )}
    </Fragment>
  );
};

export default IncidentTableContent;
