import {
  faCircleMinus,
  faCirclePlus,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const Participants: React.FC<{
  register: any;
  setValue: any;
  clearErrors: any;
  getValues: any;
  participantsChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = (props) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {/* psaStudents */}
      <div className="flex justify-between items-center  mt-7">
        {" "}
        <p className="text-sm text-[#414141] mb-[6px]">
          {t("Workshops.PSA students")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (props.getValues("psaStudent") > 0) {
                props.setValue("psaStudent", props.getValues("psaStudent") - 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...props.register("psaStudent")}
            onChange={props.participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              props.clearErrors("participants");
              props.setValue(
                "psaStudent",
                Number(props.getValues("psaStudent")) + 1
              );
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>
      {/* schoolStudents */}
      <div className="flex justify-between items-center mt-7">
        {" "}
        <p className="text-sm text-[#414141] mb-[6px] ">
          {t("Workshops.School students")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (props.getValues("schoolStudent") > 0) {
                props.setValue(
                  "schoolStudent",
                  props.getValues("schoolStudent") - 1
                );
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...props.register("schoolStudent")}
            onChange={props.participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              props.clearErrors("schoolStudent");
              props.setValue(
                "schoolStudent",
                Number(props.getValues("schoolStudent")) + 1
              );
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>
      {/* psaTutors */}
      <div className="flex justify-between items-center mt-7">
        {" "}
        <p className="text-sm text-[#414141] mb-[6px]">
          {t("Workshops.PSA tutors")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (props.getValues("psaTutor") > 0) {
                props.setValue("psaTutor", props.getValues("psaTutor") - 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...props.register("psaTutor")}
            onChange={props.participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              props.clearErrors("psaTutor");
              props.setValue(
                "psaTutor",
                Number(props.getValues("psaTutor")) + 1
              );
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>
      {/* school teachers */}
      <div className="flex justify-between items-center mt-7">
        {" "}
        <p className="text-sm text-[#414141] mb-[6px]">
          {t("Workshops.School teachers")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (props.getValues("schoolTeacher") > 0) {
                props.setValue(
                  "schoolTeacher",
                  props.getValues("schoolTeacher") - 1
                );
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...props.register("schoolTeacher")}
            onChange={props.participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              props.clearErrors("schoolTeacher");
              props.setValue(
                "schoolTeacher",
                Number(props.getValues("schoolTeacher")) + 1
              );
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>
      {/* Farmers */}
      <div className="flex justify-between items-center mt-7">
        {" "}
        <p className="text-sm text-[#414141] mb-[6px] ">
          {t("Workshops.Farmers")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (props.getValues("farmers") > 0) {
                props.setValue("farmers", props.getValues("farmers") - 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...props.register("farmers")}
            onChange={props.participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              props.clearErrors("farmers");
              props.setValue("farmers", Number(props.getValues("farmers")) + 1);
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>
      {/* promoters */}
      <div className="flex justify-between items-center mt-7">
        {" "}
        <p className="text-sm text-[#414141] mb-[6px] ">
          {t("Workshops.Promoters")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (props.getValues("promoters") > 0) {
                props.setValue("promoters", props.getValues("promoters") - 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...props.register("promoters")}
            onChange={props.participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              props.clearErrors("promoters");
              props.setValue(
                "promoters",
                Number(props.getValues("promoters")) + 1
              );
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>
      {/* juniors */}
      <div className="flex justify-between items-center mt-7">
        {" "}
        <p className="text-sm text-[#414141] mb-[6px] ">
          {t("Workshops.Junior youth parents")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (props.getValues("junior_youth_parents") > 0) {
                props.setValue(
                  "junior_youth_parents",
                  props.getValues("junior_youth_parents") - 1
                );
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...props.register("junior_youth_parents")}
            onChange={props.participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              props.clearErrors("junior_youth_parents");
              props.setValue(
                "junior_youth_parents",
                Number(props.getValues("junior_youth_parents")) + 1
              );
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>
      {/* community leaders */}
      <div className="flex justify-between items-center mt-7">
        {" "}
        <p className="text-sm text-[#414141] mb-[6px] ">
          {t("Workshops.Community leaders")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (props.getValues("community_leaders") > 0) {
                props.setValue(
                  "community_leaders",
                  props.getValues("community_leaders") - 1
                );
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...props.register("community_leaders")}
            onChange={props.participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              props.clearErrors("community_leaders");
              props.setValue(
                "community_leaders",
                Number(props.getValues("community_leaders")) + 1
              );
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>
      {/* other participants */}
      <div className="flex justify-between items-center mt-7">
        {" "}
        <p className="text-sm text-[#414141] mb-[6px]">
          {t("Workshops.Other participants")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (props.getValues("other") > 0) {
                props.setValue("other", props.getValues("other") - 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...props.register("other")}
            onChange={props.participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              props.clearErrors("other");
              props.setValue("other", Number(props.getValues("other")) + 1);
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default Participants;
